import { IDeleteOfferFromComparisonResponse200 } from '../../../../repositories/offers-comparison/v1/delete-offer-from-comparison';
import { IDeleteOfferFromComparisonResponseSchema } from 'shared/repositories/offers-comparison/entities/delete_offer_from_comparison/DeleteOfferFromComparisonResponseSchema';

export const mapDeleteOfferFromComparisonResponse = (
  response: IDeleteOfferFromComparisonResponseSchema,
): IDeleteOfferFromComparisonResponse200 => {
  return {
    statusCode: 200,
    headers: [],
    response,
  };
};
