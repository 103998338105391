import { ca } from '@cian/analytics';

import { IJsonQuery } from 'shared/json_query';

interface IParams {
  jsonQuery: IJsonQuery;
  offerId: number;
  position: number;
}

export const trackAutoScroll = ({ jsonQuery, offerId, position }: IParams) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'show',
    label: 'autoscroll',
    sc: jsonQuery,
    products: [
      {
        id: offerId,
        offerType: 'offer',
        position,
      },
    ],
  });
};
