import { useEventCallback } from '@cian/react-utils';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useDeferredSlideStatus } from './useDeferredSlideStatus';
import { useCardInViewContext } from '../../../../../../fragments/OfferCardWidget/shared/context/CardInViewContext';
import { TVideoType } from '../../../types';
import { PLAYABLE_VIDEO_TYPES } from '../constants';
import { selectGalleryAutoScrollExperimentEnabled } from 'shared/selectors/experiments/selectGalleryAutoScrollExperimentEnabled';
import { selectGalleryAutoZoomExperimentEnabled } from 'shared/selectors/experiments/selectGalleryAutoZoomExperimentEnabled';

interface IParams {
  videoType: TVideoType;
  disableVideoPlay?: boolean;
}

/**
 * Хук управляет возможностью воспроизведения видео
 */
export const useCanPlayVideo = ({ videoType, disableVideoPlay }: IParams) => {
  const cardInView = useCardInViewContext('videoShorts');
  const isGalleryAutoZoomExperimentEnabled = useSelector(selectGalleryAutoZoomExperimentEnabled);
  const isGalleryAutoScrollExperimentEnabled = useSelector(selectGalleryAutoScrollExperimentEnabled);

  const isSlideActive = useDeferredSlideStatus();

  const [canPlayVideo, setCanPlayVideo] = React.useState(false);

  React.useEffect(() => {
    if (!disableVideoPlay) {
      setCanPlayVideo(
        !isGalleryAutoZoomExperimentEnabled &&
          !isGalleryAutoScrollExperimentEnabled &&
          cardInView &&
          isSlideActive &&
          PLAYABLE_VIDEO_TYPES.includes(videoType),
      );
    }
  }, [
    cardInView,
    isSlideActive,
    videoType,
    disableVideoPlay,
    isGalleryAutoZoomExperimentEnabled,
    isGalleryAutoScrollExperimentEnabled,
  ]);

  const resetCanPlayVideo = useEventCallback(() => {
    setCanPlayVideo(false);
  });

  return React.useMemo(() => ({ canPlayVideo, resetCanPlayVideo }), [canPlayVideo, resetCanPlayVideo]);
};
