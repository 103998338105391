// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IAnalyticsSchema } from '../analytics/AnalyticsSchema';
import type { IOfferAreaSchema } from '../offer_area/OfferAreaSchema';
import type { IBuilderSchema } from '../builder/BuilderSchema';
import type { IContactsSchema } from '../contacts/ContactsSchema';
import type { IOfferPropSchema } from '../props/OfferPropSchema';
import type { IGeoSchema } from '../geo/GeoSchema';
import type { IOfferImageSchema } from '../offer_image/OfferImageSchema';
import type { INewbuildingSchema } from '../newbuilding/NewbuildingSchema';
import type { INewbuildingHouseSchema } from '../newbuilding_house/NewbuildingHouseSchema';
import type { IPriceSchema } from '../price/PriceSchema';

export interface IOfferDesktopSchema {
  /** Аналитика **/
  analytics: IAnalyticsSchema;
  /** Площади объявления **/
  area: IOfferAreaSchema;
  /** Количество балконов и лоджий **/
  balconies?: string;
  /** Год постройки **/
  buildYear?: number;
  /** Застройщик **/
  builder?: IBuilderSchema;
  /** Категория **/
  category: ECategory;
  /** Новостройка или вторичка **/
  categoryName?: string;
  /** Высота потолков, м **/
  ceilingHeight?: string;
  /** Контакты **/
  contacts: IContactsSchema;
  /** Тип сделки **/
  dealType: EDealType;
  /** Список непустых строк таблицы с атрибутами объявления (для оптимизации фронта) **/
  fields: IOfferPropSchema[];
  /** Этаж **/
  floor?: string;
  /** Гео **/
  geo: IGeoSchema;
  /** ID **/
  id: number;
  /** Изображения **/
  images: IOfferImageSchema[];
  /** В избранном ли объявление у пользователя **/
  isFavorite: boolean;
  /** Лифты **/
  lifts?: string;
  /** Возможна ли ипотека **/
  mortgage?: string;
  /** ЖК **/
  newbuilding?: INewbuildingSchema;
  /** Корпус ЖК **/
  newbuildingHouse?: INewbuildingHouseSchema;
  /** Тип объявления **/
  offerType: EOfferType;
  /** Цена **/
  price: IPriceSchema;
  /** Опубликовано ли объявление **/
  published: boolean;
  /** Тип ремонта **/
  repairType?: string;
  /** Заголовок ('2-комн. апарт., 111,9 м², 9/10') **/
  title: string;
  /** Ссылка на 3D-тур vr net **/
  tourUrl?: string;
  /** Url карточки **/
  url: string;
  /** Санузел **/
  wc?: string;
  /** Вид из окон **/
  windowsView?: string;
}

export enum ECategory {
  /** Аренда койко-места **/
  'BedRent' = 'bedRent',
  /** Аренда здания **/
  'BuildingRent' = 'buildingRent',
  /** Продажа здания **/
  'BuildingSale' = 'buildingSale',
  /** Аренда готового бизнеса **/
  'BusinessRent' = 'businessRent',
  /** Продажа готового бизнеса **/
  'BusinessSale' = 'businessSale',
  /** Аренда автосервис **/
  'CarServiceRent' = 'carServiceRent',
  /** Продажа автосервиса **/
  'CarServiceSale' = 'carServiceSale',
  /** Аренда коммерческой земли **/
  'CommercialLandRent' = 'commercialLandRent',
  /** Продажа коммерческой земли **/
  'CommercialLandSale' = 'commercialLandSale',
  /** Аренда коттеджа **/
  'CottageRent' = 'cottageRent',
  /** Продажа коттеджа **/
  'CottageSale' = 'cottageSale',
  /** Посуточная аренда койко-места **/
  'DailyBedRent' = 'dailyBedRent',
  /** Посуточная аренда квартиры **/
  'DailyFlatRent' = 'dailyFlatRent',
  /** Посуточная аренда дома, дачи, коттеджа **/
  'DailyHouseRent' = 'dailyHouseRent',
  /** Посуточная аренда комнаты **/
  'DailyRoomRent' = 'dailyRoomRent',
  /** Аренда помещения под бытовые услуги **/
  'DomesticServicesRent' = 'domesticServicesRent',
  /** Продажа помещения под бытовые услуги **/
  'DomesticServicesSale' = 'domesticServicesSale',
  /** Аренда квартиры **/
  'FlatRent' = 'flatRent',
  /** Продажа квартиры **/
  'FlatSale' = 'flatSale',
  /** Продажа доли в квартире **/
  'FlatShareSale' = 'flatShareSale',
  /** Аренда помещения свободного назначения **/
  'FreeAppointmentObjectRent' = 'freeAppointmentObjectRent',
  /** Продажа помещения свободного назначения **/
  'FreeAppointmentObjectSale' = 'freeAppointmentObjectSale',
  /** Аренда гаража **/
  'GarageRent' = 'garageRent',
  /** Продажа гаража **/
  'GarageSale' = 'garageSale',
  /** Аренда дома/дачи **/
  'HouseRent' = 'houseRent',
  /** Продажа дома/дачи **/
  'HouseSale' = 'houseSale',
  /** Аренда части дома **/
  'HouseShareRent' = 'houseShareRent',
  /** Продажа части дома **/
  'HouseShareSale' = 'houseShareSale',
  /** Аренда производства **/
  'IndustryRent' = 'industryRent',
  /** Продажа производство **/
  'IndustrySale' = 'industrySale',
  /** Продажа участка **/
  'LandSale' = 'landSale',
  /** Продажа квартиры в новостройке **/
  'NewBuildingFlatSale' = 'newBuildingFlatSale',
  /** Аренда офиса **/
  'OfficeRent' = 'officeRent',
  /** Продажа офиса **/
  'OfficeSale' = 'officeSale',
  /** Аренда общепита **/
  'PublicCateringRent' = 'publicCateringRent',
  /** Продажа общепита **/
  'PublicCateringSale' = 'publicCateringSale',
  /** Аренда комнаты **/
  'RoomRent' = 'roomRent',
  /** Продажа комнаты **/
  'RoomSale' = 'roomSale',
  /** Аренда торговой площади **/
  'ShoppingAreaRent' = 'shoppingAreaRent',
  /** Продажа торговой площади **/
  'ShoppingAreaSale' = 'shoppingAreaSale',
  /** Аренда таунхауса **/
  'TownhouseRent' = 'townhouseRent',
  /** Продажа таунхауса **/
  'TownhouseSale' = 'townhouseSale',
  /** Аренда склада **/
  'WarehouseRent' = 'warehouseRent',
  /** Продажа склада **/
  'WarehouseSale' = 'warehouseSale',
}
export enum EDealType {
  /** Аренда **/
  'Rent' = 'rent',
  /** Продажа **/
  'Sale' = 'sale',
}
export enum EOfferType {
  /** коммерческая **/
  'Commercial' = 'commercial',
  /** квартиры **/
  'Flat' = 'flat',
  /** новостройки **/
  'Newobject' = 'newobject',
  /** загородка **/
  'Suburban' = 'suburban',
}
