import { equals } from 'ramda';
import * as R from 'ramda';

import {
  IJsonQuery,
  IJsonQueryRange,
  IJsonQueryTerm,
  IJsonQueryTerms,
  IJsonQueryType,
  TGeoValue,
} from '../../repositories/common/json_query';
import { TLocation } from '../../types/location';
import { tempNormalizeLocation } from '../../utils/geo';
import { range, term, terms, geo } from '../constructors';

export function setRootType(value: IJsonQueryType): (jsonQuery: IJsonQuery) => IJsonQuery {
  return jsonQuery => ({
    ...jsonQuery,
    _type: value,
  });
}

export function setTerm<T, K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryTerm<T>>(
  key: K,
): (jsonQuery: IJsonQuery) => (value: V['value'] | undefined) => IJsonQuery {
  return jsonQuery => value => R.assoc(key, term(value), jsonQuery);
}

export function setTerms<T, K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryTerms<T>>(
  key: K,
): (jsonQuery: IJsonQuery) => (value: V['value']) => IJsonQuery {
  return jsonQuery => value => R.assoc(key, terms(value), jsonQuery);
}

export function setRange<K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryRange>(
  key: K,
): (jsonQuery: IJsonQuery) => (gte: V['value']['gte'], lte: V['value']['lte']) => IJsonQuery {
  return jsonQuery => (gte, lte) => R.assoc(key, range(gte, lte), jsonQuery);
}

export function setGeo(): (jsonQuery: IJsonQuery) => (value: TGeoValue[]) => IJsonQuery {
  return jsonQuery => value => R.assoc('geo', geo(value), jsonQuery);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTerm<T>(value: any): value is IJsonQueryTerm<T> {
  return typeof value === 'object' && value.type === 'term';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTerms<T>(value: any): value is IJsonQueryTerms<T> {
  return typeof value === 'object' && value.type === 'terms';
}

export function getTermValue<T, K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryTerm<T>>(
  key: K,
): (jsonQuery: IJsonQuery) => V['value'] | undefined {
  return jsonQuery => {
    const termField = jsonQuery[key];

    return isTerm<T>(termField) ? (termField.value as V['value']) : undefined;
  };
}

export function getTermsValue<T, K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryTerms<T>>(
  key: K,
): (jsonQuery: IJsonQuery) => V['value'] {
  return jsonQuery => {
    const termsField = jsonQuery[key];

    return isTerms<T>(termsField) ? termsField.value : [];
  };
}

export function needClearGeo(nextLocationRaw: TLocation, currentLocationRaw: TLocation) {
  const nextLocation = tempNormalizeLocation(nextLocationRaw);
  const currentLocation = tempNormalizeLocation(currentLocationRaw);

  // Если выбрана та же локация
  if (equals(nextLocation.map(n => n.id).sort(), currentLocation.map(n => n.id).sort())) {
    return false;
  }

  // Если следующая локация является группой и содержит предыдущую
  if (
    currentLocation.length === 1 &&
    nextLocation.length > 1 &&
    nextLocation.some(next => currentLocation[0].id === next.id)
  ) {
    return false;
  }

  // Если следующая локация является родительской для текущей
  if (nextLocation.some(next => currentLocation.some(current => current.parentId === next.id))) {
    return false;
  }

  return true;
}

export function removeNewbuildingHouses(): (jsonQuery: IJsonQuery) => IJsonQuery {
  return jsonQuery => {
    const geoValue = jsonQuery.geo?.value.filter(item => item.type !== 'nb_house_key') || [];

    return R.assoc('geo', geo(geoValue), jsonQuery);
  };
}
