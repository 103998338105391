import * as React from 'react';

import { useOfferContext } from '../../../../context';
import { ChatOnboardingContainer } from '../ChatOnboardingContainer';
import { ConsultantChatButtonContainer } from '../ConsultantChatButtonContainer';
import { OfferChatButtonContainer } from '../OfferChatButtonContainer';

export const ChatButtonContainer: React.FC = () => {
  const { isChatAvailable, isNewbuilding, isFromLeadFactory } = useOfferContext();

  if (isChatAvailable) {
    return (
      <ChatOnboardingContainer type="offerChatOnboarding">
        <OfferChatButtonContainer />
      </ChatOnboardingContainer>
    );
  }

  // TODO: Удалить в CD-225150, если эксперимент неудачен.
  if (isNewbuilding || isFromLeadFactory) {
    return <ConsultantChatButtonContainer />;
  }

  return null;
};
