export const NB_CHAT_COMMON_FILTER = 'newbuilding_chat_common_filter';
export const NB_CONSULTANT_TRIGGERING_MESSAGE = 'newbuilding-triggering-message';
export const RENTER_FORM_EXPERIMENT = 'renter_form_experiment';
export const DAILY_RENT_OTA_CONVERSION = 'dailyrent_web_sut375_online_booking_cr';
export const NB_LAYOUT_LISTING = 'newbuilding_layout_listing_inside_jk_desktop';
export const NB_RENT_TRAP = 'newbuilding_rent_trap_desktop';
export const NB_SUBURBAN_TRAP = 'newbuilding_suburban_trap_desktop';
export const NB_FLAT_SALE_TRAP = 'newbuilding_flatsale_trap_desktop';
// TODO: Удалить в CD-225150.
export const NB_CONSULTANT_MESSAGE_BUTTON = 'newbuilding_new_entry_point_webim_desktop';
export const AUD_GALLERY_AUTOSCROLL_AUTOZOOM_EXPERIMENT = 'vx_desktop_aud_3776_autoscroll_autozoom';
export const AUD_REDESIGN_MIXED_FILTERS_EXPERIMENT = 'vx_desktop_aud_3788_remove_filters';

export const AUD_HIDE_PHONE_NUMBER_EXPERIMENT = 'aud_desktop_aud3852_phone_auth_blocker';

export const EXPERIMENTS: string[] = [
  'newbuilding_consultant',
  NB_CHAT_COMMON_FILTER,
  NB_CONSULTANT_TRIGGERING_MESSAGE,
  RENTER_FORM_EXPERIMENT,
  DAILY_RENT_OTA_CONVERSION,
  NB_LAYOUT_LISTING,
  NB_RENT_TRAP,
  NB_SUBURBAN_TRAP,
  NB_FLAT_SALE_TRAP,
  NB_CONSULTANT_MESSAGE_BUTTON,
  AUD_GALLERY_AUTOSCROLL_AUTOZOOM_EXPERIMENT,
  AUD_REDESIGN_MIXED_FILTERS_EXPERIMENT,
  AUD_HIDE_PHONE_NUMBER_EXPERIMENT,
];
