import { Header2, Header4 } from '@cian/ui-kit/typography';
import cx from 'classnames';
import React, { memo } from 'react';

import styles from './Title.css';
import { GeneralInfoSectionRow } from '../GeneralInfoSectionRow';

interface ITitleProps {
  title: string;
  subtitle?: string | null;
  starRating?: string;
  href: string;
  isTop: boolean;
  isHighlighted?: boolean;
  rel?: string;
}

export const TitleComponent: React.FC<ITitleProps> = ({
  title,
  subtitle,
  starRating,
  href,
  rel = 'noreferrer',
  isTop,
  isHighlighted,
}) => {
  const titleText = starRating ? (
    <>
      {title}&nbsp;<span className={styles['star-rating']}>{starRating}</span>
    </>
  ) : (
    title
  );

  const target = rel?.includes('noreferrer') ? '_blank' : undefined;

  return (
    <GeneralInfoSectionRow>
      <a className={styles['link']} href={href} target={target} rel={rel}>
        <Header2
          data-mark="OfferTitle"
          color={isTop || subtitle !== undefined ? 'text-main-default' : 'text-primary-default'}
        >
          <span className={cx({ [styles['title-highlighted']]: isHighlighted })}>{titleText}</span>
        </Header2>
        {subtitle && (
          <div className={styles['subtitle']}>
            <Header4 data-mark="OfferSubtitle">{subtitle}</Header4>
          </div>
        )}
      </a>
    </GeneralInfoSectionRow>
  );
};

TitleComponent.displayName = 'Title';

export const Title = memo(TitleComponent);
