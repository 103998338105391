import { pathOr } from 'ramda';

import { IJsonQuery } from '../../json_query';
import { IOffersData } from '../../types/offersData';
import { ISearchOffersDesktopResponseV2Schema } from 'shared/repositories/search-offers/entities/entities/SearchOffersDesktopResponseV2Schema';

export function prepareOffers(response: ISearchOffersDesktopResponseV2Schema): IOffersData {
  const offersSerialized = response?.data?.offersSerialized ?? [];

  return {
    ...response.data,
    aggregatedCount: pathOr(0, ['data', 'aggregatedCount'], response),
    avgPriceInformer: pathOr(undefined, ['data', 'avgPriceInformer'], response),
    breadcrumbs: pathOr([], ['data', 'breadcrumbs'], response),
    collections: pathOr(null, ['data', 'collections', 'items'], response),
    extendedOffersCount: pathOr(0, ['data', 'extendedOffersCount'], response),
    extensionTypes: pathOr([], ['data', 'extensionTypes'], response),
    fullUrl: pathOr('/', ['data', 'fullUrl'], response),
    jsonQuery: response.data.jsonQuery as IJsonQuery,
    mlRankingGuid: pathOr(undefined, ['data', 'mlRankingGuid'], response),
    mlRankingModelVersion: pathOr(undefined, ['data', 'mlRankingModelVersion'], response),
    newbuilding: pathOr(null, ['data', 'newbuilding'], response),
    newbuildingIdsForBuildersPromoSlider: pathOr([], ['data', 'newbuildingIdsForBuildersPromoSlider'], response),
    newbuildingPromoBuilderOffers: pathOr(undefined, ['data', 'specialPromoBuilderOffers'], response),
    newbuildingSimilarOffersBlock: pathOr(undefined, ['data', 'newbuildingSimilarOffersBlock'], response),
    offerCount: pathOr(0, ['data', 'offerCount'], response),
    offersSerialized: offersSerialized as IOffersData['offersSerialized'],
    queryString: pathOr('', ['data', 'queryString'], response),
    searchRequestId: pathOr('', ['data', 'searchRequestId'], response),
    seoData: response.data.seoData as IOffersData['seoData'],
    seoLinks: pathOr(undefined, ['data', 'seoLinks'], response),
    seoSchemas: pathOr(undefined, ['data', 'seoSchemas'], response),
    markedList: pathOr(undefined, ['data', 'markedList'], response),
    suggestOffersSerializedList: pathOr(undefined, ['data', 'suggestOffersSerializedList'], response),
    suggestionsQuery: pathOr(null, ['data', 'suggestionsQuery'], response),
    topHitsLinks: pathOr(undefined, ['data', 'topHitsLinks'], response),
    puids: pathOr(undefined, ['data', 'puids'], response),
    newbuildingFlatSaleQueryString: pathOr(undefined, ['data', 'newbuildingFlatSaleQueryString'], response),
  };
}
