import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';

import { ResponseError } from 'shared/errors/ResponseError';
import { IChat } from 'shared/repositories/newbuilding-consultants/entities/chat/Chat';
import {
  fetchGetOrCreateChat,
  TGetOrCreateChatResponse,
  TGetOrCreateChatResponseError,
} from 'shared/repositories/newbuilding-consultants/v1/get-or-create-chat';

interface IConsultantChatProps {
  httpApi: IHttpApi;
  logger: ILogger;
  parameters: {
    pageUrl?: null | string;
  };
}

export const fetchConsultantCianChat = async ({
  httpApi,
  logger,
  parameters,
}: IConsultantChatProps): Promise<IChat | null> => {
  try {
    const data: TGetOrCreateChatResponse = await fetchGetOrCreateChat({
      httpApi,
      parameters,
    });

    if (data.statusCode !== 200) {
      throw new ResponseError({
        domain: 'resolveConsultantChat',
        message: (data.response as TGetOrCreateChatResponseError).message as string,
      });
    }

    return data.response.chat || null;
  } catch (error) {
    logger.error(error, {
      domain: 'fetchConsultantCianChat',
    });

    return null;
  }
};
