import { mergeStates } from './mergeStates';
import { prepareValue } from './prepareValue';
import { NonEmptyArray } from '../../../../../../JsonQuery';
import { ESaleType } from 'shared/repositories/common/json_query';

const ALL_OTHERS_TYPES: NonEmptyArray<ESaleType> = [ESaleType.Free, ESaleType.Alternative];
const ALL_NEWBUILDINGS_TYPES: NonEmptyArray<ESaleType> = [
  ESaleType.F3,
  ESaleType.GSKContract,
  ESaleType.Reassignment,
  ESaleType.PreliminaryContract,
  ESaleType.InvestmentContract,
];

interface IUseSeparateStateProps {
  onSaleTypeChange(value: NonEmptyArray<ESaleType> | null): void;
  saleType: NonEmptyArray<ESaleType> | null;
}

interface IUseSeparateStateRes {
  onOthersChange(value: NonEmptyArray<ESaleType> | null): void;
  onNewbuildingChange(value: NonEmptyArray<ESaleType> | null): void;
  flatNewList: NonEmptyArray<ESaleType> | null;
  othersList: NonEmptyArray<ESaleType> | null;
}

export const useSeparateState = ({ onSaleTypeChange, saleType }: IUseSeparateStateProps): IUseSeparateStateRes => {
  const onOthersChange = (value: NonEmptyArray<ESaleType> | null) => {
    const mergedState = mergeStates({
      newbuildingState: prepareValue({ value: saleType, allowedValues: ALL_NEWBUILDINGS_TYPES }),
      otherState: value,
      allowedNewbuildingValues: ALL_NEWBUILDINGS_TYPES,
      allowedOtherValues: ALL_OTHERS_TYPES,
    });

    onSaleTypeChange(mergedState);
  };

  const onNewbuildingChange = (value: NonEmptyArray<ESaleType> | null) => {
    const mergedState = mergeStates({
      newbuildingState: value,
      otherState: prepareValue({ value: saleType, allowedValues: ALL_OTHERS_TYPES }),
      allowedNewbuildingValues: ALL_NEWBUILDINGS_TYPES,
      allowedOtherValues: ALL_OTHERS_TYPES,
    });

    onSaleTypeChange(mergedState);
  };

  return {
    flatNewList: prepareValue({ value: saleType, allowedValues: ALL_NEWBUILDINGS_TYPES }),
    othersList: prepareValue({ value: saleType, allowedValues: ALL_OTHERS_TYPES }),
    onNewbuildingChange,
    onOthersChange,
  };
};
