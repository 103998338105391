import { EAuthenticationActionType } from '../../actions/authentication';
import { EChatActionTypes } from '../../actions/chat';
import { IAppState, IChatState, TActions } from '../../common/state';
import { ENewbuildingChatActionTypes } from 'shared/serp/state/newbuildingConsultant';

export const defaultState: IChatState = {
  offer: null,
  newbuilding: null,
  isOpened: false,
  position: null,
  openAfterAuth: false,
};

export function chatReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case EChatActionTypes.Open: {
      return {
        ...state,
        chat: {
          offer: action.payload.offer,
          newbuilding: null,
          isOpened: true,
          position: action.payload.position,
          openAfterAuth: false,
        },
      };
    }
    case EChatActionTypes.OpenChatbot: {
      return {
        ...state,
        chat: {
          offer: null,
          newbuilding: null,
          isOpened: true,
          position: null,
          openAfterAuth: false,
        },
      };
    }
    case EChatActionTypes.OpenNewbuildingChat: {
      return {
        ...state,
        chat: {
          offer: null,
          newbuilding: action.payload,
          isOpened: true,
          position: null,
          openAfterAuth: false,
        },
      };
    }
    case EChatActionTypes.Close: {
      return {
        ...state,
        chat: defaultState,
      };
    }
    case EChatActionTypes.OpenAfterAuth:
      return {
        ...state,
        chat: {
          ...state.chat,
          offer: action.payload.offer,
          newbuilding: null,
          position: action.payload.position,
          openAfterAuth: true,
        },
      };
    case EChatActionTypes.OpenNewbuildingChatAfterAuth:
      return {
        ...state,
        chat: {
          ...state.chat,
          offer: null,
          newbuilding: action.payload,
          position: null,
          openAfterAuth: true,
        },
      };

    case EAuthenticationActionType.Succeed: {
      if (!state.chat.openAfterAuth) {
        return state;
      }

      return {
        ...state,
        chat: {
          ...state.chat,
          isOpened: true,
          openAfterAuth: false,
        },
      };
    }

    case EAuthenticationActionType.Cancelled:
      return {
        ...state,
        chat: {
          ...state.chat,
          openAfterAuth: false,
        },
      };

    // TODO: Удалить в CD-225150, если эксперимент неудачен.
    // Закрываем циановский чат, если открывается вебимовский.
    case ENewbuildingChatActionTypes.ToggleChat: {
      if (action.payload) {
        return {
          ...state,
          chat: {
            ...state.chat,
            isOpened: false,
          },
        };
      }
    }
  }

  return state;
}
