import { parseURI } from '@cian/http-api';

import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';
import { defaultHideOfferState } from '../../serp/state/hide_offer';
import { fetchOffers, prepareJsonQuery } from '../../services/fetchOffers';
import { fetchSeoUrlsForFirstTwoPages } from '../../services/fetchSeoUrlsForFirstTwoPages';
import { trackPaginationClick } from '../../tracking/pagination';
import { IOffersData } from '../../types/offersData';
import { TThunkAction } from '../../types/redux';
import { cutDescriptionFromOffers } from '../../utils';
import { saveHistorySearch } from '../../utils/history_search';
import { offersDecorator } from '../../utils/offersDecorator';
import { trackPageView } from '../tracking';

export interface IPageChangeStartedAction {
  type: 'filters/pagination/PAGE_CHANGE_STARTED';
}

export interface IPageChangeFinishedAction {
  type: 'filters/pagination/PAGE_CHANGE_FINISHED';
  data: IOffersData;
  paginationUrls: string[];
}

export const FILTERS_PAGINATION_PAGE_CHANGE_STARTED = 'filters/pagination/PAGE_CHANGE_STARTED';
export const FILTERS_PAGINATION_PAGE_CHANGE_FINISHED = 'filters/pagination/PAGE_CHANGE_FINISHED';

export const pageChangeStarted = (): IPageChangeStartedAction => ({
  type: FILTERS_PAGINATION_PAGE_CHANGE_STARTED,
});

export const pageChangeFinished = (data: IOffersData, paginationUrls: string[]): IPageChangeFinishedAction => ({
  type: FILTERS_PAGINATION_PAGE_CHANGE_FINISHED,
  data,
  paginationUrls,
});

export function changePage(page: number): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const state = getState();
    const {
      results: { jsonQuery },
      filters: { currentLocation },
      isFetching,
      config,
      logger,
      currentSubdomain,
    } = state;

    trackPaginationClick({
      currentPage: jsonQuery.page?.value || 1,
      nextPage: page,
    });

    if (isFetching) {
      return;
    }

    dispatch(pageChangeStarted());

    const mutatedJsonQuery = jq(jsonQuery).setPageNumber(page);
    const isAdditionalTop3Disabled = config.get('serp.isAdditionalTop3Enabled') !== 'true';

    const offersData = await fetchOffers(context, {
      jsonQuery: prepareJsonQuery({ jsonQuery: mutatedJsonQuery, location: currentLocation }),
    });

    /**
     * Сохранение текущего поиска для истории поисков
     */
    saveHistorySearch(offersData.seoData.h1, offersData.fullUrl, offersData.jsonQuery);

    const suggestions = offersData.suggestOffersSerializedList;

    offersData.offersSerialized = cutDescriptionFromOffers(offersData.offersSerialized, state.userAgent);

    if (suggestions) {
      offersData.suggestOffersSerializedList = cutDescriptionFromOffers(suggestions, state.userAgent);
    }

    // Обработка объявлений
    offersDecorator(offersData.offersSerialized, isAdditionalTop3Disabled);

    const paginationUrls = await fetchSeoUrlsForFirstTwoPages(context, {
      subdomain: currentSubdomain,
      jsonQuery: offersData.jsonQuery,
    });

    dispatch(pageChangeFinished(offersData, paginationUrls));

    const newState = getState();

    try {
      const url = parseURI(offersData.fullUrl);
      const path = `${url.path}${url.query}`;

      window.history.pushState({}, document.title, path);
    } catch (error) {
      logger.warning(error, {
        description: 'Проблема парсинга урла выдачи',
        degradation: 'Ссылка в строке поиска не поменялась при пагинации',
      });
    }

    trackPageView(
      {
        filters: newState.filters,
        results: newState.results,
        breadcrumbs: offersData.breadcrumbs,
        user: newState.user,
        profileSessionKey: newState.profileSessionKey,
        mlRankingGuid: newState.mlRankingGuid,
        mlRankingModelVersion: newState.mlRankingModelVersion,
        searchRequestId: newState.searchRequestId,
        location: newState.filters.currentLocation,
      },
      config,
    );
  };
}

export function paginationReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case FILTERS_PAGINATION_PAGE_CHANGE_STARTED:
      return {
        ...state,
        isFetching: true,
      };

    case FILTERS_PAGINATION_PAGE_CHANGE_FINISHED:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...action.data.jsonQuery,
            page: undefined,
          },
          jsonQueryCount: undefined,
          jsonQueryCountRefresing: false,
        },
        results: {
          ...state.results,
          queryString: action.data.queryString,
          jsonQuery: action.data.jsonQuery,
          offers: action.data.offersSerialized,
          aggregatedOffers: action.data.aggregatedCount,
          totalOffers: action.data.offerCount,
          seo: action.data.seoData,
          fullUrl: action.data.fullUrl,
          paginationUrls: action.paginationUrls,
          suggestions: action.data.suggestOffersSerializedList || null,
          suggestionsQuery: action.data.suggestionsQuery,
        },
        breadcrumbs: action.data.breadcrumbs,
        isFetching: false,
        hideOffer: defaultHideOfferState,
        mlRankingGuid: action.data.mlRankingGuid || null,
        mlRankingModelVersion: action.data.mlRankingModelVersion || null,
        searchRequestId: action.data.searchRequestId,
        newbuildingPromoBuilderOffers: action.data.specialPromoBuilderOffers || null,
      };

    default:
      return state;
  }
}
