import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../../../../packages/Filters/shared/types/jsonQuery';

export function trackOfferCardShow({
  position,
  offerId,
  jsonQuery,
}: {
  position: number;
  offerId: number;
  jsonQuery: IJsonQuery;
}) {
  ca('teaser', {
    sc: jsonQuery,
    product: {
      screen: 'Listing',
      block: 'Listing_snippet',
      id: offerId,
      name: 'show_card',
      extra: {
        offerType: 'offer',
      },
      position,
    },
  });
}
