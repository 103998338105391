import { Button } from '@cian/ui-kit/button';
import { equals } from 'ramda';
import * as React from 'react';

import * as styles from './AdvancedFiltersContainer.css';
import { getAvailableFilters } from './helpers';
import { AdvancedFilters } from '../../../components/AdvancedFiltersComponent';
import { SearchResultsTooltip } from '../../../components/SearchResultsTooltip';
import { ADVANCED_FILTERS } from '../../../constants/advancedFilters';
import { IFilterComponentProps } from '../../../types/filters';
import { IJsonQuery } from '../../../types/jsonQuery';
import { getUncommonProperties, isAdvancedFiltersSelected } from '../../../utils/advancedFilters';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';
import { PikBannerInFiltersContainer } from '../../PikBannerInFiltersContainer';
import { ProfessionalSearchBannerContainer } from '../../ProfessionalSearchBannerContainer';

export const AdvancedFiltersContainer: React.FC<IFilterComponentProps> = ({ filterKey, open, onOpen, onClose }) => {
  const {
    appContext: { config },
    currentLocation,
    features,
    jsonQuery,
    jsonQueryCount,
    jsonQueryCountRefreshing,
    isMixedFiltersRedesign,
    onApply,
    onApplyClick,
    onChange,
    onClear,
  } = useContext();
  const isAnythingSelected = isAdvancedFiltersSelected(jsonQuery);
  const openTooltip = useSearchResultsTooltipOpen({ filterKey: filterKey as 'advancedFilters' });
  const [jsonQueryBackup, setJsonQueryBackup] = React.useState<IJsonQuery>();
  const [isAwaitingJsonQueryUpdate, setIsAwaitingJsonQueryUpdate] = React.useState<boolean>(false);
  const [isJsonQueryUpdated, setIsJsonQueryUpdated] = React.useState<boolean>(false);
  const availableFilters = getAvailableFilters(jsonQuery, isMixedFiltersRedesign);

  const handleClose = React.useCallback(() => {
    if (jsonQueryBackup && !equals(jsonQuery, jsonQueryBackup)) {
      onChange({ action: 'setJsonQuery', arguments: [jsonQueryBackup] });
    }

    if (onClose) {
      onClose();
    }

    if (isJsonQueryUpdated) {
      onApply();
    }
  }, [isJsonQueryUpdated, jsonQuery, jsonQueryBackup, onApply, onChange, onClose]);

  const handleClear = React.useCallback(() => {
    onChange({
      action: 'resetTerms',
      arguments: [['for_day', ...(getUncommonProperties(jsonQuery) || [])]],
    });

    setIsAwaitingJsonQueryUpdate(true);
    setIsJsonQueryUpdated(true);

    if (onClear) {
      onClear();
    }
  }, [jsonQuery, onChange, onClear]);

  const handleShowClick = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick({ source: 'advancedFilters' });
    }

    onApply();

    if (onClose) {
      onClose();
    }
  }, [onApply, onApplyClick, onClose]);

  const handleOnTooltipApply = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick({ source: 'tooltip' });
    }

    onApply();
  }, [onApplyClick, onApply]);

  React.useEffect(() => {
    if (open) {
      setJsonQueryBackup(jsonQuery);
    } else {
      setJsonQueryBackup(undefined);
      setIsAwaitingJsonQueryUpdate(false);
      setIsJsonQueryUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (isAwaitingJsonQueryUpdate) {
      setJsonQueryBackup(jsonQuery);
      setIsAwaitingJsonQueryUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonQuery]);

  return (
    <>
      <SearchResultsTooltip
        open={!open && openTooltip}
        offersCount={jsonQueryCount || 0}
        loading={jsonQueryCountRefreshing}
        onApply={handleOnTooltipApply}
        placement="bottom"
      >
        <div data-name="AdvancedFiltersContainer" className={styles['button']}>
          <Button theme="stroke_secondary" size="XS" onClick={onOpen}>
            Ещё фильтры
          </Button>
          {isAnythingSelected ? <div className={styles['badge']} /> : null}
        </div>
      </SearchResultsTooltip>
      <AdvancedFilters open={!!open} onClearClick={handleClear} onShowClick={handleShowClick} onClose={handleClose}>
        <PikBannerInFiltersContainer />
        {/* instanbul ignore next */}
        <ProfessionalSearchBannerContainer />
        {availableFilters.map(filterKey => {
          const { availability, component: Component } = ADVANCED_FILTERS[filterKey];

          if (availability && !availability({ config, currentLocation, features, jsonQuery })) {
            return null;
          }

          return <Component key={`filter_${filterKey}`} />;
        })}
      </AdvancedFilters>
    </>
  );
};
