import * as React from 'react';

import {
  dealTypeFromJsonQuery,
  FDealType,
  FOfferType,
  getTermValue,
  offerTypeFromJsonQuery,
} from '../../../../../JsonQuery';
import { PublicationDate } from '../../../components/advancedFilters/PublicationDate';
import { EPublishPeriod } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';

export const PublicationDateContainer: React.FC = () => {
  const { onChange, jsonQuery, isMixedFiltersRedesign } = useContext();

  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  const period = getTermValue('publish_period')(jsonQuery);
  const hasPhoto = getTermValue('wp')(jsonQuery);
  const hasVideo = getTermValue('has_video')(jsonQuery);
  const withLayout = getTermValue('with_layout')(jsonQuery);

  const handlePublishPeriodChange = React.useCallback(
    (period: EPublishPeriod | null) => {
      onChange({ action: 'setPublishPeriod', arguments: [period] });
    },
    [onChange],
  );

  const handleHasVideoChange = React.useCallback(
    (hasVideo: boolean | null) => {
      onChange({ action: 'setHasVideo', arguments: [hasVideo] });
    },
    [onChange],
  );

  const handleHasPhotoChange = React.useCallback(
    (hasPhoto: boolean | null) => {
      onChange({ action: 'setHasPhoto', arguments: [hasPhoto] });
    },
    [onChange],
  );

  const handleWithLayoutChange = React.useCallback(
    (withLayout: boolean | null) => {
      onChange({ action: 'setWithLayout', arguments: [withLayout] });
    },
    [onChange],
  );

  return (
    <PublicationDate
      publishPeriod={period}
      hasPhoto={hasPhoto}
      hasVideo={hasVideo}
      withLayout={withLayout}
      onPublishPeriodChange={handlePublishPeriodChange}
      onHasPhotoChange={handleHasPhotoChange}
      onHasVideoChange={handleHasVideoChange}
      onWithLayoutChange={handleWithLayoutChange}
      showWithLayoutFilter={isMixedFiltersRedesign && dealType === FDealType.Sale && offerType === FOfferType.Flat}
    />
  );
};
