import { OnboardingTooltip, TPlacement } from '@cian/ui-kit';
import * as React from 'react';

import { useBuilderChatOnboarding } from './hooks/useBuilderChatOnboarding';
import { TBuilderChatOnboardingType } from './types';
import { BuilderChatOnboardingContent } from '../../components/BuilderChatOnboardingContent';

interface IBuilderChatOnboardingContainerProps {
  children: React.ReactElement;
  type: TBuilderChatOnboardingType;
  placement: TPlacement;
  wide?: boolean;
}

// TODO: Удалить в CD-226143. Онбординги на месяц.
export const BuilderChatOnboardingContainer: React.FC<IBuilderChatOnboardingContainerProps> = ({
  children,
  type,
  placement,
  wide = false,
}) => {
  const { open, onboardingText, closeHandler } = useBuilderChatOnboarding(type);

  if (!open) {
    return children;
  }

  return (
    <OnboardingTooltip
      theme="black"
      content={<BuilderChatOnboardingContent wide={wide} onboardingText={onboardingText} />}
      placement={placement}
      portal={false}
      open={open}
      onClose={closeHandler}
      flip={false}
    >
      <div>{children}</div>
    </OnboardingTooltip>
  );
};
