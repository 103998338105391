import * as React from 'react';

import { EPriceType, getRangeValue, getTermValue } from '../../../../../JsonQuery';
import { Price } from '../../../components/advancedFilters/Price';
import { useContext } from '../../../utils/useContext';

export const PriceContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();

  const price = getRangeValue('price')(jsonQuery);

  const priceType = React.useMemo(() => {
    const isSquareMeterPrice = getTermValue('price_sm')(jsonQuery);

    return isSquareMeterPrice ? EPriceType.SM : EPriceType.Total;
  }, [jsonQuery]);

  const handlePriceMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setPriceMin', arguments: [gte] });
    },
    [onChange],
  );

  const handlePriceMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setPriceMax', arguments: [lte] });
    },
    [onChange],
  );

  const handlePriceTypeChange = React.useCallback(
    (priceType: EPriceType) => {
      onChange({ action: 'setPriceType', arguments: [priceType === EPriceType.SM ? true : null] });
    },
    [onChange],
  );

  return (
    <Price
      price={price}
      priceType={priceType}
      onPriceMinChange={handlePriceMinChange}
      onPriceMaxChange={handlePriceMaxChange}
      onPriceTypeChange={handlePriceTypeChange}
    />
  );
};
