import { type IConfig } from '@cian/config/shared';

import { getRandomBoolean } from './getRandomBoolean';

const NEWBUILDING_BANNER_ROTATION_VALUE_CONFIG_KEY = 'newbuildingBannerRotationValue';
const NEWBUILDING_BANNER_ROTATION_VALUE_COOKIE_KEY = 'newbuildingBannerRotationValue';

/**
 * Возвращает рандомный булеан. Можно переопределить в конфиге или куках, чтобы всегда получать нужный результат.
 */
export function getNewbuildingBannerRandomBoolean(config: IConfig, cookie: Record<string, string>): boolean {
  const newbuildingBannerRotationValue = config.get<boolean>(NEWBUILDING_BANNER_ROTATION_VALUE_CONFIG_KEY);

  if (typeof newbuildingBannerRotationValue === 'boolean') {
    return newbuildingBannerRotationValue;
  }

  if (cookie[NEWBUILDING_BANNER_ROTATION_VALUE_COOKIE_KEY]) {
    return cookie[NEWBUILDING_BANNER_ROTATION_VALUE_COOKIE_KEY] === '1';
  }

  return getRandomBoolean();
}
