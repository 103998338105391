/* eslint-disable max-lines */

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSearch } from './search';
import { isGeoHighwayTag } from './tags/definitions/geo';
import { Dispatch, IAppState, TActions } from '../../common/state';
import { IJsonQueryGeo, IJsonQueryHighway, TGeoValue } from '../../json_query';
import { IDirection } from '../../types/direction';
import { unique } from '../../utils/normalize';
import { directions, IRequestDirectionsData } from '../api/directions';
import { normalizeHighwaysData } from './tags/definitions/geo/highway_helper';

export interface IDirectionsModalOpenedAction {
  type: 'filters/directions_modal/OPENED';
}

export interface IDirectionsDataRequestedAction {
  type: 'filters/directions_modal/DATA_REQUESTED';
}

export interface IDirectionsDataReceivedAction {
  type: 'filters/directions_modal/DATA_RECEIVED';
  directionsData: IDirection[];
  regionId: number;
}

export interface IDirectionsModalClosedAction {
  type: 'filters/directions_modal/CLOSED';
}

export interface IHighwaysChangedAction {
  type: 'filters/directions_modal/HIGHWAYS_CHANGED';
  highwaysIds: number[];
}

export interface IHighwaysSavedAction {
  type: 'filters/directions_modal/HIGHWAYS_SAVED';
}

export function openDirectionsModal() {
  return {
    type: 'filters/directions_modal/OPENED',
  };
}

export function requestDirectionsData() {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { currentLocation, directionsModal } = getState().filters;
    const regionId = directions.getRegionId(currentLocation);

    // В store уже лежат данные для этого региона
    if (regionId === directionsModal.regionId && directionsModal.data && directionsModal.data.length) {
      return;
    }

    dispatch({
      type: 'filters/directions_modal/DATA_REQUESTED',
    });
  };
}

export function handleReceiveDirectionsData(directionsData: IDirection[], regionId: number) {
  return {
    type: 'filters/directions_modal/DATA_RECEIVED',
    directionsData,
    regionId,
  };
}

export function closeDirectionsModal() {
  return {
    type: 'filters/directions_modal/CLOSED',
  };
}

export function* directionsLoad() {
  const state: IAppState = yield select();

  const { currentLocation } = state.filters;
  const regionId = directions.getRegionId(currentLocation);

  const requestDirections: IRequestDirectionsData = yield call(directions.requestData, state.makeRequest, regionId);

  yield put({
    type: 'filters/directions_modal/DATA_RECEIVED',
    directionsData: requestDirections.data,
    regionId,
  });
}

export function* directionsModalOpenSaga() {
  yield takeLatest('filters/directions_modal/DATA_REQUESTED', directionsLoad);
}

export function selectHighways(highwaysIds: number[], regionId?: number) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const {
      filters: { jsonQuery, currentLocation, directionsModal },
      makeRequest,
      logger,
    } = state;
    const curRegionId = directions.getRegionId(currentLocation);
    const directionsPromise: Promise<IRequestDirectionsData | void> =
      curRegionId === regionId && directionsModal.data.length
        ? Promise.resolve()
        : directions.requestData(makeRequest, regionId || curRegionId);

    directionsPromise
      .then(result => {
        if (result) {
          dispatch(handleReceiveDirectionsData(result.data, result.regionId));
        }
        const selectedHighwaysIds = getHighwaysFromJsonQuery(jsonQuery.geo);

        // All highways ids already a selected
        if (highwaysIds.every(highwayId => selectedHighwaysIds.includes(highwayId))) {
          return;
        }

        let newSelectedHighways = selectedHighwaysIds.concat(highwaysIds);
        newSelectedHighways = unique<number>(newSelectedHighways);

        dispatch({
          type: 'filters/directions_modal/HIGHWAYS_CHANGED',
          highwaysIds: newSelectedHighways,
        });
      })
      .catch(() => {
        const dataError = new Error(`Failed to get roads for regionId = ${regionId}`);
        logger.error(dataError);
      });
  };
}

export function deselectHighways(highwaysIds: number[]) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const selectedHighwaysIds = getHighwaysFromJsonQuery(state.filters.jsonQuery.geo);

    dispatch({
      type: 'filters/directions_modal/HIGHWAYS_CHANGED',
      highwaysIds: selectedHighwaysIds.filter(id => !highwaysIds.includes(id)),
    });
  };
}

export function searchBySelectedHighways() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'filters/directions_modal/HIGHWAYS_SAVED',
    });

    dispatch(makeSearch());
  };
}

export function getHighwaysFromJsonQuery(geo: IJsonQueryGeo | undefined): number[] {
  if (geo) {
    return geo.value.reduce((acc, item) => {
      if (item.type === 'highway') {
        acc.push(item.id);
      }

      return acc;
    }, [] as number[]);
  }

  return [];
}

function setHighwaysInJsonQuery(geo: IJsonQueryGeo | undefined, highwayIds: number[]): IJsonQueryGeo {
  let value: TGeoValue[] = [];

  if (geo) {
    value = geo.value.filter(({ type }) => type !== 'highway');
  }

  value = value.concat(highwayIds.map(id => ({ id, type: 'highway' }) as IJsonQueryHighway));

  return {
    type: 'geo',
    value,
  };
}

export function directionsModalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/directions_modal/OPENED':
      return {
        ...state,
        filters: {
          ...state.filters,
          backup: {
            jsonQuery: state.filters.jsonQuery,
            tags: state.filters.tags,
          },
          directionsModal: {
            ...state.filters.directionsModal,
            isVisible: true,
          },
        },
      };
    case 'filters/directions_modal/CLOSED':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...state.filters.backup,
          backup: undefined,
          directionsModal: {
            ...state.filters.directionsModal,
            isVisible: false,
          },
        },
      };
    case 'filters/directions_modal/DATA_REQUESTED':
      return {
        ...state,
        filters: {
          ...state.filters,
          directionsModal: {
            ...state.filters.directionsModal,
            isLoading: true,
          },
        },
      };
    case 'filters/directions_modal/DATA_RECEIVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          directionsModal: {
            ...state.filters.directionsModal,
            isLoading: false,
            data: action.directionsData,
            regionId: action.regionId,
          },
          tagsData: {
            ...state.filters.tagsData,
            directions: action.directionsData,
          },
        },
      };
    case 'filters/directions_modal/HIGHWAYS_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            geo: setHighwaysInJsonQuery(state.filters.jsonQuery.geo, action.highwaysIds),
          },
        },
      };
    case 'filters/directions_modal/HIGHWAYS_SAVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          backup: undefined,
          directionsModal: {
            ...state.filters.directionsModal,
            isVisible: false,
          },
        },
      };

    // Remove only direction from jsonQuery
    case 'filters/tags/TAG_REMOVED': {
      const { tag } = action;

      if (isGeoHighwayTag(tag)) {
        const { directionsModal, jsonQuery } = state.filters;
        const selectedHighwaysIds = getHighwaysFromJsonQuery(jsonQuery.geo);
        const normalizedData = normalizeHighwaysData(selectedHighwaysIds, directionsModal.data);

        const direction = normalizedData.find(({ id }) => id === tag.value);
        let newHighwaysIds = [] as number[];
        if (direction) {
          newHighwaysIds = selectedHighwaysIds.filter(highwayId => {
            if (direction.highways && direction.highways.some(({ id }) => id === highwayId)) {
              return false;
            }

            return true;
          });
        }

        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              geo: setHighwaysInJsonQuery(state.filters.jsonQuery.geo, newHighwaysIds),
            },
          },
        };
      }

      return state;
    }

    default:
      return state;
  }
}
