import React, { memo } from 'react';

import { AdFoxBanner } from '../../../../../../components/AdfoxBanner';
import { AdfoxContextData } from '../../../../../../types/adfox/AdfoxContextData';
import { AdfoxContextProvider, PParams } from '../../../../../../utils/AdfoxContext';
import { stopPropagation } from 'shared/utils/events/stopPropagation';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  place: 'card',
  place__DEPRECATED: 'offersSerp',
  name__DEPRECATED: 'Listing_native_TGB',
};
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '20px' };
const P_PARAMS: PParams = { p1: 'ctbng', p2: 'gbvb' };

type TMortgageCardCreatorBannerProps = {
  position: number;
};

export const MortgageCardCreatorBanner = memo<TMortgageCardCreatorBannerProps>(function MortgageCardCreatorBanner({
  position,
}) {
  return (
    <AdfoxContextProvider size={SIZE} pParams={P_PARAMS} postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA}>
      <AdFoxBanner onClick={stopPropagation} position={position} />
    </AdfoxContextProvider>
  );
});
