import * as React from 'react';

import { EPriceType } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../types/jsonQuery';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { RangeInput } from '../common/RangeInput';

const SQUARE_METER_TYPES_OPTIONS = [
  { label: 'За всё', value: EPriceType.Total },
  { label: 'За м²', value: EPriceType.SM },
];

interface IPriceProps {
  price: IJsonQueryRangeValue | null;
  priceType: EPriceType;
  onPriceMinChange: (gte: number | null) => void;
  onPriceMaxChange: (lte: number | null) => void;
  onPriceTypeChange: (priceType: EPriceType) => void;
}

export const Price: React.FC<IPriceProps> = ({
  price,
  priceType,
  onPriceMaxChange,
  onPriceMinChange,
  onPriceTypeChange,
}) => {
  const { gte: priceMin = null, lte: priceMax = null } = price || {};

  const handlePriceMinChange = useDebouncedCallback(
    (gte: number | null) => {
      onPriceMinChange(gte);
    },
    300,
    [onPriceMinChange],
  );

  const handlePriceMaxChange = useDebouncedCallback(
    (lte: number | null) => {
      onPriceMaxChange(lte);
    },
    300,
    [onPriceMaxChange],
  );

  return (
    <Filter id="advancedFilter_price" label="Цена">
      <InlineFilterGroup>
        <InlineFilter>
          <RangeInput
            containerWidth={232}
            min={0}
            max={999999999999}
            thousandsSeparator=""
            valueMin={priceMin}
            valueMax={priceMax}
            onChangeMin={handlePriceMinChange}
            onChangeMax={handlePriceMaxChange}
          />
        </InlineFilter>
        <InlineFilter>
          <RadioButtonGroup
            onChange={onPriceTypeChange}
            value={priceType}
            options={SQUARE_METER_TYPES_OPTIONS}
            spliced={true}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
