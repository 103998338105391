import { OnboardingTooltip } from '@cian/ui-kit';
import * as React from 'react';

import { useChatOnboarding } from './hooks/useChatOnboarding';
import { ChatOnboardingContent } from '../../../../components/ChatOnboardingContent';
import { TChatOnboarding } from 'shared/types/chatOnboarding';

interface IChatOnboardingContainerProps {
  children: React.ReactElement;
  type: keyof TChatOnboarding;
}

// TODO: Удалить в CD-226143/CD-225150. Онбординги на месяц.
export const ChatOnboardingContainer: React.FC<IChatOnboardingContainerProps> = ({ children, type }) => {
  const { open, onboardingText, closeHandler } = useChatOnboarding(type);

  if (!open) {
    return children;
  }

  return (
    <OnboardingTooltip
      theme="black"
      content={<ChatOnboardingContent>{onboardingText}</ChatOnboardingContent>}
      placement="left"
      portal={false}
      open={open}
      onClose={closeHandler}
      flip={false}
    >
      <div>{children}</div>
    </OnboardingTooltip>
  );
};
