import { ResponseError } from '../../errors';
import { IJsonQuery } from '../../json_query';
import {
  fetchCianApiSiteV1GetQuickLinks as fetchGetQuickLinks,
  TCianApiSiteV1GetQuickLinksResponse,
} from '../../repositories/monolith-python/cian-api/site/v1/get-quick-links';
import { IApplicationContext } from '../../types/applicationContext';
import { IQuickLinks } from '../../types/quickLinks';

interface IFetchQuickLinksParameters {
  subdomain: string;
  jsonQuery: IJsonQuery;
}

async function fetchQuickLinks(
  { httpApi }: IApplicationContext,
  { subdomain, jsonQuery }: IFetchQuickLinksParameters,
): Promise<IQuickLinks | null> {
  const quickLinksData: TCianApiSiteV1GetQuickLinksResponse = await fetchGetQuickLinks({
    httpApi,
    parameters: { jsonQuery },
    config: {
      subdomain,
    },
  });

  if (quickLinksData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchQuickLinks',
      message: quickLinksData.response.data?.message || '',
      details: {
        error: JSON.stringify(quickLinksData.response.data?.errors),
      },
    });
  }

  return quickLinksData.response.data.quickLinks || null;
}

export async function fetchQuickLinksSafe(
  context: IApplicationContext,
  parameters: IFetchQuickLinksParameters,
): Promise<IQuickLinks | null> {
  try {
    return await fetchQuickLinks(context, parameters);
  } catch (ex) {
    const { logger } = context;

    logger.warning(ex, { degradation: 'Быстрые ссылки' });

    return null;
  }
}
