import * as React from 'react';

import { FOfferType, offerTypeFromJsonQuery } from '../../../../../JsonQuery';
import { ObjectType } from '../../../components/advancedFilters/ObjectType';
import { useContext } from '../../../utils/useContext';

export const ObjectTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();

  const offerType = offerTypeFromJsonQuery(jsonQuery);

  const handleObjectTypeChange = React.useCallback(
    (offerType: FOfferType) => {
      onChange({ action: 'setOfferType', arguments: [offerType] });
    },
    [onChange],
  );

  return <ObjectType value={offerType} onChange={handleObjectTypeChange} />;
};
