import { addOfferToComparison } from './addOfferToComparison';
import { Dispatch } from '../../../../common/state';
import { TOfferCategory } from '../../../../offer/types';
import { TThunkAction } from '../../../../types/redux';
import { ECategory } from 'shared/repositories/offers-comparison/entities/offer/OfferDesktopSchema';

interface IParameters {
  offerId: number;
  category: ECategory | TOfferCategory | string | null;
}

export const onAddOfferToComparisonSuccessAuth =
  (parameters: IParameters) => (): TThunkAction => (dispatch: Dispatch) =>
    dispatch(addOfferToComparison(parameters));
