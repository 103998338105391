import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import styles from './MapModal.css';
import pin from './Pin.svg';
import pinFavorite from './PinFavorite.svg';
import { loadYmapsApi } from '../../../../../shared/utils/ymaps';
import { ICoordinates } from '../../types/offer';

interface IMapModalProps {
  open: boolean;
  coordinates: ICoordinates;
  isFavorite: boolean;
  onClose(): void;
  onOpen?(): void;
}

export function MapModal({ open, coordinates, onClose, isFavorite }: IMapModalProps) {
  const container = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (open) {
      loadYmapsApi({
        require: ['Map', 'Placemark', 'control.ZoomControl'],
      }).then(ymaps => {
        if (container.current) {
          const { lat, lng } = coordinates;

          // Смещение центра карты для того, чтобы иконка оказалась по центру
          const OFFSET_BY_ICON_SIZE = 0.001;

          const xPosition = typeof lat === 'number' ? lat + OFFSET_BY_ICON_SIZE : 0;
          const yPosition = typeof lng === 'number' ? lng : 0;

          const map = new ymaps.Map(container.current, {
            behaviors: ['drag', 'dblClickZoom', 'rightMouseButtonMagnifier'],
            center: [xPosition, yPosition],
            zoom: 14,
          });

          map.controls.add('zoomControl', {
            position: {
              left: 10,
              top: 92,
            },
          });

          const myPlacemark = new ymaps.Placemark(
            [lat, lng],
            {},
            {
              iconImageOffset: [-27, -54],
              iconImageSize: [54, 54],
              iconLayout: 'default#image',
              iconImageHref: isFavorite ? pinFavorite : pin,
            },
          );

          map.geoObjects.add(myPlacemark);
        }
      });
    }
  }, [open, coordinates]);

  return (
    <ModalWindow
      content={<div className={styles['map']} ref={container} />}
      escape
      fixed={false}
      outside
      size="M"
      open={open}
      onClose={onClose}
    />
  );
}
