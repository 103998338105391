import { Dispatch } from 'shared/common/state';
import { TThunkAction } from 'shared/types/redux';
import { actionGenerator } from 'shared/utils/redux';

export enum EConsultantCianChatActionTypes {
  OpenAutorisationMotivationPopup = 'consultantCianChat/autorisationMotivationPopupOpen',
  CloseAutorisationMotivationPopup = 'consultantCianChat/autorisationMotivationPopupClose',
  OpenChat = 'consultantCianChat/open',
  CloseChat = 'consultantCianChat/close',
}

export const openConsultantCianChat = actionGenerator<EConsultantCianChatActionTypes.OpenChat, string>(
  EConsultantCianChatActionTypes.OpenChat,
);

export const closeConsultantCianChat = actionGenerator<EConsultantCianChatActionTypes.CloseChat, void>(
  EConsultantCianChatActionTypes.CloseChat,
);

/* istanbul ignore next */
export const onConsultantCianCloseNoAuth = () => (): TThunkAction => (dispatch: Dispatch) => {
  dispatch(closeConsultantCianChat());
};

export const openAutorisationMotivationConsultantCianChat = actionGenerator<
  EConsultantCianChatActionTypes.OpenAutorisationMotivationPopup,
  void
>(EConsultantCianChatActionTypes.OpenAutorisationMotivationPopup);

export const closeAutorisationMotivationConsultantCianChat = actionGenerator<
  EConsultantCianChatActionTypes.CloseAutorisationMotivationPopup,
  void
>(EConsultantCianChatActionTypes.CloseAutorisationMotivationPopup);

export type TConsultantCianChatActions =
  | ReturnType<typeof openConsultantCianChat>
  | ReturnType<typeof closeConsultantCianChat>
  | ReturnType<typeof openAutorisationMotivationConsultantCianChat>
  | ReturnType<typeof closeAutorisationMotivationConsultantCianChat>;
