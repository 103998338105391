// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TCianApiSiteV1QueryToGeoTagsRequest,
  TCianApiSiteV1QueryToGeoTagsModel,
  IMappers,
  TCianApiSiteV1QueryToGeoTagsResponse,
  TCianApiSiteV1QueryToGeoTagsResponse_1,
  TCianApiSiteV1QueryToGeoTagsResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TCianApiSiteV1QueryToGeoTagsModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/query-to-geo-tags/',
  requestType: 'json',
  interserviceAuthEnabled: true,
} as TCianApiSiteV1QueryToGeoTagsModel;

export function createCianApiSiteV1QueryToGeoTagsModel(
  parameters: TCianApiSiteV1QueryToGeoTagsRequest,
): TCianApiSiteV1QueryToGeoTagsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICianApiSiteV1QueryToGeoTagsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TCianApiSiteV1QueryToGeoTagsRequest;
}

export async function fetchCianApiSiteV1QueryToGeoTags<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICianApiSiteV1QueryToGeoTagsOptions<TResponse200, TResponse400>): Promise<
  TCianApiSiteV1QueryToGeoTagsResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createCianApiSiteV1QueryToGeoTagsModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TCianApiSiteV1QueryToGeoTagsResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TCianApiSiteV1QueryToGeoTagsResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCianApiSiteV1QueryToGeoTagsResponse;
}
