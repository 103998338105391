import { Image } from '@cian/ui-kit/image';
import classNames from 'classnames';
import * as React from 'react';

import styles from './Gallery.css';
import { Carousel, IAutoScrollSettings, IAutoZoomSettings, TMedia } from '../../../../../shared/components/Carousel';

interface IGalleryProps {
  media: TMedia[];
  deoptimised?: boolean;
  bookedFromCian?: boolean;
  bookedFromDeveloper?: boolean;
  autoScroll: boolean;
  autoZoom: boolean;
  autoZoomSettings?: IAutoZoomSettings;
  autoScrollSettings?: IAutoScrollSettings;
  onChangeSlide(): void;
}

export function Gallery({
  media,
  deoptimised,
  bookedFromCian,
  bookedFromDeveloper,
  autoScroll,
  autoZoom,
  autoScrollSettings,
  autoZoomSettings,
  onChangeSlide,
}: IGalleryProps) {
  if (media.length === 0) {
    return (
      <div className={classNames(styles['cont'], styles['cont--empty'])}>
        <Image src="" />
      </div>
    );
  }

  return (
    <div data-name="Gallery" className={styles['cont']}>
      <Carousel
        autoScroll={autoScroll}
        autoZoom={autoZoom}
        autoZoomSettings={autoZoomSettings}
        autoScrollSettings={autoScrollSettings}
        media={media}
        deoptimised={deoptimised}
        bookedFromCian={bookedFromCian}
        bookedFromDeveloper={bookedFromDeveloper}
        onSlideChange={onChangeSlide}
      />
    </div>
  );
}
