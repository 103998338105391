import React, { memo } from 'react';

import { AdFoxBanner } from '../../../../../../components/AdfoxBanner';
import { AdfoxContextData } from '../../../../../../types/adfox/AdfoxContextData';
import { AdfoxContextProvider, PParams } from '../../../../../../utils/AdfoxContext';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  place: 'between_offers',
  place__DEPRECATED: 'offersSerp',
  name__DEPRECATED: 'Listing_parallax',
};
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '500px' };
const P_PARAMS_NEWBUILDING: PParams = { p1: 'dazap', p2: 'y' };
const P_PARAMS: PParams = { p1: 'bvkoa', p2: 'y' };

type ParallaxBannerProps = {
  isNewbuilding: boolean;
  position: number;
};

export const ParallaxBanner = memo<ParallaxBannerProps>(function ParallaxBanner(props) {
  const { isNewbuilding, position } = props;

  return (
    <AdfoxContextProvider
      size={SIZE}
      pParams={isNewbuilding ? P_PARAMS_NEWBUILDING : P_PARAMS}
      postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA}
    >
      <AdFoxBanner position={position} />
    </AdfoxContextProvider>
  );
});
