import { IAddOfferToComparisonResponse200 } from '../../../../repositories/offers-comparison/v2/add-offer-to-comparison';
import { IAddOfferToComparisonResponseSchema } from 'shared/repositories/offers-comparison/entities/add_offer_to_comparison/AddOfferToComparisonResponseSchema';

export const mapAddOfferToComparisonResponse = (
  response: IAddOfferToComparisonResponseSchema,
): IAddOfferToComparisonResponse200 => {
  return {
    statusCode: 200,
    headers: [],
    response,
  };
};
