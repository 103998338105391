import { EDecorationType } from '../../../../../packages/JsonQuery';
import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IDecorationTagType = 'decorations_list';

export interface IDecorationListTag {
  type: 'decorations_list';
  value: EDecorationType;
  label: string;
}

export const isDecorationListTag = (tag: TTag): tag is IDecorationListTag => tag.type === IDecorationTagType;

const LABELS_MAP: Map<EDecorationType, string> = new Map([
  [EDecorationType.Without, 'Без отделки'],
  [EDecorationType.PreFine, 'Черновая'],
  [EDecorationType.Fine, 'Чистовая'],
  [EDecorationType.FineWithFurniture, 'Чистовая c мебелью'],
]);

export const decorationListTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.decorations_list && jsonQuery.decorations_list.value;

    let nextTags = tags.filter(
      /* istanbul ignore next */
      tag => tag.type !== IDecorationTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: string) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IDecorationListTag => isDecorationListTag(tag) && tag.value === value,
            () => ({
              type: IDecorationTagType,
              value: value as EDecorationType,
              label: LABELS_MAP.get(value as EDecorationType) as string,
            }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
