import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TBuilderChatOnboardingType } from '../types';
import { getBuilderChatOnboardingCookie, setBuilderChatOnboardingCookie } from '../utils/builderChatOnboardingCookie';
import { selectChat } from 'shared/selectors/selectChat';
import { useApplicationContext } from 'shared/utils/applicationContext';

interface IOnboardingTooltipResult {
  open: boolean;
  onboardingText: string;
  closeHandler: () => void;
}

const ONBOARDING_COOKIE_NAME_MAP: Record<TBuilderChatOnboardingType, string> = {
  layoutModal: 'frontend-serp.layout_builder_chat_onboarding_shown',
  newbuildingHeader: 'frontend-serp.header_builder_chat_onboarding_shown',
};

export const useBuilderChatOnboarding = (onboardingType: TBuilderChatOnboardingType): IOnboardingTooltipResult => {
  const { config } = useApplicationContext();
  const [open, setOpen] = useState(false);
  const { newbuilding, isOpened: isChatOpened, openAfterAuth } = useSelector(selectChat);
  const onboardingText =
    config.get<string>('Newbuilding.CianChatOnboarding.Text') ?? 'Теперь можно написать застройщику в чате';

  const cookieName = ONBOARDING_COOKIE_NAME_MAP[onboardingType];

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const isOnboardingEnabled = config.get<boolean>('Newbuilding.CianChatOnboarding.Enabled');

    if (isOnboardingEnabled && !getBuilderChatOnboardingCookie(cookieName)) {
      setOpen(true);
      setBuilderChatOnboardingCookie(cookieName);
    }
  }, [cookieName, config]);

  useEffect(() => {
    if (open && newbuilding && (isChatOpened || openAfterAuth)) {
      closeHandler();
    }
  }, [open, newbuilding, isChatOpened, openAfterAuth, closeHandler]);

  return {
    open,
    onboardingText,
    closeHandler,
  };
};
