import { FOfferType } from '../../../../../JsonQuery';
import { TSquareMeterAreaType } from '../../../components/advancedFilters/SquareMeterArea';
import { TAvailableModifierActions } from '../../../types/modifier';

type AvailableAriaTypes = { [key in FOfferType]: TSquareMeterAreaType[] };

export const DEFAULT_AREA_TYPES: TSquareMeterAreaType[] = ['general', 'kitchen', 'living'];
export const AVAILABLE_AREA_TYPES: Partial<AvailableAriaTypes> = {
  [FOfferType.Room]: ['general', 'room', 'kitchen'],
  [FOfferType.Garage]: ['general'],
};

export const MIN_SQUARE_TO_ACTION_MAP: {
  [key in TSquareMeterAreaType]: TAvailableModifierActions;
} = {
  general: 'setTotalAreaMin',
  kitchen: 'setKitchenAreaMin',
  living: 'setLivingAreaMin',
  room: 'setRoomAreaMin',
};

export const MAX_SQUARE_TO_ACTION_MAP: {
  [key in TSquareMeterAreaType]: TAvailableModifierActions;
} = {
  general: 'setTotalAreaMax',
  kitchen: 'setKitchenAreaMax',
  living: 'setLivingAreaMax',
  room: 'setRoomAreaMax',
};
