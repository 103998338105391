import { Dispatch, IAppState } from '../../../../common/state';
import { TOfferCategory } from '../../../../offer/types';
import { addOfferToComparisonRequest } from '../../../../services/offersComparison';
import { isOfferCategory } from '../../../../utils/offer/isOfferCategory';
import { AddToComparisonActionType, EComparisonNotificationCategory, SetComparisonNotificationType } from '../index';
import { ECategory } from 'shared/repositories/offers-comparison/entities/offer/OfferDesktopSchema';

interface IParameters {
  offerId: number;
  category: ECategory | TOfferCategory | string | null;
}

export function addOfferToComparison(parameters: IParameters) {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { httpApi, logger } = getState();
    const { offerId, category } = parameters;

    if (!isOfferCategory(category)) {
      logger.warning('Не удалось добавить в сравнение объявление с неизвестной категорией', {
        offerId,
        category,
      });

      return;
    }

    const response = await addOfferToComparisonRequest({ httpApi, parameters: { offerId, category } });

    if (response) {
      const { success, text, comparisonUrl, offerId } = response;

      if (success) {
        dispatch({
          type: AddToComparisonActionType,
          offerId,
        });
      }

      dispatch({
        type: SetComparisonNotificationType,
        text,
        url: comparisonUrl,
        category: success ? EComparisonNotificationCategory.Add : EComparisonNotificationCategory.Limit,
        offerId,
      });
    }
  };
}
