import { ResponseError } from '../../errors';
import { IApplicationContext } from '../../types/applicationContext';
import { fetchGetMeta, TGetMetaRequest, TGetMetaResponse } from 'shared/repositories/search-offers-index/v2/get-meta';
import { ISearchMeta } from 'shared/types/searchMeta';

export async function requestMeta({ httpApi }: IApplicationContext, parameters: TGetMetaRequest): Promise<ISearchMeta> {
  const searchMeta: TGetMetaResponse = await fetchGetMeta({
    httpApi,
    parameters,
  });

  if (searchMeta.statusCode !== 200) {
    throw new ResponseError({
      domain: 'requestMeta',
      message: searchMeta.response.message || '',
      details: {
        error: JSON.stringify(searchMeta.response.errors),
      },
    });
  }

  return searchMeta.response.data;
}
