import { INewbuildingLayoutWithOffers } from '../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';
import { IGetNewbuildingLayoutWithOffersDesktopResponseSchema } from 'shared/repositories/search-offers/entities/entities/GetNewbuildingLayoutWithOffersDesktopResponseSchema';

export const prepareNewbuildingLayoutWithOffers = (
  response: IGetNewbuildingLayoutWithOffersDesktopResponseSchema,
): INewbuildingLayoutWithOffers | null => {
  if (!response || !response.layout) {
    return null;
  }

  return response.layout;
};
