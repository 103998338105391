import React from 'react';

import { AdFoxBanner } from '../../../../../shared/components/AdfoxBanner';
import { AdfoxContextData } from '../../../../../shared/types/adfox/AdfoxContextData';
import { AdfoxContextProvider, PParams } from '../../../../../shared/utils/AdfoxContext';
import { FOfferType } from '../../../../JsonQuery';
import { BannerInFilter } from '../../components/advancedFilters/common/BannerInFilter';
import { useOfferType } from '../../utils/useOfferType';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  place: 'additional_filters',
  place__DEPRECATED: 'offersSerp',
  name__DEPRECATED: 'Listing_filter_more',
};
const P_PARAMS: PParams = { p1: 'crzye', p2: 'fmsa' };
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '100px' };

export const PikBannerInFiltersContainer: React.FC = () => {
  const offerType = useOfferType();

  if (offerType !== FOfferType.FlatNew) {
    return null;
  }

  return (
    <BannerInFilter>
      <AdfoxContextProvider size={SIZE} pParams={P_PARAMS} postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA}>
        <AdFoxBanner />
      </AdfoxContextProvider>
    </BannerInFilter>
  );
};
