import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingBrokerConsultantBanner } from './NewbuildingBrokerConsultantBanner';
import { selectNewbuildingBrokerConsultantBannerUrl } from 'shared/selectors/newbuildingBrokerConsultantBanner';

export const NewbuildingBrokerConsultantBannerContainer: React.FC = () => {
  const url = useSelector(selectNewbuildingBrokerConsultantBannerUrl) as string;

  return <NewbuildingBrokerConsultantBanner url={url} />;
};
