/* eslint-disable react/no-danger */
/* eslint-disable max-lines */
import { type IConfig } from '@cian/config/shared';
import { ErrorLogComponent } from '@cian/error-log-component';
import { getGlobalEventsLogs } from '@cian/events-log';
import { type IHttpApi } from '@cian/http-api/shared';
import { type ILogger } from '@cian/logger';
import { Spinner } from '@cian/ui-kit';
import classNames from 'classnames';
import * as React from 'react';

import { readAllOffersForSavedSearches } from '../../../api/saved_searches/readAllOffers';
import { SeoTopBanners } from '../../../components/seo_top_banners';
import { ChatPopupContainer } from '../../../containers/ChatPopupContainer';
import { FavoritesFoldersContainer } from '../../../containers/FavoritesFoldersContainer';
import { FiltersContainer } from '../../../containers/FiltersContainer';
import { HeaderSectionContainer } from '../../../containers/HeaderSectionContainer';
import { JournalHighlightsContainer } from '../../../containers/JournalHighlightsContainer';
import { NewbuildingBlackFridayTopBannerContainer } from '../../../containers/NewbuildingBlackFridayTopBannerContainer';
import { NewbuildingLayoutsContainer } from '../../../containers/NewbuildingLayoutsContainer';
import { NewbuildingPromoBuilderOffersContainer } from '../../../containers/NewbuildingPromoBuilderOffersContainer';
import { LazyNewbuildingPromoCarouselContainer } from '../../../containers/NewbuildingPromoCarousel';
import { NotAvailablePopupContainer } from '../../../containers/NotAvailablePopupContainer';
import { PaginationContainer } from '../../../containers/PaginationContainer';
import { ProfessionalSearchPromoContainer } from '../../../containers/ProfessionalSearchPromoContainer';
import { RegionalDistrictsModalContainer } from '../../../containers/RegionalDistrictsModalContainer';
import { SeoMarkedListContainer } from '../../../containers/SeoMarkedListContainer';
import { SpecialProjectBannerContainer } from '../../../containers/SpecialProjectBannerContainer';
import { SuggestionsContainer } from '../../../containers/SuggestionsContainer';
import { UndergroundMapModalContainer } from '../../../containers/UndergroundMapContainer';
import { VillagesRecommendationsContainer } from '../../../containers/VillagesRecommendationsContainer';
import { DirectionsContainer } from '../../../filters/components/directions/modal/container';
import { DistrictsContainer } from '../../../filters/components/districts/container';
import LocationSwitherContainer from '../../../filters/components/location_switcher/container';
import { MapContainer } from '../../../filters/components/map/container';
import { TTag } from '../../../filters/state/tags';
import { IJsonQuery } from '../../../json_query';
import { RealtorRequestTrapContainer } from '../../../serp/components/demand';
import { FastLinksContainer } from '../../../serp/components/fast_links/container';
import { HideOfferTopPopup } from '../../../serp/components/hide_offer_top_popup';
import { NewobjectsContainer } from '../../../serp/components/newobjects/container';
import { NewobjectsFromDeveloperContainer } from '../../../serp/components/newobjects_from_developer/container';
import { OffersContainer } from '../../../serp/components/offers/container';
import { PromoteMobile } from '../../../serp/components/promote_mobile';
import { ComparisonNotificationContainerLoadable } from '../../../serp/containers/ComparisonNotificationContainer';
import { EmptyPageSummaryContainer } from '../../../serp/containers/EmptyPageSummaryContainer';
import { NewbuildingFiltersContainer } from '../../../serp/containers/NewbuildingFilters/NewbuildingFiltersContainer';
import { QuickLinksContainer } from '../../../serp/containers/QuickLinksContainer';
import { SeoBotCatcherLinksContainer } from '../../../serp/containers/SeoBotCatcherLinksContainer';
import { SeoCrossLinksContainer } from '../../../serp/containers/SeoCrossLinksContainer';
import { SeoTabsLinksContainer } from '../../../serp/containers/SeoTabsLinksContainer';
import { SubsidisedMortgageZeroPlaceBannerContainer } from '../../../serp/containers/SubsidisedMortgageZeroPlaceBannerContainer';
import { SuburbanCatalogsContainer } from '../../../serp/containers/SuburbanCatalogsContainer';
import { EHideOfferState } from '../../../serp/state/hide_offer';
import { IComparisonNotification } from '../../../serp/state/offersComparison';
import { ILocation, TLocation } from '../../../types/location';
import { IQuickLinks } from '../../../types/quickLinks';
import { TSuburbanCatalog } from '../../../types/suburbanCatalog';
import { TUser } from '../../../types/user';
import { isArrayWithItems } from '../../../utils';
import { parseHash } from '../../../utils/hash';
import { logComponentError } from '../../../utils/log_component_error';
import { saveJsonQueryInLocalStorage } from '../../../utils/save_json_query';
import { TDeviceType } from '../../state';
import { ChatsContainerLoadable } from '../ChatsContainer';
import { NewbuildingConsultantPopupContainer } from '../NewbuildingConsultantPopup';
import { PreInfiniteBanner } from '../PreInfiniteBanner';
import { EPreInfiniteBannerTypes } from '../PreInfiniteBanner/PreInfiniteBanner';
import { dealTypeFromJsonQuery, FDealType, FOfferType, offerTypeFromJsonQuery } from 'shared/utils/category';

const style = require('./index.css');

export interface IContext {
  logger: ILogger;
}

export interface ISearchEngineResultsPageProps {
  currency: string;
  context: IContext;
  deviceType: TDeviceType;
  fullUrl: string;
  isFetching: boolean;
  isPromotionMobileClosed: boolean;
  isPrintEnabled: boolean;
  isSpecialProjectAdfoxBannerVisible: boolean;
  isNewbuildingPromoBuilderOffersVisible: boolean;
  isProfessionalSearchPromoBannerVisible: boolean;
  isSubsidisedMortgageZeroPlaceBannerVisible: boolean;
  isBlackFridayBannerVisible: boolean;
  isResidentialComplexLayouts: boolean;
  subdomain: string;
  quickLinks: IQuickLinks | null;
  aggregatedOffers: number;

  regions: ILocation[];
  currentLocation: TLocation;
  jsonQuery: IJsonQuery;
  tags: TTag[];
  isSeoTabsLinksAvailable: boolean;

  user: TUser;
  isDirectionsModalVisible: boolean;
  isDistrictModalVisible: boolean;
  isLocationSwitcherVisible: boolean;
  isMapModalVisible: boolean;
  isRegionalDistrictsModalVisible: boolean;
  isStalkerBannerShown: boolean;
  isMetroModalVisible: boolean;
  isOfficeCalculatorVisible: boolean;
  isSignInTriggerModalOpen: boolean;
  showTrap: boolean;
  isTopPopupOpened: boolean;
  hideOfferState: EHideOfferState;
  hideOfferErrorMessage: string;
  lkUrl: string;

  httpApi: IHttpApi;
  queryString: string;

  showPreInfinteSaveSearchBanner?: boolean;

  currentUrl: string | undefined;
  isOfferInSavedSearch?: boolean;
  config: IConfig;

  comparisonNotification: IComparisonNotification | null;
  suburbanCatalogsTypes: TSuburbanCatalog[];
}

export interface ISearchEngineResultsPageDispatchProps {
  setHideOfferState(hideOfferState: EHideOfferState): void;
  setLoading(): void;
}

export interface ISearchEngineResultsState {
  isMounted: boolean;
}

export type TSearchEngineResultsPageProps = ISearchEngineResultsPageProps & ISearchEngineResultsPageDispatchProps;

export class SearchEngineResultsPage extends React.Component<TSearchEngineResultsPageProps, ISearchEngineResultsState> {
  public context: IContext;
  public state = {
    isMounted: false,
  };

  /* istanbul ignore next */
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logComponentError({
      error,
      errorInfo,
      logger: this.props.context.logger,
    });
  }

  /* istanbul ignore next */
  public componentDidMount() {
    const { setHideOfferState, httpApi, context, queryString, jsonQuery, fullUrl } = this.props;

    if (
      offerTypeFromJsonQuery(jsonQuery) === FOfferType.FlatOld &&
      dealTypeFromJsonQuery(jsonQuery) === FDealType.Sale
    ) {
      const url = new URL(fullUrl);
      url.pathname = 'cat.php';
      url.search = queryString;

      const globalLog = getGlobalEventsLogs();

      globalLog.produce('residentialComplexesTooltip', { type: 'open', value: url.toString() });
    }

    saveJsonQueryInLocalStorage(jsonQuery);

    /**
     * При уходе с выдачи по сохраненному поиску отметить все "новые" оферы просмотренными
     */
    window.addEventListener('beforeunload', () => {
      const searchParams = new URLSearchParams(queryString);
      if (searchParams.has('saved_search_id')) {
        const savedSearchId = Number(searchParams.get('saved_search_id'));
        readAllOffersForSavedSearches(httpApi, { savedSearchId }, context.logger);
      }
    });

    /* istanbul ignore next */
    window.addEventListener('popstate', async () => {
      this.props.setLoading();
      window.location.reload();
    });

    const parsedHash = parseHash(window.location.hash);
    if (Object.keys(parsedHash).includes('HIDE')) {
      setHideOfferState(parsedHash['HIDE'] as EHideOfferState);
      history.pushState('', document.title, window.location.pathname + window.location.search);
    }

    this.setState({ isMounted: true });
  }

  /* istanbul ignore next */
  public componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  /* istanbul ignore next */
  public render() {
    const {
      deviceType,
      isFetching,
      isPrintEnabled,
      isPromotionMobileClosed,
      quickLinks,
      isTopPopupOpened,
      hideOfferState,
      hideOfferErrorMessage,
      showTrap,
      isOfferInSavedSearch,
      comparisonNotification,
      isSpecialProjectAdfoxBannerVisible,
      isNewbuildingPromoBuilderOffersVisible,
      isProfessionalSearchPromoBannerVisible,
      isSubsidisedMortgageZeroPlaceBannerVisible,
      isBlackFridayBannerVisible,
      suburbanCatalogsTypes,
      isResidentialComplexLayouts,
      isSeoTabsLinksAvailable,
    } = this.props;

    const isQuickLinksAvailable =
      quickLinks &&
      isArrayWithItems(quickLinks.links) &&
      quickLinks.links.some(({ offersCount }) => Boolean(offersCount));

    return (
      <div
        className={classNames(style['serp'], style['serp--light'])}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <SeoMarkedListContainer />
        <ErrorLogComponent logger={this.props.context.logger}>
          <FiltersContainer />
        </ErrorLogComponent>
        {isFetching && (
          <div className={style['preloadOverlay']}>
            <div className={style['preloader']}>
              <Spinner size={50} />
            </div>
          </div>
        )}
        <ErrorLogComponent logger={this.props.context.logger}>
          <HeaderSectionContainer />
        </ErrorLogComponent>

        <ErrorLogComponent logger={this.props.context.logger}>
          <EmptyPageSummaryContainer />
        </ErrorLogComponent>

        {deviceType !== 'desktop' && !isPromotionMobileClosed && (
          <ErrorLogComponent logger={this.props.context.logger}>
            <PromoteMobile />
          </ErrorLogComponent>
        )}
        {isSeoTabsLinksAvailable && (
          <ErrorLogComponent logger={this.props.context.logger}>
            <SeoTabsLinksContainer />
          </ErrorLogComponent>
        )}
        {isQuickLinksAvailable && !isSeoTabsLinksAvailable && (
          <ErrorLogComponent logger={this.props.context.logger}>
            <QuickLinksContainer
              banner={
                suburbanCatalogsTypes.length <= 1 ? (
                  <SuburbanCatalogsContainer catalogsTypes={suburbanCatalogsTypes} />
                ) : undefined
              }
            />
          </ErrorLogComponent>
        )}
        <ErrorLogComponent logger={this.props.context.logger}>
          <NewbuildingFiltersContainer />
        </ErrorLogComponent>
        {(!isQuickLinksAvailable || suburbanCatalogsTypes.length > 1) && (
          <ErrorLogComponent logger={this.props.context.logger}>
            <SuburbanCatalogsContainer catalogsTypes={suburbanCatalogsTypes} fullWidth />
          </ErrorLogComponent>
        )}
        <ErrorLogComponent logger={this.props.context.logger}>
          {isSubsidisedMortgageZeroPlaceBannerVisible ? (
            <SubsidisedMortgageZeroPlaceBannerContainer />
          ) : isResidentialComplexLayouts ? null : isNewbuildingPromoBuilderOffersVisible ? (
            <NewbuildingPromoBuilderOffersContainer />
          ) : isSpecialProjectAdfoxBannerVisible ? (
            <SpecialProjectBannerContainer />
          ) : isProfessionalSearchPromoBannerVisible ? (
            <ProfessionalSearchPromoContainer />
          ) : isBlackFridayBannerVisible ? (
            <NewbuildingBlackFridayTopBannerContainer />
          ) : null}
        </ErrorLogComponent>
        <ErrorLogComponent logger={this.props.context.logger}>
          {isResidentialComplexLayouts ? <NewbuildingLayoutsContainer /> : <OffersContainer />}
        </ErrorLogComponent>
        <ErrorLogComponent logger={this.props.context.logger}>
          {isResidentialComplexLayouts ? null : <NewobjectsContainer />}
        </ErrorLogComponent>
        <ErrorLogComponent logger={this.props.context.logger}>
          {isResidentialComplexLayouts ? null : <NewobjectsFromDeveloperContainer />}
        </ErrorLogComponent>

        <ErrorLogComponent logger={this.props.context.logger}>
          {!isResidentialComplexLayouts && this.props.showPreInfinteSaveSearchBanner && (
            <PreInfiniteBanner
              type={EPreInfiniteBannerTypes.subscription}
              isOfferInSavedSearch={isOfferInSavedSearch}
            />
          )}
        </ErrorLogComponent>

        <ErrorLogComponent logger={this.props.context.logger}>
          {!isResidentialComplexLayouts && <SuggestionsContainer />}
        </ErrorLogComponent>

        <ErrorLogComponent logger={this.props.context.logger}>
          {!isResidentialComplexLayouts && <PaginationContainer />}
        </ErrorLogComponent>

        <ErrorLogComponent logger={this.props.context.logger}>
          {!isResidentialComplexLayouts && <LazyNewbuildingPromoCarouselContainer />}
        </ErrorLogComponent>

        <ErrorLogComponent logger={this.props.context.logger}>
          {!isResidentialComplexLayouts && <VillagesRecommendationsContainer />}
        </ErrorLogComponent>

        {!isResidentialComplexLayouts && showTrap && (
          <div className={style['wrapper']} data-testid="realtor_request_trap">
            <ErrorLogComponent logger={this.props.context.logger}>
              <RealtorRequestTrapContainer />
            </ErrorLogComponent>
          </div>
        )}
        <ErrorLogComponent logger={this.props.context.logger}>
          <FastLinksContainer />
        </ErrorLogComponent>
        {!isPrintEnabled && (
          <ErrorLogComponent logger={this.props.context.logger}>
            <SeoTopBanners />
          </ErrorLogComponent>
        )}

        <ErrorLogComponent logger={this.props.context.logger}>
          <JournalHighlightsContainer />
        </ErrorLogComponent>

        <ErrorLogComponent logger={this.props.context.logger}>
          <SeoCrossLinksContainer />
        </ErrorLogComponent>

        <ErrorLogComponent logger={this.props.context.logger}>
          <UndergroundMapModalContainer />
        </ErrorLogComponent>

        {this.props.isMapModalVisible && (
          <ErrorLogComponent logger={this.props.context.logger}>
            <MapContainer />
          </ErrorLogComponent>
        )}
        {this.props.isLocationSwitcherVisible && (
          <ErrorLogComponent logger={this.props.context.logger}>
            <LocationSwitherContainer />
          </ErrorLogComponent>
        )}
        {this.props.isDistrictModalVisible && (
          <ErrorLogComponent logger={this.props.context.logger}>
            <DistrictsContainer />
          </ErrorLogComponent>
        )}
        {this.props.isDirectionsModalVisible && (
          <ErrorLogComponent logger={this.props.context.logger}>
            <DirectionsContainer />
          </ErrorLogComponent>
        )}
        {this.props.isRegionalDistrictsModalVisible && (
          <ErrorLogComponent logger={this.props.context.logger}>
            <RegionalDistrictsModalContainer />
          </ErrorLogComponent>
        )}
        {this.state.isMounted && isTopPopupOpened && (
          <HideOfferTopPopup
            isError={hideOfferState === EHideOfferState.ERROR}
            lkUrl={this.props.lkUrl}
            errorMessage={hideOfferErrorMessage}
          />
        )}
        <NewbuildingConsultantPopupContainer />
        <ChatsContainerLoadable />
        {/** TODO: Возможно стоит подумать об архитектуре 3х разных чатов */}
        <ChatPopupContainer />

        <NotAvailablePopupContainer />

        <FavoritesFoldersContainer />

        <ErrorLogComponent logger={this.props.context.logger}>
          <SeoBotCatcherLinksContainer />
        </ErrorLogComponent>
        {comparisonNotification && <ComparisonNotificationContainerLoadable notification={comparisonNotification} />}
      </div>
    );
  }
}
