import { connect, IAdFoxBannerOnErrorError, TAdFoxBannerProps } from '@cian/adfox-component';
import { withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';
import { NoSsr } from '@cian/react-utils';
import * as React from 'react';

import { OwnTemplates } from './internal/components/OwnTemplates';
import { useAdsDisabled } from './internal/hooks/useAdsDisabled';
import { useAdfoxContext } from '../../utils/AdfoxContext';

const AdFoxBannerComponent = connect(OwnTemplates);

type AdFoxBannerProps = {
  galleryPosition?: number;
  position?: number;
  onClick?(event: MouseEvent): void;
  onStub?(): void;
  onError?(error: IAdFoxBannerOnErrorError): void;
};

export const AdFoxBanner = React.memo<AdFoxBannerProps>(function AdFoxBanner(props) {
  const { onStub, onError, onClick, position, galleryPosition } = props;

  const { params, size, postViewAnalyticsData } = useAdfoxContext();

  const [isHidden, setHidden] = React.useState(false);

  const handleStub = React.useCallback(() => {
    setHidden(true);

    onStub?.();
  }, []);

  const handleError = React.useCallback((error: IAdFoxBannerOnErrorError) => {
    setHidden(true);

    onError?.(error);
  }, []);

  useAdsDisabled(() => {
    handleStub();
  }, []);

  const banner = React.useMemo(() => {
    if (isHidden) {
      return null;
    }

    const bannerProps: TAdFoxBannerProps = {
      onStub: handleStub,
      onError: handleError,
      params,
      ...size,
    };

    const AdfoxWithPostView = withAdfoxPostViewAnalytics(AdFoxBannerComponent, {
      position,
      galleryPosition,
      onClick,
      ...postViewAnalyticsData,
    });

    return <AdfoxWithPostView {...bannerProps} />;
  }, [isHidden]);

  return <NoSsr>{banner}</NoSsr>;
});
