import { Button } from '@cian/ui-kit';
import * as React from 'react';

import { useOfferContext } from '../../../../context';
import { ChatOnboardingContainer } from '../ChatOnboardingContainer';

export const ConsultantChatButtonContainer: React.FC = () => {
  const { isConsultantMessageButtonAvailable, api, offer } = useOfferContext();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
    event => {
      event.stopPropagation();
      api.openConsultantChat(offer.id);
    },
    [api, offer.id],
  );

  if (!isConsultantMessageButtonAvailable) {
    return null;
  }

  return (
    <ChatOnboardingContainer type="consultantChatOnboarding">
      <Button data-mark="ChatButton" fullWidth size="XS" onClick={handleClick} theme="fill_secondary">
        Написать
      </Button>
    </ChatOnboardingContainer>
  );
};
