import { type IConfig } from '@cian/config/shared';
import * as React from 'react';

import { EPosition } from '../../../../shared/filters/components/filters/types';
import { IAbUseExperiment } from '../../../../shared/types/abUseExperiments';
import { TApplyFiltersSource } from '../../../../shared/types/applyFiltersSource';
import { TPromoSearchTypes } from '../../../../shared/types/promoSearchTypes';
import { IMakeRequest } from '../../../../shared/utils/request';
import { TJsonQuery } from '../../../JsonQuery';
import { EFeature } from '../types/features';
import { TFilter, TOpenedFiltersState } from '../types/filters';
import { TCurrentLocation } from '../types/location';
import { TModifier } from '../types/modifier';
import { ISpecialPromo } from '../types/specialPromo';

export interface IOnApplyClickParameters {
  source: TApplyFiltersSource;
}

export interface IContext {
  jsonQuery: TJsonQuery;
  jsonQueryCount: number | undefined;
  jsonQueryCountRefreshing: boolean;
  lastAppliedModifiers: [TModifier, ...TModifier[]] | null;
  openedFilters: TOpenedFiltersState;
  currentLocation: TCurrentLocation;
  filtersSectionPosition: EPosition;
  specialPromo?: ISpecialPromo;
  hasUnderground: boolean;
  hasDistricts: boolean;
  hasHighways: boolean;
  hasRegionalDistricts: boolean;
  isTooltipAvailable: boolean;
  features: EFeature[];
  appContext: {
    makeRequest: IMakeRequest;
    config: IConfig;
    experiments: IAbUseExperiment[];
  };
  onOpenFilter(filter: TFilter): void;
  onCloseFilter(filter: TFilter): void;
  onChange(...modifiers: [TModifier, ...TModifier[]]): void;
  onApply(): void;
  onClear?(): void;
  onApplyClick?(parameters: IOnApplyClickParameters): void;
  onGeoPopupOpen?(): void;
  onUndergroundPopupOpen?(): void;
  onDistrictPopupOpen?(): void;
  onHighwaysPopupOpen?(): void;
  onRegionalDistrictsOpen?(): void;
  isVillageMortgageFilterEnabled: boolean;
  isNewbuildingBlackFridayActive: boolean;
  isMixedFiltersRedesign: boolean;
  promoSearchTypes: TPromoSearchTypes;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const context = React.createContext<IContext>(null as any);
