import { EChatActionTypes } from 'shared/actions/chat';
import { IAppState, TActions } from 'shared/common/state';
import { ENewbuildingChatActionTypes } from 'shared/serp/state/newbuildingConsultant';
import { getChatOnboarding } from 'shared/utils/chatOnboarding';

// TODO: Удалить в CD-226143/CD-225150. Онбординги на месяц.
export function chatOnboardingReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/search/SEARCH_REQUEST_FINISHED':
      return {
        ...state,
        chatOnboarding: getChatOnboarding({
          config: state.config,
          data: action.data,
          experiments: state.abUseExperiments,
        }),
      };

    case EChatActionTypes.Open:
    case EChatActionTypes.OpenAfterAuth:
      if (state.chatOnboarding.offerChatOnboarding && action.payload.offer.newbuilding?.isFromBuilder) {
        return {
          ...state,
          chatOnboarding: {
            ...state.chatOnboarding,
            offerChatOnboarding: {
              ...state.chatOnboarding.offerChatOnboarding,
              wasChatOpened: true,
            },
          },
        };
      }

      return state;

    case ENewbuildingChatActionTypes.ToggleChat:
      if (action.payload && state.chatOnboarding.consultantChatOnboarding) {
        return {
          ...state,
          chatOnboarding: {
            ...state.chatOnboarding,
            consultantChatOnboarding: {
              ...state.chatOnboarding.consultantChatOnboarding,
              wasChatOpened: true,
            },
          },
        };
      }

      return state;
    default:
      return state;
  }
}
