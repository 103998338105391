import { TJsonQuery } from '../../../packages/JsonQuery';
import { ResponseError } from '../../../shared/errors';
import { IRecommendedVillages } from '../../../shared/types/recommendedVillages';
import {
  fetchSuggestVillagesTariffPro,
  TSuggestVillagesTariffProResponse,
} from '../../repositories/countryside-search/v1/suggest-villages-tariff-pro';
import { IApplicationContext } from '../../types/applicationContext';

interface IFetchRecommendedVillagesParameters {
  jsonQuery: TJsonQuery;
}

async function fetchRecommendedVillages(
  { httpApi }: IApplicationContext,
  parameters: IFetchRecommendedVillagesParameters,
): Promise<IRecommendedVillages> {
  const recommendedVillagesData: TSuggestVillagesTariffProResponse = await fetchSuggestVillagesTariffPro({
    httpApi,
    parameters,
  });

  if (recommendedVillagesData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchRecommendedVillages',
      message: recommendedVillagesData.response.message || '',
      details: {
        error: JSON.stringify(recommendedVillagesData.response.errors),
      },
    });
  }

  return recommendedVillagesData.response;
}

export async function fetchRecommendedVillagesSafe(
  context: IApplicationContext,
  parameters: IFetchRecommendedVillagesParameters,
): Promise<IRecommendedVillages | null> {
  try {
    return await fetchRecommendedVillages(context, parameters);
  } catch (ex) {
    const { logger } = context;

    logger.warning(ex, { degradation: 'Рекомендованные КП' });

    return null;
  }
}
