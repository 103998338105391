import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './ChatOnboardingContent.css';

export const ChatOnboardingContent: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles['content']}>
      <UIText2 color="text-inverted-default" whiteSpace="pre-line">
        {children}
      </UIText2>
    </div>
  );
};
