import { IOfferCardCreatorProps } from './helpers';
import {
  trackAddCommentClick,
  trackAddressClick,
  trackDocLinkClicked,
  trackFirstMessageClick,
  trackFirstMessageOpen,
  trackFirstMessageSent,
  trackHideIdenticalOffers,
  trackHidePrintButtonClick,
  trackIdenticalOfferClick,
  trackingCreate,
  trackingEnter,
  trackingShowPopup,
  trackJKLinkClicked,
  trackKpLinkClick,
  trackMapClosed,
  trackMapOpened,
  trackMultiOfferClick,
  trackPDFLinkClicked,
  trackPhotoSlideChanged,
  trackReportPopupOpened,
  trackShowAllIdenticalOffers,
  trackShowIdenticalOffers,
  trackSafeRentFillFormClick,
  trackAddOfferToComparison,
  trackDeleteOfferFromComparison,
  trackOfferCardShow,
} from './tracking';
import {
  IButtonsAPI,
  ICommentsAPI,
  ILoginMotivationPopupAPI,
  IOfferCardAPI,
  IUserInfoAPI,
} from '../../../../packages/api-models/search-card-fragment/v1/get-offer-card.props';
import { IJsonQuery } from '../../../json_query';
import { IOffer, TOfferCategory } from '../../../offer';

interface IButtonsAPIAddition {
  onHideOffer(): void;
  onHideOfferAfterAuth(): void;
}
export interface IPrepareOfferCardProps {
  onFavoriteChange(isFavorite: boolean): void;
  onPageJumped(label?: string): void;
  onPopupClose(): void;
  onPopupOpen(): void;
  onHideOffer(): void;
  onHideOfferAfterAuth(): void;
  trackPhoneOpened(isFromBrandBlock?: boolean): void;
  onHideButtonToggle?(isChecked: boolean): void;
  isHideButtonChecked?: boolean | undefined;
  mortgageBanner?: React.ReactNode | undefined;
  offer: IOffer;
  jsonQuery: IJsonQuery;
  position: number;
  addToComparison({ offerId }: { offerId: number; category: TOfferCategory | null }): void;
  addToComparisonAfterAuth({ offerId }: { offerId: number; category: TOfferCategory | null }): void;
  deleteFromComparison({ offerId }: { offerId: number }): void;
}

export function prepareOfferCardComponentsProps(
  creatorProps: IOfferCardCreatorProps,
  prepareProps: IPrepareOfferCardProps,
) {
  const {
    closePopup,
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    isBot,
    isPopupsOpened,
    onAgentRatingChanged,
    onComplaintSent,
    onOfferCommentsChanged,
    onPopupMoreOpen,
    onPopupReportOpen,
    onPopupMoreClose,
    onPopupReportClose,
    shownId,
    ratingBlockedAgents,
    user,
    favoritesLimitForUnauthUser,
    sendComplaintFeedback,
    statusChanger,
    complaintsFormStatus,
    excludedUtilitiesTermsRegions,
    featureToggle,
  } = creatorProps;

  const {
    mortgageBanner,
    onFavoriteChange,
    onPageJumped,
    onPopupClose,
    onPopupOpen,
    trackPhoneOpened,
    onHideOffer,
    onHideOfferAfterAuth,
    offer,
    jsonQuery,
    position,
    addToComparison,
    deleteFromComparison,
    addToComparisonAfterAuth,
  } = prepareProps;

  const buttons: IButtonsAPI & IButtonsAPIAddition = {
    onHideOffer,
    onHideOfferAfterAuth,
    onPopupMoreOpen,
    onPopupReportOpen,
    onPopupMoreClose,
    onPopupReportClose,
  };

  const comments: ICommentsAPI = {
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    isAuthenticated: user.isAuthenticated,
    onOfferCommentsChanged,
  };

  const loginMotivationPopup: ILoginMotivationPopupAPI = {
    closePopup,
    shownId,
  };

  const offerCard: IOfferCardAPI = {
    isPopupsOpened,
    onFavoriteChange,
    onPageJumped,
  };

  const userInfo: IUserInfoAPI = {
    onAgentRatingChanged,
    ratingBlockedAgents,
    userIsAuthenticated: user.isAuthenticated,
    onPopupClose,
    onPopupOpen,
  };

  const tracking = {
    trackHidePrintButtonClick,
    trackMultiOfferClick,
    trackJKLinkClicked,
    trackAddCommentClick,
    trackReportPopupOpened,
    trackAddressClick,
    trackingShowPopup,
    trackingEnter,
    trackingCreate,
    trackKpLinkClick,
    trackDocLinkClicked,
    trackMapClosed,
    trackMapOpened,
    trackPDFLinkClicked,
    trackPhoneOpened,
    trackFirstMessageClick: (trackingLabel: string) =>
      trackFirstMessageClick({ position, offer, jsonQuery }, trackingLabel),
    trackFirstMessageOpen: (trackingLabel: string) =>
      trackFirstMessageOpen({ position, offer, jsonQuery }, trackingLabel),
    trackFirstMessageSent,
    trackPhotoSlideChanged: () => trackPhotoSlideChanged(offer.id, position),
    trackShowIdenticalOffers: () => trackShowIdenticalOffers({ position, offer, jsonQuery }),
    trackHideIdenticalOffers: () => trackHideIdenticalOffers({ position, offer, jsonQuery }),
    trackOfferCardShow: () => {
      trackOfferCardShow({ position, offerId: offer.id, jsonQuery });
    },
    trackShowAllIdenticalOffers: () => trackShowAllIdenticalOffers({ position, offer, jsonQuery }),
    trackIdenticalOfferClick: (identicalOfferIndex: number) =>
      trackIdenticalOfferClick({ identicalOfferIndex, offer, jsonQuery }),
    trackSafeRentFillFormClick,
    trackAddOfferToComparison,
    trackDeleteOfferFromComparison,
  };

  const offerComparison = {
    addToComparison,
    deleteFromComparison,
    addToComparisonAfterAuth,
  };

  return {
    buttons,
    comments,
    favoritesLimitForUnauthUser,
    loginMotivationPopup,
    mortgageBanner,
    offerCard,
    pessimisation: {
      isBot,
    },
    reportPopup: {
      onComplaintSent,
    },
    userInfo,
    tracking,
    complaintsHelpers: {
      sendComplaintFeedback,
      statusChanger,
      complaintsFormStatus,
    },
    excludedUtilitiesTermsRegions,
    featureToggle,
    offerComparison,
  };
}
