import { motivationPopupAvailableSelector } from './motivationPopup';
import { isCommonFilterSelector, isNewbuildingSelector } from './newbuildingConsultant';
import { MOSCOW_AND_REGION } from '../../../packages/Filters/shared/constants/regions';
import { IAppState } from '../../common/state';
import { isChatOnCommonFilterAvailable } from '../../utils/experiments/isChatOnCommonFilterAvailable';
import { isInRegionSelector } from '../isInRegion';
import { selectChat as selectBuilderChat } from '../selectChat';
import { NB_CONSULTANT_MESSAGE_BUTTON } from 'shared/constants/experiments';
import { isAbUseExperimentGroup } from 'shared/utils/experiments/isAbUseExperimentGroup';

export function chatAvailableSelector(state: IAppState): boolean {
  const { config, isNewbuildingsOnly } = state;
  const accountName = config.get<string>('webim.accountName');
  const { isOpened: isBuilderChatOpened } = selectBuilderChat(state);

  return !!accountName && !isBuilderChatOpened && (isNewbuildingsOnly || chatAvailableOnCommonFilterSelector(state));
}

export function chatAvailableOnCommonFilterSelector(state: IAppState): boolean {
  const { config, abUseExperiments } = state;
  const accountName = config.get<string>('webim.accountName');

  return (
    !!accountName &&
    isCommonFilterSelector(state) &&
    isChatOnCommonFilterAvailable(abUseExperiments) &&
    isInRegionSelector(MOSCOW_AND_REGION)(state)
  );
}

export function chatOpenSelector(state: IAppState) {
  return state.newbuildingConsultant.isOpen;
}

export function isForceNeedOpenSelector(state: IAppState) {
  return (
    state.newbuildingConsultant.needForceOpen &&
    !motivationPopupAvailableSelector(state) &&
    !chatAvailableOnCommonFilterSelector(state)
  );
}

export function selectNeedRunJumpAnimation(state: IAppState) {
  return state.newbuildingConsultant.needRunJumpAnimation;
}

export function selectConsultantChatOfferId(state: IAppState) {
  return state.newbuildingConsultant.offerId ?? /* istanbul ignore next */ 0;
}

// TODO: Удалить эксперимент в CD-225150.
export function selectConsultantMessageButtonAvailable(state: IAppState): boolean {
  const { abUseExperiments, config, featureToggle } = state;

  const accountName = config.get<string>('webim.accountName');

  return (
    featureToggle.isConsultantMessageButtonEnabled &&
    Boolean(accountName) &&
    isNewbuildingSelector(state) &&
    isAbUseExperimentGroup(abUseExperiments, NB_CONSULTANT_MESSAGE_BUTTON, 'B')
  );
}
