// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TSearchNewbuildingLayoutsDesktopRequest,
  TSearchNewbuildingLayoutsDesktopModel,
  IMappers,
  TSearchNewbuildingLayoutsDesktopResponse,
  TSearchNewbuildingLayoutsDesktopResponse_1,
  TSearchNewbuildingLayoutsDesktopResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TSearchNewbuildingLayoutsDesktopModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-offers',
  pathApi: '/v1/search-newbuilding-layouts-desktop/',
  requestType: 'json',
  interserviceAuthEnabled: true,
} as TSearchNewbuildingLayoutsDesktopModel;

export function createSearchNewbuildingLayoutsDesktopModel(
  parameters: TSearchNewbuildingLayoutsDesktopRequest,
): TSearchNewbuildingLayoutsDesktopModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISearchNewbuildingLayoutsDesktopOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TSearchNewbuildingLayoutsDesktopRequest;
}

export async function fetchSearchNewbuildingLayoutsDesktop<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ISearchNewbuildingLayoutsDesktopOptions<TResponse200, TResponse400>): Promise<
  TSearchNewbuildingLayoutsDesktopResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createSearchNewbuildingLayoutsDesktopModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TSearchNewbuildingLayoutsDesktopResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TSearchNewbuildingLayoutsDesktopResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TSearchNewbuildingLayoutsDesktopResponse;
}
