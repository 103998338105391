import { getOrder } from './geo/getOrder';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { IJsonQueryUnderground } from '../../types/jsonQuery';
import { IUnderground } from '../../types/offer';

/**
 * Берем метро из гео офера geo.undergrounds
 * выводим первый элемент из списка, отсортировав в соответствии с geo.sortedGeoIds
 */
export const underground: TComputedPropMapper<IUnderground | undefined> = ({ offer }) => {
  const sortedGeoIds = offer.geo?.sortedGeoIds || [];
  const undergrounds = offer.geo?.undergrounds || [];

  const undergroundOrderMap: Map<number, number> = (
    sortedGeoIds.filter(item => item.type === 'underground') as IJsonQueryUnderground[]
  ).reduce((map, item, index) => {
    map.set(item.id, index);

    return map;
  }, new Map<number, number>());

  /**
   * Сортируем согласно порядку полученному из geo.sortedGeoId
   * станции, по каким то причинам, не попавшие в geo.sortedGeoId, идут низшим приоритетом
   */
  const sortedUndergrounds = undergrounds
    .concat()
    .sort(
      (a, b) =>
        getOrder<number, number>(undergroundOrderMap, a.id, Infinity) -
        getOrder<number, number>(undergroundOrderMap, b.id, Infinity),
    );

  return sortedUndergrounds[0];
};
