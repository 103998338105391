import { ResponseError } from '../../errors';
import {
  TGetProjectsSuggestResponse,
  fetchGetProjectsSuggest,
} from '../../repositories/countryside-constructors/v1/get-projects-suggest';
import { IApplicationContext } from '../../types/applicationContext';
import { ISuburbanProjectSuggest } from '../../types/suburbanBuildersProjects';

interface IFetchSuburbanBuildersProjectsParameters {
  regionId: number;
}

export async function fetchSuburbanBuildersProjects(
  { httpApi }: IApplicationContext,
  parameters: IFetchSuburbanBuildersProjectsParameters,
): Promise<ISuburbanProjectSuggest[]> {
  const suburbanBuildersProjectsData: TGetProjectsSuggestResponse = await fetchGetProjectsSuggest({
    httpApi,
    parameters,
  });

  if (suburbanBuildersProjectsData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchSuburbanBuildersProjects',
      message: suburbanBuildersProjectsData.response.message || '',
      details: {
        error: JSON.stringify(suburbanBuildersProjectsData.response.errors),
      },
    });
  }

  return suburbanBuildersProjectsData.response.projectsSuggest;
}

export async function fetchSuburbanBuildersProjectsSafe(
  context: IApplicationContext,
  parameters: IFetchSuburbanBuildersProjectsParameters,
): Promise<ISuburbanProjectSuggest[]> {
  try {
    return await fetchSuburbanBuildersProjects(context, parameters);
  } catch (ex) {
    const { logger } = context;

    logger.warning(ex, { degradation: 'Рекомендуемые проекты строителей' });

    return [];
  }
}
