import { ConfigurationError } from '@cian/peperrors/shared';
import * as React from 'react';

import { getAvailableFilters } from './helpers';
import { Filters } from '../../components/FiltersComponent';
import { FILTERS } from '../../constants/filters';
import { EFilterType, TFilter } from '../../types/filters';
import { useContext } from '../../utils/useContext';
import { useDealType } from '../../utils/useDealType';
import { useOfferType } from '../../utils/useOfferType';

interface IFiltersContainerProps {
  savedSearch?: React.ReactNode;
  applyButton?: React.ReactNode;
  tags?: React.ReactNode;
}

export const FiltersContainer: React.FC<IFiltersContainerProps> = ({ savedSearch, applyButton, tags }) => {
  const context = useContext();
  if (!context) {
    throw new ConfigurationError({
      domain: 'Filters',
      message: "Filters used outside of it's context",
    });
  }
  const {
    appContext: { config },
    currentLocation,
    features,
    jsonQuery,
    isVillageMortgageFilterEnabled,
  } = context;
  const dealType = useDealType();
  const offerType = useOfferType();
  const availableFilters = getAvailableFilters(dealType, offerType);
  const availableFiltersComponents = availableFilters.reduce(
    (acc, filterKey) => {
      const filter = FILTERS[filterKey];

      if (
        filter.availability &&
        !filter.availability({
          config,
          currentLocation,
          features,
          jsonQuery,
          isVillageMortgageFilterEnabled,
        })
      ) {
        return acc;
      }

      if (filter.type === EFilterType.Openable) {
        const isOpen = context.openedFilters[filterKey];
        const onOpen = () => context.onOpenFilter(filterKey);
        const onClose = () => context.onCloseFilter(filterKey);

        acc[filterKey] = <filter.component filterKey={filterKey} open={isOpen} onOpen={onOpen} onClose={onClose} />;
      } else {
        acc[filterKey] = <filter.component filterKey={filterKey} />;
      }

      return acc;
    },
    {} as { [key in TFilter]: React.ReactNode },
  );

  return <Filters {...availableFiltersComponents} savedSearch={savedSearch} applyButton={applyButton} tags={tags} />;
};
