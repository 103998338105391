import * as React from 'react';

import { ERoomType, NonEmptyArray } from '../../../../../JsonQuery';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IRoomTypeOption {
  label: string;
  value: ERoomType | null;
}

const ROOM_TYPE_OPTIONS: IRoomTypeOption[] = [
  { label: 'Неважно', value: null },
  { label: 'Студия', value: ERoomType.Studio },
  { label: '1', value: ERoomType.FlatOneRoom },
  { label: '2', value: ERoomType.FlatTwoRooms },
  { label: '3', value: ERoomType.FlatThreeRooms },
  { label: '4', value: ERoomType.FlatFourRooms },
  { label: '5', value: ERoomType.FlatFiveRooms },
  { label: '6+', value: ERoomType.FlatManyRooms },
  { label: 'Свободная планировка', value: ERoomType.FlatFreeLayout },
];

interface IRoomTypeProps {
  value: NonEmptyArray<ERoomType> | null;
  onChange(value: NonEmptyArray<ERoomType> | null): void;
}

export const RoomType: React.FC<IRoomTypeProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_roomType" label="Комнатность">
      <CheckboxButtonGroupUnselectable options={ROOM_TYPE_OPTIONS} value={value} onChange={onChange} />
    </Filter>
  );
};
