// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TAddOfferToComparisonRequest,
  TAddOfferToComparisonModel,
  IMappers,
  TAddOfferToComparisonResponse,
  TAddOfferToComparisonResponse_1,
  TAddOfferToComparisonResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TAddOfferToComparisonModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'offers-comparison',
  pathApi: '/v2/add-offer-to-comparison/',
  requestType: 'json',
  interserviceAuthEnabled: true,
} as TAddOfferToComparisonModel;

export function createAddOfferToComparisonModel(parameters: TAddOfferToComparisonRequest): TAddOfferToComparisonModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IAddOfferToComparisonOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TAddOfferToComparisonRequest;
}

export async function fetchAddOfferToComparison<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IAddOfferToComparisonOptions<TResponse200, TResponse400>): Promise<
  TAddOfferToComparisonResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createAddOfferToComparisonModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TAddOfferToComparisonResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TAddOfferToComparisonResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TAddOfferToComparisonResponse;
}
