import { pathOr } from 'ramda';

import { IJsonQuery } from '../../repositories/common/json_query';
import { IOffersData } from '../../types/offersData';
import { INewbuildingLayoutsForDesktop } from '../../types/residentialComplexLayouts/layouts/residentialComplexLayouts';
import { ISearchNewbuildingLayoutsForDesktopResponseSchema } from 'shared/repositories/search-offers/entities/entities/SearchNewbuildingLayoutsForDesktopResponseSchema';

export const prepareNewbuildingLayouts = (
  response: ISearchNewbuildingLayoutsForDesktopResponseSchema,
): INewbuildingLayoutsForDesktop => {
  return {
    maxAuctionBet: pathOr(null, ['layouts', 'maxAuctionBet'], response),
    maxAuctionService: pathOr(null, ['layouts', 'maxAuctionService'], response),
    qsToUris: pathOr(null, ['layouts', 'qsToUris'], response),
    quickLinks: pathOr(null, ['layouts', 'quickLinks'], response),
    ymEvents: pathOr(null, ['layouts', 'ymEvents'], response),
    avgPriceInformer: pathOr(null, ['layouts', 'avgPriceInformer'], response),
    breadcrumbs: pathOr([], ['layouts', 'breadcrumbs'], response),
    fullUrl: pathOr('/', ['layouts', 'fullUrl'], response),
    jsonQuery: response?.layouts?.jsonQuery as IJsonQuery,
    layouts: pathOr([], ['layouts', 'layouts'], response),
    offerCount: pathOr(0, ['layouts', 'offerCount'], response),
    puids: pathOr(null, ['layouts', 'puids'], response),
    queryString: pathOr('', ['layouts', 'queryString'], response),
    searchRequestId: pathOr('', ['layouts', 'searchRequestId'], response),
    seoData: pathOr({}, ['layouts', 'seoData'], response) as IOffersData['seoData'],
    seoLinks: pathOr(null, ['layouts', 'seoLinks'], response),
    newbuildingInformation: pathOr(null, ['newbuilding'], response),
  };
};
