import { prepareValue } from './prepareValue';
import { NonEmptyArray } from '../../../../../../JsonQuery';
import { ESaleType } from 'shared/repositories/common/json_query';

interface IMergeStatesProps {
  newbuildingState: NonEmptyArray<ESaleType> | null;
  otherState: NonEmptyArray<ESaleType> | null;
  allowedNewbuildingValues: NonEmptyArray<ESaleType>;
  allowedOtherValues: NonEmptyArray<ESaleType>;
}

export const mergeStates = ({
  newbuildingState,
  otherState,
  allowedNewbuildingValues,
  allowedOtherValues,
}: IMergeStatesProps): NonEmptyArray<ESaleType> | null => {
  const newbuildings: NonEmptyArray<ESaleType> | null = prepareValue({
    value: newbuildingState,
    allowedValues: allowedNewbuildingValues,
  });
  const others: NonEmptyArray<ESaleType> | null = prepareValue({
    value: otherState,
    allowedValues: allowedOtherValues,
  });
  if (newbuildings && others) {
    return [...newbuildings, ...others];
  }
  if (!newbuildings && others) {
    return others;
  }
  if (newbuildings && !others) {
    return newbuildings;
  }
  if (!newbuildings && !others) {
    return null;
  }

  return null;
};
