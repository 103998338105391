/* eslint-disable max-lines */
import { type IConfig } from '@cian/config/shared';
import { parseCookies } from '@cian/utils';
import { call, put, select, take } from 'redux-saga/effects';

import {
  disableVillageMortgageFilter,
  enableVillageMortgageFilter,
  getVillageMortgageMeta,
} from './village_mortgage_filter';
import {
  JsonQuery,
  getTermValue,
  getTermsValue,
  isSuburban,
  offerTypeFromJsonQuery,
} from '../../../packages/JsonQuery';
import { EObjectType } from '../../../packages/api-models/common/json_query';
import { EJournalActionTypes } from '../../actions/journal';
import { newbuildingLayoutsOff } from '../../actions/newbuildingLayouts';
import { Dispatch, IAppState, TActions } from '../../common/state';
import { IJsonQuery, IJsonQueryDeveloper } from '../../json_query';
import { isNewbuildingSelector, motivationPopupOpenSelector } from '../../selectors/newbuildingConsultant';
import {
  isListingForResidentialComplexSelector,
  residentialComplexLayoutsLastMetaCountSelector,
  residentialComplexLayoutsOnSelector,
} from '../../selectors/newbuildingLayouts';
import { defaultHideOfferState } from '../../serp/state/hide_offer';
import { newbuildingOpenMotivationPopup } from '../../serp/state/newbuildingConsultant';
import { fetchDeveloperInfoSafe } from '../../services/fetchDeveloperInfo';
import { fetchNewobjectsBuilderIfNeeded } from '../../services/fetchNewobjectsBuilder';
import { fetchOffers, prepareJsonQuery } from '../../services/fetchOffers';
import { fetchQuickLinksSafe } from '../../services/fetchQuickLinks';
import { fetchRecommendedVillagesSafe } from '../../services/fetchRecommendedVillages';
import { fetchSimilarNewobjectsIfNeeded } from '../../services/fetchSimilarNewobjects';
import { fetchSuburbanBuildersProjectsSafe } from '../../services/fetchSuburbanBuildersProjects';
import { fetchVillageInfoSafe } from '../../services/fetchVillageInfo';
import { IApplicationContext } from '../../types/applicationContext';
import { IDeveloperInfo } from '../../types/developerInfo';
import { IOffersData } from '../../types/offersData';
import { IQuickLinks } from '../../types/quickLinks';
import { IRecommendedVillages } from '../../types/recommendedVillages';
import { IResidentialComplexInfo } from '../../types/residentialComplexInfo';
import { INewbuildingLayout } from '../../types/residentialComplexLayouts/layouts/newbuildingLayout';
import { ISimilarNewobject, ISimilarNewobjectFromDeveloper } from '../../types/similarNewobjects';
import { ISuburbanProjectSuggest } from '../../types/suburbanBuildersProjects';
import { IVillageInfo } from '../../types/villageInfo';
import { cutDescriptionFromOffers } from '../../utils';
import { displayConsultantPhone } from '../../utils/displayNewbuildingConsultantPhone';
import { triggerHeaderCategoryChanged, triggerHeaderRegionChanged } from '../../utils/events';
import { getRegionId } from '../../utils/geo';
import { getNewbuildingBannerRandomBoolean } from '../../utils/getNewbuildingBannerRandomBoolean';
import { saveHistorySearch } from '../../utils/history_search';
import { isHybridSearchRedirectNeeded } from '../../utils/isHybridSearchRedirectNeeded';
import { isSubdomainRedirectNeeded } from '../../utils/isSubdomainRedirectNeeded';
import { offersDecorator } from '../../utils/offersDecorator';
import { isDeveloperPage } from '../helpers';
import { trackPageView } from '../tracking';
import { IKpOffers, ISerpDataQueristringToUri } from 'shared/offer';
import { INewbuildingSimilarOffersBlockSchema } from 'shared/repositories/search-offers.legacy/v2/types/NewbuildingSimilarOffersBlockSchema';
import { ISearchMeta } from 'shared/types/searchMeta';

export interface ISearchRequestStartRequestedAction {
  type: 'filters/search/SEARCH_REQUEST_START_REQUESTED';
}

export interface ISearchRequestFetchingAction {
  type: 'filters/search/SEARCH_REQUEST_FETCHING';
}

export interface ISearchSaveTooltipHidderAction {
  type: 'filters/save_tooltip/TOOLTIP_HIDDEN';
}

export interface ISearchRequestFinishedAction {
  type: 'filters/search/SEARCH_REQUEST_FINISHED';
  data: IOffersData;
  newbuildingInfo: IResidentialComplexInfo | null;
  developerInfo: IDeveloperInfo | null;
  villageInfo: IVillageInfo | null;
  similarNewobjects: ISimilarNewobject[] | null;
  similarNewobjectsFromDeveloper: ISimilarNewobjectFromDeveloper[] | null;
  quickLinks: IQuickLinks | null;
  recommendedVillages: IRecommendedVillages | null;
  suburbanBuildersProjects: ISuburbanProjectSuggest[];
  newbuildingLayouts: INewbuildingLayout[] | null;
}

export const FILTERS_SEARCH_SEARCH_REQUEST_START_REQUESTED = 'filters/search/SEARCH_REQUEST_START_REQUESTED';
export const FILTERS_SEARCH_SEARCH_REQUEST_FETCHING = 'filters/search/SEARCH_REQUEST_FETCHING';
export const FILTERS_SEARCH_SEARCH_REQUEST_FINISHED = 'filters/search/SEARCH_REQUEST_FINISHED';

export const FILTERS_SEARCH_LAYOUT_SEARCH_REQUEST_START_REQUESTED =
  'filters/layoutSearch/SEARCH_REQUEST_START_REQUESTED';

export function makeSearch() {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const layoutToggle = residentialComplexLayoutsOnSelector(state);
    const layoutCount = residentialComplexLayoutsLastMetaCountSelector(state);
    const isNewbuilding = isListingForResidentialComplexSelector(state);

    if ((layoutToggle && !layoutCount) || !isNewbuilding) {
      dispatch(newbuildingLayoutsOff());
    }

    if (layoutToggle && layoutCount && isNewbuilding) {
      dispatch({
        type: FILTERS_SEARCH_LAYOUT_SEARCH_REQUEST_START_REQUESTED,
      });
    } else {
      dispatch({
        type: FILTERS_SEARCH_SEARCH_REQUEST_START_REQUESTED,
      });
    }
  };
}

export function setLoading(): ISearchRequestFetchingAction {
  return {
    type: FILTERS_SEARCH_SEARCH_REQUEST_FETCHING,
  };
}

let prevRegionsValue: number[] = [];

/* istanbul ignore next */
export function* searchSaga() {
  while (true) {
    let state: IAppState = yield select();

    const prevJsonQuery = state.filters.jsonQuery;

    yield take(FILTERS_SEARCH_SEARCH_REQUEST_START_REQUESTED);

    state = yield select();

    if (state.filters.jsonQueryCountRefresing) {
      // Block search while meta is loading
      yield take(['filters/meta/META_REFRESH_COMPLETE', 'filters/meta/META_REFRESH_FAILED']);
    }

    state = yield select();

    const {
      httpApi,
      filters: { jsonQuery, jsonQueryFullUrl, currentLocation },
      config,
      logger,
      currentSubdomain,
      journalHighlights,
      abUseExperiments,
    } = state;

    if (
      jsonQueryFullUrl &&
      (isRedirectNeeded(config, jsonQuery, jsonQueryFullUrl) ||
        isSubdomainRedirectNeeded(jsonQueryFullUrl) ||
        isHybridSearchRedirectNeeded({
          jsonQuery,
          config,
          experiments: abUseExperiments,
          location: currentLocation,
        }))
    ) {
      window.location.assign(jsonQueryFullUrl);

      return;
    }

    const isAdditionalTop3Disabled = config.get('serp.isAdditionalTop3Enabled') !== 'true';

    const isRegionChanged = (value: number[]) => {
      let isChanged = false;

      if (prevRegionsValue.length === value.length) {
        const prev = prevRegionsValue.sort();
        const next = value.sort();

        prev.forEach((n, i) => {
          if (n !== next[i]) {
            isChanged = true;
          }
        });
      } else {
        isChanged = true;
      }

      prevRegionsValue = value;

      return isChanged;
    };

    const regionChanged = jsonQuery.region && isRegionChanged(jsonQuery.region.value);

    if (window.__reloadHeader__ && jsonQuery.region && isRegionChanged(jsonQuery.region.value)) {
      window.__reloadHeader__();
    }

    const data: IOffersData = yield call(
      fetchOffers,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { httpApi } as any,
      {
        jsonQuery: prepareJsonQuery({ jsonQuery, location: currentLocation }),
      },
    );

    /**
     * Сохранение текущего поиска для истории поисков
     */
    saveHistorySearch(data.seoData.h1, data.fullUrl, data.jsonQuery);

    /**
     * Если текущий путь не чпу, сбрасываем имеющиеся статьи журнала
     */
    if (data.fullUrl.includes('/cat.php') && journalHighlights.length > 0) {
      yield put({
        type: EJournalActionTypes.Reset,
      });
    }

    const suggestions = data.suggestOffersSerializedList;

    data.offersSerialized = cutDescriptionFromOffers(data.offersSerialized, state.userAgent);

    if (suggestions) {
      data.suggestOffersSerializedList = cutDescriptionFromOffers(suggestions, state.userAgent);
    }

    // Обработка объявлений
    offersDecorator(data.offersSerialized, isAdditionalTop3Disabled);

    let villageInfo: IVillageInfo | null = null;
    if (data.jsonQuery.kp_id) {
      const oldVillageInfoId = state.results.villageInfo && state.results.villageInfo.id;
      villageInfo =
        oldVillageInfoId === data.jsonQuery.kp_id.value
          ? state.results.villageInfo
          : yield call(fetchVillageInfoSafe, { httpApi, logger } as IApplicationContext, {
              id: data.jsonQuery.kp_id.value,
            });
    }

    let developerInfo: IDeveloperInfo | null = null;
    if (data.jsonQuery.geo && isDeveloperPage(data.jsonQuery)) {
      developerInfo = yield call(fetchDeveloperInfoSafe, { httpApi, logger } as IApplicationContext, {
        subdomain: currentSubdomain,
        id: (data.jsonQuery.geo.value[0] as IJsonQueryDeveloper).id,
      });
    }

    const similarNewobjects: ISimilarNewobject | null = yield call(
      fetchSimilarNewobjectsIfNeeded,
      { httpApi, logger } as IApplicationContext,
      data,
    );
    const similarNewobjectsFromDeveloper: ISimilarNewobjectFromDeveloper | null = yield call(
      fetchNewobjectsBuilderIfNeeded,
      { httpApi, logger } as IApplicationContext,
      data,
    );
    const quickLinks: IQuickLinks | null = yield call(fetchQuickLinksSafe, { httpApi, logger } as IApplicationContext, {
      subdomain: currentSubdomain,
      jsonQuery: data.jsonQuery,
    });

    const isVillageMortgageFilterEnabled = config.get<boolean>('Countryside.VillageMortgage.Enabled');

    const isSuburbanSearch = isSuburban(offerTypeFromJsonQuery(data.jsonQuery));

    const offerTypeChanged = offerTypeFromJsonQuery(prevJsonQuery) !== offerTypeFromJsonQuery(jsonQuery);

    const canUpdateVillageMortgageFilter = regionChanged || offerTypeChanged;

    const isVillageMortgageAllowed = getTermValue('village_mortgage_allowed')(data.jsonQuery);

    if (
      isVillageMortgageFilterEnabled &&
      isSuburbanSearch &&
      canUpdateVillageMortgageFilter &&
      !isVillageMortgageAllowed
    ) {
      const offersVillageMortgageMeta: ISearchMeta = yield call(getVillageMortgageMeta);

      const action = offersVillageMortgageMeta?.count ? enableVillageMortgageFilter : disableVillageMortgageFilter;

      yield put(action());
    }

    state = yield select();

    document.title = data.seoData.title || document.title;
    window.history.pushState({}, document.title, state.filters.jsonQueryUrl);

    const regionId = getRegionId(currentLocation);

    const recommendedVillages: IRecommendedVillages | null = isSuburbanSearch
      ? yield call(fetchRecommendedVillagesSafe, { httpApi, logger } as IApplicationContext, {
          jsonQuery: new JsonQuery(data.jsonQuery).setRegion([Math.abs(regionId)]).toJSON(),
        })
      : null;

    const objectType = getTermsValue('object_type')(data.jsonQuery);

    const suburbanBuildersProjects: ISuburbanProjectSuggest[] = objectType?.includes(EObjectType.Land)
      ? yield call(fetchSuburbanBuildersProjectsSafe, { httpApi, logger } as IApplicationContext, {
          regionId,
        })
      : [];

    yield put({
      type: FILTERS_SEARCH_SEARCH_REQUEST_FINISHED,
      data,
      newbuildingInfo: null,
      developerInfo,
      villageInfo,
      similarNewobjects,
      similarNewobjectsFromDeveloper,
      quickLinks,
      recommendedVillages,
      suburbanBuildersProjects,
      newbuildingLayouts: null,
    });

    state = yield select();

    if (motivationPopupOpenSelector(state) && !isNewbuildingSelector(state)) {
      yield put(newbuildingOpenMotivationPopup(false));
    }

    const consultantPhone = config.get<string>('newbuildingConsultant.phone.moscowAndRegion');
    const consultantPhoneEnabledRegions = config.get<number[]>('newbuildingConsultant.phone.enabledRegions') || [];
    displayConsultantPhone(state, consultantPhoneEnabledRegions, consultantPhone);

    trackPageView(
      {
        filters: state.filters,
        results: state.results,
        breadcrumbs: data.breadcrumbs,
        user: state.user,
        profileSessionKey: state.profileSessionKey,
        mlRankingGuid: data.mlRankingGuid || null,
        mlRankingModelVersion: data.mlRankingModelVersion || null,
        searchRequestId: data.searchRequestId,
        extensions: data.extensionTypes || undefined,
        location: state.filters.currentLocation,
      },
      config,
    );
  }
}

export function searchReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case FILTERS_SEARCH_SEARCH_REQUEST_START_REQUESTED:
    case FILTERS_SEARCH_SEARCH_REQUEST_FETCHING:
      return {
        ...state,
        isFetching: true,
      };

    case FILTERS_SEARCH_SEARCH_REQUEST_FINISHED:
      triggerHeaderCategoryChanged(action.data.jsonQuery);
      triggerHeaderRegionChanged(state.filters.currentLocation);

      return {
        ...state,
        results: {
          ...state.results,
          avgPriceInformer: action.data.avgPriceInformer || null,
          queryString: action.data.queryString,
          quickLinks: action.quickLinks,
          jsonQuery: action.data.jsonQuery,
          aggregatedOffers: action.data.aggregatedCount,
          extendedOffersCount: action.data.extendedOffersCount,
          developerInfo: action.developerInfo,
          fastLinks: action.data.topHitsLinks || null,
          fullUrl: action.data.fullUrl,
          newbuildingInfo: action.newbuildingInfo,
          offers: action.data.offersSerialized,
          seo: action.data.seoData,
          seoLinks: action.data.seoLinks,
          totalOffers: action.data.offerCount,
          suggestions: action.data.suggestOffersSerializedList || null,
          suggestionsQuery: action.data.suggestionsQuery,
          qsToUris: (action.data.qsToUris as ISerpDataQueristringToUri) || null,
          villageInfo: action.villageInfo,
          kp: action.data.kp as IKpOffers,
          extensionTypes: action.data.extensionTypes || [],
          puids: action.data.puids,
          ymEvents: action.data.ymEvents,
        },
        similarNewobjects: action.similarNewobjects,
        breadcrumbs: action.data.breadcrumbs,
        isFetching: false,
        hideOffer: defaultHideOfferState,
        mlRankingGuid: action.data.mlRankingGuid || null,
        mlRankingModelVersion: action.data.mlRankingModelVersion || null,
        newbuildingIdsForBuildersPromoSlider: action.data.newbuildingIdsForBuildersPromoSlider,
        newbuildingPromoBuilderOffers: action.data.specialPromoBuilderOffers || null,
        isNewbuildingsOnly: (action.data.jsonQuery.building_status || { value: undefined }).value === 2,
        collections: action.data.collections,
        newbuildingSimilarOffersBlock: action.data
          .newbuildingSimilarOffersBlock as INewbuildingSimilarOffersBlockSchema,
        isNewbuildingBannerRandomRotationEnabled: getNewbuildingBannerRandomBoolean(
          state.config,
          parseCookies(document.cookie),
        ),
        newbuildingInformation: action.data.newbuilding || null,
        recommendedVillages: action.recommendedVillages,
        suburbanBuildersProjects: action.suburbanBuildersProjects,
        newbuildingLayouts: action.newbuildingLayouts || null,
        newbuildingFlatSaleQueryString: action.data.newbuildingFlatSaleQueryString,
      };
    default:
      return state;
  }
}

export function isRedirectNeeded(config: IConfig, jsonQuery: IJsonQuery, fullUrl?: string): boolean {
  if (config.get('redirectToOldFilters') !== 'enabled') {
    return false;
  }

  if (!fullUrl) {
    return false;
  }

  let queryHasMapObjects = false;

  if (jsonQuery.geo && jsonQuery.geo.value) {
    queryHasMapObjects = jsonQuery.geo.value.some(geoObject => {
      return geoObject.type === 'polygon' || geoObject.type === 'distance';
    });
  }

  if (!queryHasMapObjects) {
    const parser = document.createElement('a');
    parser.href = fullUrl;

    return parser.hostname !== window.location.hostname;
  }

  return false;
}
