import { ResponseError } from '../../errors';
import { prepareSimilarNewobjects } from '../../mappers/prepareSimilarNewobjects';
import {
  fetchCianApiSiteV1GetSimilarNewobjects as fetchGetSimilarNewobjects,
  TCianApiSiteV1GetSimilarNewobjectsResponse,
} from '../../repositories/monolith-python/cian-api/site/v1/get-similar-newobjects';
import { IApplicationContext } from '../../types/applicationContext';
import { ISimilarNewobject } from '../../types/similarNewobjects';

interface IParameters {
  newobjectId: number;
}

export async function fetchSimilarNewobjects(
  context: IApplicationContext,
  parameters: IParameters,
): Promise<ISimilarNewobject[] | null> {
  const { httpApi, logger } = context;

  try {
    const similarNewobjectsResponse: TCianApiSiteV1GetSimilarNewobjectsResponse = await fetchGetSimilarNewobjects({
      httpApi,
      parameters,
    });

    if (similarNewobjectsResponse.statusCode !== 200) {
      throw new ResponseError({
        domain: 'fetchSimilarNewobjects',
        message: similarNewobjectsResponse.response.data?.message || '',
        details: { errors: JSON.stringify(similarNewobjectsResponse.response.data?.errors) },
      });
    }

    return prepareSimilarNewobjects(similarNewobjectsResponse.response);
  } catch (ex) {
    logger.warning(ex, {
      degradation: 'Similar newobjects',
    });

    return null;
  }
}
