import { useState, useEffect } from 'react';

import { IIntersectionOption } from './useIntersectionOptions';
import { TCardInViewContext } from '../context/context';

interface IParams {
  containerRef: React.RefObject<HTMLDivElement>;
  intersectionOptions: IIntersectionOption[];
}

/**
 * Создает экземпляры IntersectionObserver для каждого набора опций из intersectionOptions
 * и подписывает каждый observer наблюдать за попаданием элемента containerRef во вьюпорт
 *
 * @returns Объект ключ-значение, где ключ - id фичи из intersectionOptions, значение - флаг попадания во вьюпорт
 */
export const useInViewMap = ({ containerRef, intersectionOptions }: IParams) => {
  const [inViewMap, setInViewMap] = useState<TCardInViewContext>({} as TCardInViewContext);

  useEffect(() => {
    const container = containerRef.current;

    /* istanbul ignore next */
    if (!container) {
      return () => {};
    }

    const observers = intersectionOptions.map(({ id, options }) => {
      const observer = new IntersectionObserver(([entry]) => {
        setInViewMap(prevState => ({ ...prevState, [id]: entry.isIntersecting }));
      }, options);

      observer.observe(container);

      return observer;
    });

    return () => {
      observers.forEach(observer => observer.unobserve(container));
    };
  }, [containerRef, intersectionOptions]);

  return inViewMap;
};
