import { Checkbox, SelectDesktop } from '@cian/ui-kit';
import * as React from 'react';

import { EPublishPeriod } from '../../../types/jsonQuery';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';

interface IPublicationDateProps {
  publishPeriod: EPublishPeriod | null;
  hasPhoto: boolean | null;
  hasVideo: boolean | null;
  withLayout: boolean | null;
  showWithLayoutFilter: boolean;
  onPublishPeriodChange(period: EPublishPeriod | null): void;
  onHasPhotoChange(withPhoto: boolean | null): void;
  onHasVideoChange(withVideo: boolean | null): void;
  onWithLayoutChange(withLayout: boolean | null): void;
}

interface IPublicationDate {
  label: string;
  value: EPublishPeriod;
}

const PUBLICATION_DATE_OPTIONS: IPublicationDate[] = [
  { label: 'Неважно', value: EPublishPeriod.Any },
  { label: 'За месяц', value: EPublishPeriod.Month },
  { label: 'За неделю', value: EPublishPeriod.Week },
  { label: 'За сегодня', value: EPublishPeriod.Today },
  { label: 'За час', value: EPublishPeriod.Hour },
];

export const PublicationDate: React.FC<IPublicationDateProps> = ({
  publishPeriod,
  hasPhoto,
  hasVideo,
  withLayout,
  showWithLayoutFilter,
  onWithLayoutChange,
  onPublishPeriodChange,
  onHasPhotoChange,
  onHasVideoChange,
}) => {
  return (
    <Filter label="Дата публикации" id="advancedFilter_publicationDate">
      <InlineFilterGroup>
        <InlineFilter>
          <SelectDesktop
            options={PUBLICATION_DATE_OPTIONS}
            value={publishPeriod !== null ? publishPeriod : EPublishPeriod.Any}
            onChange={(value: EPublishPeriod) => onPublishPeriodChange(value === EPublishPeriod.Any ? null : value)}
          />
        </InlineFilter>
        <InlineFilter>
          <Checkbox
            label="Фото"
            checked={!!hasPhoto}
            onChange={event => onHasPhotoChange(event.target.checked || null)}
          />
        </InlineFilter>
        <InlineFilter>
          <Checkbox
            label="Видео"
            checked={!!hasVideo}
            onChange={event => onHasVideoChange(event.target.checked || null)}
          />
        </InlineFilter>
        {showWithLayoutFilter && (
          <InlineFilter>
            <Checkbox
              label="Схема планировки"
              checked={!!withLayout}
              onChange={event => onWithLayoutChange(event.target.checked || null)}
            />
          </InlineFilter>
        )}
      </InlineFilterGroup>
    </Filter>
  );
};
