// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TGetNewbuildingLayoutsCountRequest,
  TGetNewbuildingLayoutsCountModel,
  IMappers,
  TGetNewbuildingLayoutsCountResponse,
  TGetNewbuildingLayoutsCountResponse_1,
  TGetNewbuildingLayoutsCountResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TGetNewbuildingLayoutsCountModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-offers-index',
  pathApi: '/v1/get-newbuilding-layouts-count/',
  requestType: 'json',
  interserviceAuthEnabled: true,
} as TGetNewbuildingLayoutsCountModel;

export function createGetNewbuildingLayoutsCountModel(
  parameters: TGetNewbuildingLayoutsCountRequest,
): TGetNewbuildingLayoutsCountModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewbuildingLayoutsCountOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TGetNewbuildingLayoutsCountRequest;
}

export async function fetchGetNewbuildingLayoutsCount<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetNewbuildingLayoutsCountOptions<TResponse200, TResponse400>): Promise<
  TGetNewbuildingLayoutsCountResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetNewbuildingLayoutsCountModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TGetNewbuildingLayoutsCountResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TGetNewbuildingLayoutsCountResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetNewbuildingLayoutsCountResponse;
}
