import * as React from 'react';

import { getCompletionYearOptions } from './helpers';
import { NonEmptyArray, ECompletionYearType } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../types/jsonQuery';
import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { CheckboxButtonGroupUnselectable } from '../../CheckboxButtonGroupUnselectable';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { FilterSection } from '../common/InlineFilterSection';
import { RangeInput } from '../common/RangeInput';

interface IConstructionYearProps {
  constructionYear: IJsonQueryRangeValue | null;
  yearOffset: number;
  newbuildingCompletionYear: NonEmptyArray<ECompletionYearType> | null;
  mixed: boolean;
  onConstructionYearMinChange(gte: number | null): void;
  onConstructionYearMaxChange(lte: number | null): void;
  onCompletionYearChange: (completionYear: NonEmptyArray<ECompletionYearType> | null) => void;
}

export const ConstructionYear: React.FC<IConstructionYearProps> = ({
  constructionYear,
  yearOffset,
  newbuildingCompletionYear,
  mixed,
  onConstructionYearMinChange,
  onConstructionYearMaxChange,
  onCompletionYearChange,
}) => {
  const { gte: constructionYearMin = null, lte: constructionYearMax = null } = constructionYear || {};

  const completionYearOptions = getCompletionYearOptions(yearOffset);

  const handleConstructionYearMinChange = useDebouncedCallback(
    (gte: number | null) => {
      onConstructionYearMinChange(gte);
    },
    300,
    [onConstructionYearMinChange],
  );

  const handleConstructionYearMaxChange = useDebouncedCallback(
    (lte: number | null) => {
      onConstructionYearMaxChange(lte);
    },
    300,
    [onConstructionYearMaxChange],
  );

  return (
    <Filter id="advancedFilter_constructionYear" label="Год постройки">
      {mixed ? (
        <>
          <InlineFilterGroup>
            <FilterSection title="На вторичке">
              <InlineFilter>
                <RangeInput
                  containerWidth={100}
                  min={1336}
                  max={2038}
                  thousandsSeparator=""
                  valueMin={constructionYearMin}
                  valueMax={constructionYearMax}
                  onChangeMin={handleConstructionYearMinChange}
                  onChangeMax={handleConstructionYearMaxChange}
                />
              </InlineFilter>
            </FilterSection>
          </InlineFilterGroup>
          <InlineFilterGroup>
            <FilterSection title="В новостройках">
              <InlineFilter>
                <CheckboxButtonGroupUnselectable
                  options={completionYearOptions}
                  value={newbuildingCompletionYear}
                  onChange={onCompletionYearChange}
                />
              </InlineFilter>
            </FilterSection>
          </InlineFilterGroup>
        </>
      ) : (
        <InlineFilterGroup>
          <InlineFilter>
            <RangeInput
              min={1336}
              max={2038}
              thousandsSeparator=""
              valueMin={constructionYearMin}
              valueMax={constructionYearMax}
              onChangeMin={handleConstructionYearMinChange}
              onChangeMax={handleConstructionYearMaxChange}
            />
          </InlineFilter>
        </InlineFilterGroup>
      )}
    </Filter>
  );
};
