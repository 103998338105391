import * as React from 'react';

import { ERoomType, getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { RoomType } from '../../../components/advancedFilters/RoomType';
import { useContext } from '../../../utils/useContext';

export const RoomTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();

  const roomType = getTermsValue('room')(jsonQuery);

  const handleChange = React.useCallback(
    (roomType: NonEmptyArray<ERoomType> | null) => {
      onChange({ action: 'setRoomTypes', arguments: [roomType] });
    },
    [onChange],
  );

  return <RoomType value={roomType} onChange={handleChange} />;
};
