import { ResponseError } from '../../errors';
import { IJsonQuery } from '../../json_query';
import {
  fetchCianApiSiteV1QueryToGeoTags as fetchQueryToGeoTags,
  TCianApiSiteV1QueryToGeoTagsResponse,
} from '../../repositories/monolith-python/cian-api/site/v1/query-to-geo-tags';
import { IApplicationContext } from '../../types/applicationContext';
import { IGeoTags } from '../../types/geoTags';

interface IFetchGeoTagsParameters {
  subdomain: string;
  jsonQuery: IJsonQuery;
}

async function fetchGeoTags(
  { httpApi }: IApplicationContext,
  { subdomain, jsonQuery }: IFetchGeoTagsParameters,
): Promise<IGeoTags> {
  const geoTagsData: TCianApiSiteV1QueryToGeoTagsResponse = await fetchQueryToGeoTags({
    httpApi,
    parameters: { jsonQuery },
    config: {
      subdomain,
    },
  });

  if (geoTagsData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchGeoTags',
      message: geoTagsData.response.data?.message || '',
      details: {
        error: JSON.stringify(geoTagsData.response.data?.errors),
      },
    });
  }

  return geoTagsData.response.data.geoTags as IGeoTags;
}

export async function fetchGeoTagsSafe(
  context: IApplicationContext,
  parameters: IFetchGeoTagsParameters,
): Promise<IGeoTags | null> {
  try {
    return await fetchGeoTags(context, parameters);
  } catch (ex) {
    const { logger } = context;

    logger.warning(ex, { degradation: 'Гео теги' });

    return null;
  }
}
