import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ApplyFiltersButtonContainer } from './ApplyFiltersButton';
import { SaveSearchButtonContainer } from './SaveSearchButtonContainer';
import {
  trackAdvancedFiltersSearchClick,
  trackClearClick,
  trackSearchButtonClick,
  trackShowAdvancedFiltersClick,
  trackTooltipSearchClick,
} from './tracking';
import { trackApplyDatesModal } from './tracking/trackApplyDatesModal';
import {
  areTagsAvailableSelector,
  hasDistrictsSelector,
  hasHighwaysSelector,
  hasMetroSelector,
  hasRegionalDistrictsSelector,
} from './utils/selectors';
import { useFiltersState } from './utils/useFiltersState';
import { useTooltipDisabled } from './utils/useTooltipDisabled';
import {
  EFeature,
  Filters,
  IOnApplyClickParameters,
  IOnChangeParameters,
  Provider,
  TModifier,
} from '../../../packages/Filters';
import { selectIsVillageMortgageFilterEnabled } from '../../../packages/Filters/shared/selectors/selectIsVillageMortgageFilterEnabled';
import { TFilter } from '../../../packages/Filters/shared/types/filters';
import { getDateRangeValue } from '../../../packages/JsonQuery';
import { Dispatch, IAppState } from '../../common/state';
import { FiltersSection } from '../../components/FiltersSection';
import { trackOpenDistrictsModalEvent } from '../../filters/components/filters/mainFilters/tracking';
import { EPosition } from '../../filters/components/filters/types';
import { trackOpenGeoModal } from '../../filters/components/location_switcher_button/tracking';
import { TagsContainer } from '../../filters/components/tags/container';
import { openDirectionsModal } from '../../filters/state/directions';
import { openDistrictSwitcher } from '../../filters/state/districts/popup';
import { changeJsonQuery } from '../../filters/state/json_query';
import { openLocationSwitcher } from '../../filters/state/location_switcher';
import { openMetroModal } from '../../filters/state/metro/toggle_modal';
import { openRegionalDistrictsModal } from '../../filters/state/regionalDistricts';
import { makeSearch } from '../../filters/state/search';
import { TTag } from '../../filters/state/tags';
import { IJsonQuery } from '../../repositories/common/json_query';
import { selectIsWithNeighborsFilterEnabled } from '../../selectors/filters/selectIsWithNeighborsFilterEnabled';
import { selectPromoSearchTypes } from '../../selectors/filters/selectPromoSearchTypes';
import { selectNewbuildingBlackFridayActive } from '../../selectors/newbuildingBlackFriday';
import { IAbUseExperiment } from '../../types/abUseExperiments';
import { TLocation } from '../../types/location';
import { useApplicationContext } from '../../utils/applicationContext';
import { checkIsHotelsSearch } from '../../utils/checkIsHotelsSearch';
import { IMakeRequest } from '../../utils/request';
import { selectIsRedesignMixedFiltersExperiment } from 'shared/selectors/experiments/selectIsRedesignMixedFiltersExperiment';

export const FiltersContainer: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const appContext = useApplicationContext();
  const makeRequest = useSelector<IAppState, IMakeRequest>(state => state.makeRequest);
  const resultsJsonQuery = useSelector<IAppState, IJsonQuery>(state => state.results.jsonQuery);
  const jsonQuery = useSelector<IAppState, IJsonQuery>(state => state.filters.jsonQuery);
  const jsonQueryCount = useSelector((state: IAppState) => state.filters.jsonQueryCount);
  const jsonQueryCountRefreshing = useSelector((state: IAppState) => state.filters.jsonQueryCountRefresing);
  const currentLocation = useSelector<IAppState, TLocation>(state => state.filters.currentLocation);
  const queryString = useSelector<IAppState, string | undefined>(state => state.filters.queryString) || '';
  const experiments = useSelector<IAppState, IAbUseExperiment[]>(state => state.abUseExperiments);
  const tags = useSelector<IAppState, TTag[]>(state => state.filters.tags);
  const areTagsAvailable = useSelector(areTagsAvailableSelector);
  const hasUnderground = useSelector(hasMetroSelector);
  const hasDistricts = useSelector(hasDistrictsSelector);
  const hasHighways = useSelector(hasHighwaysSelector);
  const hasRegionalDistricts = useSelector(hasRegionalDistrictsSelector);
  const isVillageMortgageFilterEnabled = useSelector(selectIsVillageMortgageFilterEnabled);
  const isWithNeighborsEnabled = useSelector(selectIsWithNeighborsFilterEnabled);
  const isNewbuildingBlackFridayActive = useSelector(selectNewbuildingBlackFridayActive);
  const promoSearchTypes = useSelector(selectPromoSearchTypes);
  const isMixedFiltersRedesign = useSelector(selectIsRedesignMixedFiltersExperiment);
  const isHotelsSearch = checkIsHotelsSearch(jsonQuery);
  const showSaveSearchButton = !isHotelsSearch;
  const isDatesChanged = React.useMemo(() => {
    const { gte: resultsDateFrom, lt: resultsDateTo } = getDateRangeValue('dates')(resultsJsonQuery) || {};
    const { gte: filtersDateFrom, lt: filtersDateTo } = getDateRangeValue('dates')(jsonQuery) || {};

    return resultsDateFrom !== filtersDateFrom || resultsDateTo !== filtersDateTo;
  }, [resultsJsonQuery, jsonQuery]);

  const { openedFilters, openFilter, closeFilter } = useFiltersState();
  const [lastAppliedModifiers, setLastAppliedModifiers] = React.useState<[TModifier, ...TModifier[]] | null>(null);
  const [filtersSectionPosition, setFiltersSectionPosition] = React.useState<EPosition>(EPosition.default);
  const updateFiltersHeightCb = React.useRef<() => void | undefined>();
  const isTooltipAvailable = !useTooltipDisabled({ filtersSectionPosition });

  const availableFeatures = React.useMemo<EFeature[]>(() => {
    const features: EFeature[] = [];
    if (isWithNeighborsEnabled) {
      features.push(EFeature.withNeighbors);
    }

    return features;
  }, [isWithNeighborsEnabled]);

  const handleChange = React.useCallback(
    (parameters: IOnChangeParameters) => {
      setLastAppliedModifiers(parameters.appliedModifiers);
      dispatch(changeJsonQuery(parameters));
    },
    [dispatch],
  );

  const handleOpenGeoPopup = React.useCallback(() => {
    trackOpenGeoModal();
    dispatch(openLocationSwitcher());
  }, [dispatch]);

  const handleOpenUndergroundPopup = React.useCallback(() => {
    dispatch(openMetroModal());
  }, [dispatch]);

  const handleOpenDistrictPopup = React.useCallback(() => {
    trackOpenDistrictsModalEvent();
    dispatch(openDistrictSwitcher());
  }, [dispatch]);

  const handleOpenHighwaysPopup = React.useCallback(() => {
    dispatch(openDirectionsModal());
  }, [dispatch]);

  const handleOpenRegionalDistrictsPopup = React.useCallback(() => {
    dispatch(openRegionalDistrictsModal());
  }, [dispatch]);

  const handleOpenFilter = React.useCallback(
    (filter: TFilter) => {
      openFilter(filter);

      if (filter === 'advancedFilters') {
        trackShowAdvancedFiltersClick();
      }
    },
    [openFilter],
  );

  const handleApply = React.useCallback(() => {
    dispatch(makeSearch());
  }, [dispatch]);

  const handleApplyClick = React.useCallback(
    ({ source }: IOnApplyClickParameters) => {
      switch (source) {
        case 'tooltip':
          trackTooltipSearchClick(queryString);
          break;
        case 'advancedFilters':
          trackAdvancedFiltersSearchClick(queryString);
          break;
        case 'findButton':
          trackSearchButtonClick(queryString);

          if (isDatesChanged) {
            trackApplyDatesModal();
          }
          break;
      }
    },
    [queryString, isDatesChanged],
  );

  const setUpdateHeightCb = React.useCallback((cb: () => void) => {
    updateFiltersHeightCb.current = cb;
  }, []);

  React.useEffect(() => {
    // при изменении кол-ва тегов, запускаем пересчет мин-высоты плавающих фильтров
    if (updateFiltersHeightCb.current) {
      updateFiltersHeightCb.current();
    }
  }, [tags]);

  return (
    <Provider
      jsonQuery={jsonQuery}
      jsonQueryCount={jsonQueryCount}
      jsonQueryCountRefreshing={jsonQueryCountRefreshing}
      lastAppliedModifiers={lastAppliedModifiers}
      openedFilters={openedFilters}
      currentLocation={currentLocation}
      features={availableFeatures}
      appContext={{
        ...appContext,
        makeRequest,
        experiments,
      }}
      filtersSectionPosition={filtersSectionPosition}
      onOpenFilter={handleOpenFilter}
      onCloseFilter={closeFilter}
      onClear={trackClearClick}
      onApplyClick={handleApplyClick}
      onChange={handleChange}
      onApply={handleApply}
      hasUnderground={hasUnderground}
      hasDistricts={hasDistricts}
      hasHighways={hasHighways}
      hasRegionalDistricts={hasRegionalDistricts}
      isTooltipAvailable={isTooltipAvailable}
      onDistrictPopupOpen={handleOpenDistrictPopup}
      onGeoPopupOpen={handleOpenGeoPopup}
      onUndergroundPopupOpen={handleOpenUndergroundPopup}
      onHighwaysPopupOpen={handleOpenHighwaysPopup}
      onRegionalDistrictsOpen={handleOpenRegionalDistrictsPopup}
      isVillageMortgageFilterEnabled={isVillageMortgageFilterEnabled}
      isNewbuildingBlackFridayActive={isNewbuildingBlackFridayActive}
      promoSearchTypes={promoSearchTypes}
      isMixedFiltersRedesign={isMixedFiltersRedesign}
    >
      <FiltersSection
        position={filtersSectionPosition}
        setUpdateHeightCb={setUpdateHeightCb}
        onChangePosition={setFiltersSectionPosition}
      >
        <Filters
          savedSearch={showSaveSearchButton && <SaveSearchButtonContainer />}
          applyButton={<ApplyFiltersButtonContainer />}
          tags={areTagsAvailable && <TagsContainer />}
        />
      </FiltersSection>
    </Provider>
  );
};
