import { UIHeading2, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import styles from './EmptyListingBanner.css';
import imageSrc from './assets/image.svg';

export const EmptyListingBanner = () => {
  const [showEmptyListingBanner, setShowEmptyListingBanner] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (containerRef.current) {
      const commentStart = document.createComment('noindex');
      const commentEnd = document.createComment('/noindex');

      containerRef.current.before(commentStart);
      containerRef.current.after(commentEnd);

      setShowEmptyListingBanner(true);
    }
  }, []);

  return (
    <div ref={containerRef} className={styles['empty-listing-banner']} data-name="EmptyListingBanner">
      {showEmptyListingBanner && (
        <>
          <img src={imageSrc} className={styles['empty-listing-banner-image']} />
          <div className={styles['empty-listing-banner--text']}>
            <UIHeading2 as="h3">У нас ещё нет таких объявлений</UIHeading2>
            <UIText1 as="div">Попробуйте изменить что-нибудь в фильтрах</UIText1>
          </div>
        </>
      )}
    </div>
  );
};
