import { ca } from '@cian/analytics';

import { IJsonQuery } from 'shared/json_query';

export const onEntryPointClick = ({ jsonQuery }: { jsonQuery: IJsonQuery }) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry',
    action: 'Click_sopr',
    label: 'Click',
    sc: jsonQuery,
    useLastProducts: true,
  });

export const onChatCloseClick = ({ jsonQuery }: { jsonQuery: IJsonQuery }) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry',
    action: 'Click_sopr',
    label: 'EndChat',
    sc: jsonQuery,
    useLastProducts: true,
  });

export const onEntryAutorisationPopupOpen = ({ jsonQuery, label }: { jsonQuery: IJsonQuery; label: string }) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry_popup',
    action: 'Show_sopr',
    label,
    sc: jsonQuery,
    useLastProducts: true,
  });

export const onEntryAutorisationPopupClick = ({ jsonQuery, label }: { jsonQuery: IJsonQuery; label: string }) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry_popup',
    action: 'Click_sopr',
    label,
    sc: jsonQuery,
    useLastProducts: true,
  });

export const onOpenChatPopup = ({ label, chatId, botId }: { label: string; chatId: string; botId: number }) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Message_popup',
    action: 'open',
    label,
    useLastProducts: true,
    page: {
      pageType: 'Listing',
      siteType: 'desktop',
      extra: {
        chat_id: chatId,
        bot_id: botId,
      },
    },
  });
