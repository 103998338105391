import { IMultiSuggestResult, IAddressSuggest, ESuggestType } from '@cian/geosuggest-widget';
import { type IHttpApi } from '@cian/http-api/shared';
import { type ILogger } from '@cian/logger';
import { either, isEmpty, isNil, reject } from 'ramda';

import * as geoApi from '../../../api/geo';
import { ISuggestItemsSchema } from '../../../repositories/geo-suggest/entities/entities/SuggestItemsSchema';
import { ISuggestRequest, EQueryParameters_1 as EOfferType } from '../../../repositories/geo-suggest/v2/suggest';
import { getGeoSuggestions, IGeoSuggestResult } from '../../../services/geoSuggest';
import { FOfferType, isSuburban } from '../../../utils/category';
import { TGeoType } from '../../state/geo';

export interface IAddressSuggestionOptions {
  boundedBy: [IYmapsCoord, IYmapsCoord] | undefined;
  results: number;
}

export interface IMultiSuggestParams {
  structured: ISuggestRequest;
  yandex: {
    value: string;
    options?: IAddressSuggestionOptions;
  };
}

export interface IResultSuggestions {
  addresses?: IAddressSuggest[];
  cities?: ISuggestItemsSchema['items'];
  districts?: ISuggestItemsSchema['items'];
  newbuildings?: ISuggestItemsSchema['items'];
  builders?: ISuggestItemsSchema['items'];
  undergrounds?: ISuggestItemsSchema['items'];
  villages?: ISuggestItemsSchema['items'];
  roads?: ISuggestItemsSchema['items'];
  railways?: ISuggestItemsSchema['items'];
  regionalDistricts?: ISuggestItemsSchema['items'];
}

export interface IMultiSuggestFull extends Omit<IMultiSuggestResult, 'suggestions'> {
  suggestions: IResultSuggestions;
}

export enum ESuggestOfferType {
  FLAT = 'flat',
  SUBURBAN = 'suburban',
  NEWOBJECT = 'newobject',
}

export function geoTypeMapper(geoType: ESuggestType): TGeoType {
  switch (geoType) {
    case ESuggestType.city:
      return 'location';
    case ESuggestType.district:
      return 'district';
    case ESuggestType.underground:
      return 'underground';
    case ESuggestType.newbuilding:
      return 'newobject';
    case ESuggestType.builder:
      return 'builder';
    case ESuggestType.road:
      return 'highway';
    case ESuggestType.railway:
      return 'railway';
    case ESuggestType.village:
      return 'village';
    case ESuggestType.regionalDistrict:
      return 'regional_district_id';
    default:
      return 'location';
  }
}

export function getSuggestOfferType(offerType: FOfferType) {
  if (isSuburban(offerType)) {
    return EOfferType.Suburban;
  } else if (offerType === FOfferType.FlatNew) {
    return EOfferType.Newobject;
  }

  return EOfferType.Flat;
}

export function getMultiSuggest(
  httpApi: IHttpApi,
  params: IMultiSuggestParams,
  logger: ILogger,
): Promise<IMultiSuggestFull> {
  const {
    yandex: { value, options },
    structured: structuredParams,
  } = params;

  return Promise.all([
    geoApi.suggest(value, options).catch(ex => {
      logger.warning(ex, { domain: 'shared.filters.components.geo_suggest.geoApi.suggest', initiator: 'loadYmapsApi' });

      return null;
    }),
    getGeoSuggestions({ httpApi, logger }, structuredParams).catch(() => null),
  ]).then((results: [IAddressSuggest[], IGeoSuggestResult | null]) => {
    const [yandex, structured] = results;

    if (!yandex && (!structured || (structured && !structured.data.suggestions))) {
      throw new Error('Failed to load suggest data');
    }

    let suggestions: IResultSuggestions = {};
    const sortOrder: string[] = [];

    if (structured && structured.data.suggestions) {
      const { cities, districts, newbuildings, builders, undergrounds, railways, roads, villages, regionalDistricts } =
        structured.data.suggestions;

      suggestions = {
        cities: cities && cities.items,
        districts: districts && districts.items,
        newbuildings: newbuildings && newbuildings.items,
        builders: builders && builders.items,
        undergrounds: undergrounds && undergrounds.items,
        villages: villages && villages.items,
        roads: roads && roads.items,
        railways: railways && railways.items,
        regionalDistricts: regionalDistricts && regionalDistricts.items,
      };
    }

    suggestions = reject(either(isNil, isEmpty), suggestions);

    if (structured && !isEmpty(suggestions)) {
      sortOrder.push(...structured.data.sortOrder);
    }

    if (yandex && !isEmpty(yandex)) {
      suggestions.addresses = Object.keys(suggestions).length ? yandex.slice(0, 5) : yandex;

      if (!sortOrder.includes('addresses')) {
        sortOrder.push('addresses');
      }
    }

    return {
      suggestions,
      sortOrder,
    };
  });
}
