import * as React from 'react';

export type TViewKeys = 'videoShorts' | 'carouselAutoZoomAutoScroll';

export type TCardInViewContext = Record<TViewKeys, boolean>;

export const CardInViewContext = React.createContext<TCardInViewContext>({} as TCardInViewContext);

export const useCardInViewContext = (key: TViewKeys) => {
  const inViewMap = React.useContext(CardInViewContext);

  return inViewMap[key];
};
