/* eslint-disable max-lines */

import { FDealType, FOfferType, isAvailable, isAvailableStrict } from '../../../../../JsonQuery';
import { TFilter } from '../../../types/advancedFilters';
import { IJsonQuery } from '../../../types/jsonQuery';

interface IFilterList {
  availability(jsonQuery: IJsonQuery): boolean;
  list: TFilter[];
}

const FILTERS_LIST: IFilterList[] = [
  {
    availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew),
    list: [
      'specialOffers',
      'withNeighbors',
      'completionYear',
      'undergroundTime',
      'offerType',
      'squareMeterArea',
      'layout',
      // Видео
      // Наличие фото
      // Тип объявления
      'ceilingHeight',
      'floor',
      'floors',
      'mortgagePromoSearchType',
      'discountPromoSearchType',
      'constructionType',
      'decorationsList',
      'saleType',
      'apartments',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatOld),
    list: [
      'specialOffers',
      'withNeighbors',
      'undergroundTime',
      'squareMeterArea',
      'floor',
      'floors',
      'subsidisedMortgage',
      'repair',
      'constructionYear',
      'constructionType',
      'demolition',
      'balcony',
      'elevators',
      'kitchenStove',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'windows',
      'apartments',
      'parking',
      'offerSeller',
      'saleType',
      'flatShare',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Room),
    list: [
      'withNeighbors',
      'dealType',
      'undergroundTime',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      'parking',
      'saleType',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.FlatShared),
    list: [
      'withNeighbors',
      'dealType',
      'undergroundTime',
      'squareMeterArea',
      'flatRooms',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      'parking',
      'saleType',
      'electronicTrading',
      'apartments',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Garage),
    list: [
      'undergroundTime',
      'squareMeterArea',
      'garageType',
      'garageStatus',
      'garageUtilities',
      'parking',
      'publicationDate',
      'electronicTrading',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },

  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Flat),
    list: [
      'withNeighbors',
      'dealType',
      'rentalPeriod',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'layout',
      'apartments',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'bathType',
      'kitchenStove',
      'repair',
      'windows',
      'floor',
      'floors',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      // Пандус
      'parking',
      'rentTerms',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Room),
    list: [
      'withNeighbors',
      'dealType',
      'rentalPeriod',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'bathType',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      // Пандус
      'parking',
      // Состав съемщиков
      'rentTerms',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Bed),
    list: [
      'withNeighbors',
      'dealType',
      'rentalPeriod',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'bathType',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'elevators',
      // Пандус
      'parking',
      // Состав съемщиков
      'rentTerms',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.House | FOfferType.HousePart | FOfferType.Townhouse),
    list: [
      'withNeighbors',
      'rentalPeriod',
      'fromMKAD',
      'undergroundTime',
      'suburbanArea',
      'facilities',
      'suburbanFacilities',
      'bathType',
      'suburbanBathroom',
      'repair',
      'floors',
      'constructionType',
      'heatingType',
      'constructionYear',
      'rentTerms',
      'livingConditions',
      'publicationDate',
      'dealType',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Garage),
    list: [
      'undergroundTime',
      'squareMeterArea',
      'garageType',
      'garageStatus',
      'garageUtilities',
      'parking',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Flat),
    list: [
      'onlineBooking',
      'withNeighbors',
      'dealType',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'apartments',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'bathType',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'elevators',
      // Пандус
      'parking',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Room | FOfferType.Bed),
    list: [
      'onlineBooking',
      'withNeighbors',
      'dealType',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'bathType',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'elevators',
      // Пандус
      'parking',
      // Состав съемщиков
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.House),
    list: [
      'onlineBooking',
      'withNeighbors',
      'fromMKAD',
      'undergroundTime',
      'suburbanAreaDailyrent',
      'facilities',
      'livingConditions',
      'suburbanFacilities',
      'bathType',
      'suburbanBathroom',
      'repair',
      'floors',
      'constructionType',
      'heatingType',
      'constructionYear',
      'publicationDate',
      'dealType',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Flat),
    list: [
      'specialOffers',
      'withNeighbors',
      'undergroundTime',
      'squareMeterArea',
      'floor',
      'floors',
      'subsidisedMortgage',
      'mortgagePromoSearchType',
      'discountPromoSearchType',
      'repair',
      'constructionYear',
      'constructionType',
      'demolition',
      'balcony',
      'elevators',
      'kitchenStove',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'windows',
      'apartments',
      'parking',
      'offerSeller',
      'saleType',
      'flatShare',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailableStrict(FDealType.Sale, FOfferType.House),
    list: [
      'withNeighbors',
      'fromMKAD',
      'undergroundTime',
      'landStatus',
      'constructionType',
      'suburbanHouseType',
      'suburbanBathroom',
      'suburbanUtilities',
      'heatingType',
      'suburbanFloors',
      'bedrooms',
      'constructionYear',
      'suburbanFacilities',
      'offerSeller',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.House | FOfferType.HousePart | FOfferType.Townhouse),
    list: [
      'withNeighbors',
      'fromMKAD',
      'undergroundTime',
      'landStatus',
      'constructionType',
      'suburbanHouseType',
      'suburbanBathroom',
      'suburbanUtilities',
      'heatingType',
      'suburbanFloors',
      'bedrooms',
      'constructionYear',
      'suburbanFacilities',
      'offerSeller',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Land),
    list: [
      'withNeighbors',
      'fromMKAD',
      'undergroundTime',
      'landStatus',
      'suburbanUtilities',
      'offerSeller',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Any, FOfferType.Any),
    list: ['withNeighbors', 'publicationDate', 'containWords', 'excludeWords', 'contact', 'offerId'],
  },
];

const FILTERS_LIST_REDESIGNED: IFilterList[] = [
  {
    availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew),
    list: [
      'objectType',
      'roomType',
      'price',
      'specialOffers',
      'withNeighbors',
      'completionYear',
      'undergroundTime',
      'offerType',
      'squareMeterArea',
      'layout',
      // Видео
      // Наличие фото
      // Тип объявления
      'ceilingHeight',
      'floor',
      'floors',
      'mortgagePromoSearchType',
      'discountPromoSearchType',
      'constructionType',
      'decorationsList',
      'saleType',
      'apartments',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatOld),
    list: [
      'objectType',
      'roomType',
      'price',
      'withNeighbors',
      'undergroundTime',
      'squareMeterArea',
      'floor',
      'floors',
      'subsidisedMortgage',
      'repair',
      'constructionYear',
      'constructionType',
      'demolition',
      'balcony',
      'elevators',
      'kitchenStove',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'windows',
      'apartments',
      'parking',
      'offerSeller',
      'saleType',
      'flatShare',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Room),
    list: [
      'withNeighbors',
      'dealType',
      'undergroundTime',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      'parking',
      'saleType',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.FlatShared),
    list: [
      'withNeighbors',
      'dealType',
      'undergroundTime',
      'squareMeterArea',
      'flatRooms',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      'parking',
      'saleType',
      'electronicTrading',
      'apartments',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Garage),
    list: [
      'undergroundTime',
      'squareMeterArea',
      'garageType',
      'garageStatus',
      'garageUtilities',
      'parking',
      'publicationDate',
      'electronicTrading',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Flat),
    list: [
      'withNeighbors',
      'dealType',
      'rentalPeriod',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'layout',
      'apartments',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'bathType',
      'kitchenStove',
      'repair',
      'windows',
      'floor',
      'floors',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      // Пандус
      'parking',
      'rentTerms',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Room),
    list: [
      'withNeighbors',
      'dealType',
      'rentalPeriod',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'bathType',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      // Пандус
      'parking',
      // Состав съемщиков
      'rentTerms',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Bed),
    list: [
      'withNeighbors',
      'dealType',
      'rentalPeriod',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'bathType',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'elevators',
      // Пандус
      'parking',
      // Состав съемщиков
      'rentTerms',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.House | FOfferType.HousePart | FOfferType.Townhouse),
    list: [
      'withNeighbors',
      'rentalPeriod',
      'fromMKAD',
      'undergroundTime',
      'suburbanArea',
      'facilities',
      'suburbanFacilities',
      'bathType',
      'suburbanBathroom',
      'repair',
      'floors',
      'constructionType',
      'heatingType',
      'constructionYear',
      'rentTerms',
      'livingConditions',
      'publicationDate',
      'dealType',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Garage),
    list: [
      'undergroundTime',
      'squareMeterArea',
      'garageType',
      'garageStatus',
      'garageUtilities',
      'parking',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Flat),
    list: [
      'onlineBooking',
      'withNeighbors',
      'dealType',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'apartments',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'bathType',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'elevators',
      // Пандус
      'parking',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Room | FOfferType.Bed),
    list: [
      'onlineBooking',
      'withNeighbors',
      'dealType',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'bathType',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'elevators',
      // Пандус
      'parking',
      // Состав съемщиков
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.House),
    list: [
      'onlineBooking',
      'withNeighbors',
      'fromMKAD',
      'undergroundTime',
      'suburbanAreaDailyrent',
      'facilities',
      'livingConditions',
      'suburbanFacilities',
      'bathType',
      'suburbanBathroom',
      'repair',
      'floors',
      'constructionType',
      'heatingType',
      'constructionYear',
      'publicationDate',
      'dealType',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Flat),
    list: [
      'objectType',
      'roomType',
      'price',
      'withNeighbors',
      'squareMeterArea',
      'undergroundTime',
      'floor',
      'floors',
      'balcony',
      'apartments',
      'constructionYear',
      'renovation',
      'constructionType',
      'ceilingHeight',
      'elevators',
      'urbanBathroom',
      'windows',
      'parking',
      'demolition',
      'flatShare',
      'electronicTrading',
      'offerSeller',
      'saleType',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailableStrict(FDealType.Sale, FOfferType.House),
    list: [
      'withNeighbors',
      'fromMKAD',
      'undergroundTime',
      'landStatus',
      'constructionType',
      'suburbanHouseType',
      'suburbanBathroom',
      'suburbanUtilities',
      'heatingType',
      'suburbanFloors',
      'bedrooms',
      'constructionYear',
      'suburbanFacilities',
      'offerSeller',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.House | FOfferType.HousePart | FOfferType.Townhouse),
    list: [
      'withNeighbors',
      'fromMKAD',
      'undergroundTime',
      'landStatus',
      'constructionType',
      'suburbanHouseType',
      'suburbanBathroom',
      'suburbanUtilities',
      'heatingType',
      'suburbanFloors',
      'bedrooms',
      'constructionYear',
      'suburbanFacilities',
      'offerSeller',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Land),
    list: [
      'withNeighbors',
      'fromMKAD',
      'undergroundTime',
      'landStatus',
      'suburbanUtilities',
      'offerSeller',
      'electronicTrading',
      'publicationDate',
      'containWords',
      'excludeWords',
      'contact',
      'offerId',
    ],
  },
  {
    availability: isAvailable(FDealType.Any, FOfferType.Any),
    list: ['withNeighbors', 'publicationDate', 'containWords', 'excludeWords', 'contact', 'offerId'],
  },
];

export function getAvailableFilters(jsonQuery: IJsonQuery, isMixedFiltersRedesign: boolean): TFilter[] {
  const filtersList = isMixedFiltersRedesign ? FILTERS_LIST_REDESIGNED : FILTERS_LIST;

  const type = filtersList.find((f: IFilterList) => f.availability(jsonQuery));

  return (type && type.list) || [];
}
