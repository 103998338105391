// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TSearchOffersDesktopRequest,
  TSearchOffersDesktopModel,
  IMappers,
  TSearchOffersDesktopResponse,
  TSearchOffersDesktopResponse_1,
  TSearchOffersDesktopResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TSearchOffersDesktopModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-offers',
  pathApi: '/v2/search-offers-desktop/',
  requestType: 'json',
  interserviceAuthEnabled: true,
} as TSearchOffersDesktopModel;

export function createSearchOffersDesktopModel(parameters: TSearchOffersDesktopRequest): TSearchOffersDesktopModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISearchOffersDesktopOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TSearchOffersDesktopRequest;
}

export async function fetchSearchOffersDesktop<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ISearchOffersDesktopOptions<TResponse200, TResponse400>): Promise<
  TSearchOffersDesktopResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createSearchOffersDesktopModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TSearchOffersDesktopResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TSearchOffersDesktopResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TSearchOffersDesktopResponse;
}
