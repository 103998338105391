import { ResponseError } from '../../errors';
import { InvalidLocationError } from '../../errors/InvalidLocationError';
import { prepareOffers } from '../../mappers/prepareOffers';
import {
  fetchSearchOffersDesktop,
  TSearchOffersDesktopResponse,
} from '../../repositories/search-offers/v2/search-offers-desktop';
import { IApplicationContext } from '../../types/applicationContext';
import { IOffersData } from '../../types/offersData';

interface IFetchOffersPathParameters {
  subdomain: string;
  path: string;
}

interface IFetchOffersJsonQueryParameters {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsonQuery: any;
}

type TFetchOffersParameters = IFetchOffersPathParameters | IFetchOffersJsonQueryParameters;

export async function fetchOffers(
  { httpApi }: IApplicationContext,
  _parameters: TFetchOffersParameters,
): Promise<IOffersData> {
  const parameters =
    'jsonQuery' in _parameters
      ? { jsonQuery: _parameters.jsonQuery }
      : { _subdomain: _parameters.subdomain, _path: _parameters.path };

  const offersData: TSearchOffersDesktopResponse = await fetchSearchOffersDesktop({
    httpApi,
    parameters,
  });

  if (offersData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchOffers',
      message: offersData.response.message || '',
      details: {
        errors: JSON.stringify(offersData.response.errors),
      },
    });
  }

  if (offersData.response.data.redirectData) {
    const { redirectData } = offersData.response.data;

    if (!redirectData.location || !redirectData.statusCode) {
      throw new ResponseError({
        domain: 'fetchOffers',
        message: 'Неверный формат redirectData',
        details: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          redirectData: redirectData as any,
        },
      });
    }

    throw new InvalidLocationError({
      domain: 'fetchOffers',
      message: 'В ответе API пришёл редирект',
      details: {
        redirectStatusCode: redirectData.statusCode as 301 | 302,
        redirectLocation: redirectData.location,
      },
    });
  }

  return prepareOffers(offersData.response);
}
