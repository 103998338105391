/* eslint-disable max-lines */
import { Button } from '@cian/ui-kit';
import classNames from 'classnames';
import * as React from 'react';

import { TagNew } from './TagNew';
import { FitInOneLineContainer } from './fit_in_one_line/container';
import { AgentCallsForbiddenTagItem } from './items/agent_calls_forbidden';
import { ApartmentTagItem } from './items/apartment';
import { AreaTagItem } from './items/area';
import { BalconyTagItem } from './items/balcony';
import { BlackFridayTagItem } from './items/blackFriday';
import { CeilingHeightTagItem } from './items/ceiling_height';
import { ComissionTypeTagItem } from './items/comission_type';
import { DecorationTagItem } from './items/decoration';
import { DeveloperTagItem } from './items/developer';
import { DomrfTagItem } from './items/domrf';
import { ElectronicTradingTypeTagItem } from './items/electronic_trading_type';
import { ElevatorsHasServiceTagItem } from './items/elevators_has_service';
import { ElevatorsMinCountTagItem } from './items/elevators_min_count';
import { FacilityTagItem } from './items/facility';
import { FlatShareTagItem } from './items/flat_share';
import { FloorTagItem } from './items/floor';
import { FurnitureTagItem } from './items/furniture';
import { GarageKindsTagItem } from './items/garage_kind';
import { GarageMaterialsTagItem } from './items/garage_material';
import { GarageStatusesTagItem } from './items/garage_status';
import { GarageTypesTagItem } from './items/garage_type';
import {
  GeoBuilderTagItem,
  GeoCircleTagItem,
  GeoDistrictTagItem,
  GeoHighwayTagItem,
  GeoHouseTagItem,
  GeoLocationTagItem,
  GeoNewobjectTagItem,
  GeoPolygonTagItem,
  GeoBboxTagItem,
  GeoStreetTagItem,
  GeoUndergroundTagItem,
  GeoRegionalDistrictTagItem,
  RailwayTagItem,
} from './items/geo';
import { HeatingTypesTagItem } from './items/heating';
import { HiddenBaseTagItem } from './items/hidden_base';
import { HouseMaterialTagItem } from './items/house_material';
import { HouseYearTagItem } from './items/house_year';
import { IdenticalOffersIdTagItem } from './items/identical_offers_id';
import { IsFirstFloorTagItem } from './items/is_first_floor';
import { KpIdTagItem } from './items/kp_id';
import { LandStatusTypesTagItem } from './items/land_status';
import { LayoutTypeTagItem } from './items/layout';
import { LoggiaTagItem } from './items/loggia';
import { MaxPrepayTagItem } from './items/max_prepay';
import { MortgagePossibleTagItem } from './items/mortgage_possible';
import { MultiIdTagItem } from './items/multi_id';
import { NewMoscowTagItem } from './items/new_moscow';
import { NotLastFloorTagItem } from './items/not_last_floor';
import { OfferSellerTagItem } from './items/offerSeller';
import { OfferIdTagItem } from './items/offer_id';
import { ParkingTypesTagItem } from './items/parking';
import { PenthouseTagItem } from './items/penthouse';
import { PhoneTagItem } from './items/phone';
import { PossessorTagItem } from './items/possessor';
import { PublishPeriodTagItem } from './items/publish_period';
import { RentTimeTagItem } from './items/rent_time';
import { RepairTagItem } from './items/repair';
import { RoomsInDealTagItem } from './items/rooms_in_deal';
import { RoomsTotalTagItem } from './items/rooms_total';
import { SaleTypesTagItem } from './items/sale_type';
import { SuburbanHouseTypeTagItem } from './items/suburban_house_type';
import { SuburbanUtilityTagItem } from './items/suburban_utility';
import { SuburbanWCTypeTagItem } from './items/suburban_wc';
import { TenantsTagItem } from './items/tenants';
import { TotalBedroomsTagItem } from './items/total_bedrooms';
import { TotalFloorsTagItem } from './items/total_floors';
import { UndergroundTimeTagItem } from './items/underground_time';
import { UserIdTagItem } from './items/user_id';
import { WCCountTagItem } from './items/wc_count';
import { WCTypeTagItem } from './items/wc_type';
import { WindowsTypeTagItem } from './items/windows';
import { WithLayoutTagItem } from './items/with_layout';
import { WithPhotoTagItem } from './items/with_photo';
import { WithoutDepositTagItem } from './items/without_deposit';
import { WordsExcludeTagItem } from './items/words_exclude';
import { WordsIncludeTagItem } from './items/words_include';
import { TGeoSpecialTag, trackGeoTagDeleted, trackClearButtonClick } from './trackings';
import { getValueFromComputedStyle } from '../../../../browser/utils/dom';
import { EMetroTravelType, IJsonQuery } from '../../../json_query';
import { FOfferType, offerTypeFromJsonQuery } from '../../../utils/category';
import { TTag } from '../../state/tags';

const style = require('./index.css');

export interface ITagsProps {
  tags: TTag[];
  travelType: EMetroTravelType;
  travelTime: number | undefined;
  jsonQuery: IJsonQuery;
  isFitInOneLineEnabled: boolean;
  userIsAuthenticated: boolean;

  onTagDeleted(tag: TTag, index: number): void;
  onAllTagsDeleted(): void;
}

export interface ITagsOwnProps {
  isAdvancedFiltersOpened?: boolean;
}

export interface ITagsState {
  tagsMaxWidth: number;
}

const GEO_SPECIAL_TYPES_TAGS: TGeoSpecialTag[] = [
  'geo-location',
  'geo-district',
  'geo-street',
  'geo-underground',
  'geo-railway',
  'geo-newobject',
];

export type TTagsProps = ITagsProps & ITagsOwnProps;

export class Tags extends React.Component<TTagsProps, ITagsState> {
  public state = {
    tagsMaxWidth: 0,
  };

  private buttons: HTMLDivElement | null = null;
  private undergroundTravel: HTMLDivElement | null = null;
  private wrapper: HTMLDivElement | null = null;

  public componentDidMount() {
    this.updateTagsMaxWidth();
    window.addEventListener('resize', this.updateTagsMaxWidth);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateTagsMaxWidth);
  }

  public componentDidUpdate(prevProps: ITagsProps) {
    const clearButtonTagsLengthReached = prevProps.tags.length < 2 && this.props.tags.length >= 2;

    if (clearButtonTagsLengthReached || prevProps.isFitInOneLineEnabled !== this.props.isFitInOneLineEnabled) {
      this.updateTagsMaxWidth();
    }
  }

  public render() {
    const { isFitInOneLineEnabled, tags, jsonQuery } = this.props;

    const offerType = offerTypeFromJsonQuery(jsonQuery);

    return (
      <div
        ref={wrapper => (this.wrapper = wrapper)}
        className={classNames(style['container'], isFitInOneLineEnabled && style['container--line'])}
      >
        <FitInOneLineContainer maxWidth={this.state.tagsMaxWidth}>
          {tags.map((tag, index) => {
            return (
              <div className={style['tag']} key={index}>
                {renderTag(tag, () => this.onTagDeleted(tag, index), offerType)}
              </div>
            );
          })}
        </FitInOneLineContainer>
        {tags.length >= 2 && (
          <div ref={buttons => (this.buttons = buttons)} className={style['buttons']}>
            <Button size="XS" theme="fill_secondary" onClick={this.handleAllTagsDeleted}>
              Очистить
            </Button>
          </div>
        )}
      </div>
    );
  }

  private handleAllTagsDeleted = () => {
    trackClearButtonClick(this.props.isAdvancedFiltersOpened);
    this.props.onAllTagsDeleted();
  };

  private onTagDeleted = (tag: TTag, index: number) => {
    const tagType = tag.type as TGeoSpecialTag;

    /**
     * Если это гео тэг улицы, района, шоссе, региона или ЖД - отправляем аналитику
     */
    if (GEO_SPECIAL_TYPES_TAGS.includes(tagType)) {
      trackGeoTagDeleted(tagType);
    }

    this.props.onTagDeleted(tag, index);
  };

  private updateTagsMaxWidth = () => {
    if (!this.wrapper) {
      return;
    }

    let tagsMaxWidth = this.wrapper.getBoundingClientRect().width;

    if (this.undergroundTravel) {
      const computedStyle = getComputedStyle(this.undergroundTravel);
      tagsMaxWidth -= this.undergroundTravel.getBoundingClientRect().width;
      tagsMaxWidth -= getValueFromComputedStyle(computedStyle, 'marginRight');
    }

    if (this.buttons) {
      const computedStyle = getComputedStyle(this.buttons);
      tagsMaxWidth -= this.buttons.getBoundingClientRect().width;
      tagsMaxWidth -= getValueFromComputedStyle(computedStyle, 'marginLeft');
    }

    this.setState({
      tagsMaxWidth,
    });
  };
}

function renderTag(tag: TTag, onDeleteClick: () => void, offerType: FOfferType): React.ReactNode {
  switch (tag.type) {
    case 'advanced-comission-comissionType':
      return <ComissionTypeTagItem key="ComissionTypeTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-comission-agentCallsForbidden':
      return <AgentCallsForbiddenTagItem key="AgentCallsForbiddenTagItem" onDeleteClick={onDeleteClick} />;

    case 'advanced-has-extinguishing-system':
      return (
        <TagNew key="advanced-has-extinguishing-system" onDeleteClick={onDeleteClick}>
          Есть система пожаротушения
        </TagNew>
      );

    case 'advanced-has-heating':
      return (
        <TagNew key="advanced-has-heating" onDeleteClick={onDeleteClick}>
          Есть отопление
        </TagNew>
      );

    case 'advanced-has-electricity':
      return (
        <TagNew key="advanced-has-electricity" onDeleteClick={onDeleteClick}>
          Есть электричество
        </TagNew>
      );

    case 'advanced-has-light':
      return (
        <TagNew key="advanced-has-light" onDeleteClick={onDeleteClick}>
          Есть свет
        </TagNew>
      );

    case 'advanced-has-water':
      return (
        <TagNew key="advanced-has-water" onDeleteClick={onDeleteClick}>
          Есть водоснабжение
        </TagNew>
      );

    case 'advanced-has-video':
      return (
        <TagNew key="advanced-has-video" onDeleteClick={onDeleteClick}>
          Только с видео
        </TagNew>
      );

    case 'advanced-heating':
      return <HeatingTypesTagItem key="HeatingTypesTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-balconies-balcony':
      return <BalconyTagItem key="BalconyTagItem" onDeleteClick={onDeleteClick} />;

    case 'advanced-balconies-loggia':
      return <LoggiaTagItem key="LoggiaTagItem" onDeleteClick={onDeleteClick} />;

    case 'advanced-tenants':
      return <TenantsTagItem key="TenantsTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-houseYear':
      return <HouseYearTagItem key="HouseYearTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-phone':
      return <PhoneTagItem key="PhoneTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-decoration':
      return <DecorationTagItem key="DecorationTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-demolished-in-moscow-programm':
      return (
        <TagNew key="DemolishedInMoscowProgrammTag" onDeleteClick={onDeleteClick}>
          {tag.value ? 'Только дома под снос' : 'Не показывать дома под снос'}
        </TagNew>
      );

    case 'advanced-developer-type':
      return <DeveloperTagItem key="DeveloperTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-landStatus':
      return <LandStatusTypesTagItem key="LandStatusTypesTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-offerId':
      return <OfferIdTagItem key="OfferTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'userId':
      return <UserIdTagItem key="UserIdTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-parking':
      return <ParkingTypesTagItem key="ParkingTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-suburbanWC':
      return <SuburbanWCTypeTagItem key="SuburbanWCTypeTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-suburbanHouseType':
      return <SuburbanHouseTypeTagItem key="SuburbanHouseTypeTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-doneYear-isDone':
      return (
        <TagNew key="BuildingDoneTagItem-isDone" onDeleteClick={onDeleteClick}>
          Дом сдан
        </TagNew>
      );

    case 'advanced-doneYear-years':
      return (
        <TagNew key="BuildingDoneTagItem-years" onDeleteClick={onDeleteClick}>
          Год сдачи {tag.value}
        </TagNew>
      );

    case 'advanced-wc-wcType':
      return <WCTypeTagItem key="WCTypeTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-wc-wcCount':
      return <WCCountTagItem key="WCCountTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-windows':
      return <WindowsTypeTagItem key="WindowsTypeTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-ceilingHeight':
      return <CeilingHeightTagItem key="CeilingHeightTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-layout':
      return <LayoutTypeTagItem key="LayoutTypeTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-elevators-minCount':
      return <ElevatorsMinCountTagItem key="ElevatorsMinCountTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-elevators-hasService':
      return <ElevatorsHasServiceTagItem key="ElevatorsHasServiceTagItem" onDeleteClick={onDeleteClick} />;

    case 'advanced-rentTime':
      return <RentTimeTagItem key="RentTimeTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-floor-floor':
      return <FloorTagItem key="FloorTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-floor-isFirstFloor':
      return <IsFirstFloorTagItem key="IsFirstFloorTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-floor-notLastFloor':
      return <NotLastFloorTagItem key="NotLastFloorTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-roomsInDeal':
      return <RoomsInDealTagItem key="RoomsInDealTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-roomsTotal':
      return <RoomsTotalTagItem key="RoomsTotalTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-facilities-facility':
      return <FacilityTagItem key="FacilityTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-suburbanUtility':
      return <SuburbanUtilityTagItem key="SuburbanUtilityTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-facilities-furniture':
      return <FurnitureTagItem key="FurnitureTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-dwellingStatus-apartment':
      return <ApartmentTagItem key="ApartmentTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-flat_share':
      return <FlatShareTagItem key="ApartmentTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-dwellingStatus-penthouse':
      return <PenthouseTagItem key="PenthouseTagItem" onDeleteClick={onDeleteClick} />;

    case 'advanced-rentTerms-maxPrepay':
      return <MaxPrepayTagItem key="MaxPrepayTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-rentTerms-withoutDeposit':
      return <WithoutDepositTagItem key="WithoutDepositTagItem" onDeleteClick={onDeleteClick} />;

    case 'advanced-wordsInclude':
      return <WordsIncludeTagItem key="WordsIncludeTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-wordsExclude':
      return <WordsExcludeTagItem key="WordsExcludeTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-saleType-mortgagePossible':
      return <MortgagePossibleTagItem key="MortgagePossibleTagItem" onDeleteClick={onDeleteClick} />;

    case 'advanced-saleType-saleType':
      return <SaleTypesTagItem key="SaleTypesTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-garage':
      return <GarageTypesTagItem key="GarageTypesTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-garageKind':
      return <GarageKindsTagItem key="GarageKindsTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-garageMaterial':
      return <GarageMaterialsTagItem key="GarageMaterialsTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-garageStatus':
      return <GarageStatusesTagItem key="GarageStatusesTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-by-homeowner':
      return (
        <TagNew key="advanced-by-homeowner" onDeleteClick={onDeleteClick}>
          От собственника
        </TagNew>
      );

    case 'advanced-offerSeller':
      return <OfferSellerTagItem key="OfferSellerTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-withPhoto':
      return <WithPhotoTagItem key="WithPhotoTagItem" onDeleteClick={onDeleteClick} />;

    case 'tour-3d':
      return (
        <TagNew key="tour-3d" onDeleteClick={onDeleteClick}>
          3D-туры
        </TagNew>
      );

    case 'advanced-withLayout':
      return <WithLayoutTagItem key="WithLayoutTagItem" onDeleteClick={onDeleteClick} />;

    case 'totalArea':
      return <AreaTagItem key="TotalAreaTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'roomArea':
      return <AreaTagItem key="RoomAreaTagItem" tag={tag} onDeleteClick={onDeleteClick} title="Площадь комнаты" />;

    case 'kitchenArea':
      return <AreaTagItem key="KitchenAreaTagItem" tag={tag} onDeleteClick={onDeleteClick} title="Площадь кухни" />;

    case 'siteArea':
      return (
        <AreaTagItem
          key="KitchenAreaTagItem"
          tag={tag}
          onDeleteClick={onDeleteClick}
          title="Площадь участка"
          unit="сот."
        />
      );

    case 'livingArea':
      return <AreaTagItem key="LivingAreaTagItem" tag={tag} onDeleteClick={onDeleteClick} title="Жилая площадь" />;

    case 'totalFloors':
      return <TotalFloorsTagItem key="TotalFloorsTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'publishPeriod':
      return <PublishPeriodTagItem key="PublishPeriodTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'houseMaterial':
      return (
        <HouseMaterialTagItem
          key={`HouseMaterialTagItem-${tag.value}`}
          tag={tag}
          onDeleteClick={onDeleteClick}
          offerType={offerType}
        />
      );

    case 'repair':
      return <RepairTagItem key={`RepairTagItem-${tag.value}`} tag={tag} onDeleteClick={onDeleteClick} />;

    case 'geo-location':
      return <GeoLocationTagItem key={`GeoLocationTagItem-${tag.value}`} tag={tag} onDeleteClick={onDeleteClick} />;

    case 'geo-newobject':
      return <GeoNewobjectTagItem key={`GeoNewobjectTagItem-${tag.value}`} tag={tag} onDeleteClick={onDeleteClick} />;

    case 'geo-street':
      return <GeoStreetTagItem key={`GeoStreetTagItem-${tag.value}`} tag={tag} onDeleteClick={onDeleteClick} />;

    case 'geo-house':
      return <GeoHouseTagItem key={`GeoHouseTagItem-${tag.value}`} tag={tag} onDeleteClick={onDeleteClick} />;

    case 'geo-underground':
      return (
        <GeoUndergroundTagItem key={`GeoUndergroundTagItem-${tag.value}`} tag={tag} onDeleteClick={onDeleteClick} />
      );

    case 'geo-railway':
      return <RailwayTagItem key={`GeoRailwayTagItem-${tag.value}`} tag={tag} onDeleteClick={onDeleteClick} />;

    case 'geo-district':
      return <GeoDistrictTagItem key={`GeoDistrictTagItem-${tag.value}`} tag={tag} onDeleteClick={onDeleteClick} />;

    case 'geo-regional-district':
      return (
        <GeoRegionalDistrictTagItem
          key={`GeoRegionalDistrictTagItem-${tag.value}`}
          tag={tag}
          onDeleteClick={onDeleteClick}
        />
      );

    case 'geo-highway':
      return <GeoHighwayTagItem key={`GeoHighwayTagItem-${tag.value}`} tag={tag} onDeleteClick={onDeleteClick} />;

    case 'geo-builder':
      return <GeoBuilderTagItem key={`GeoBuilderTagItem-${tag.value}`} tag={tag} onDeleteClick={onDeleteClick} />;

    case 'geo-polygon':
      return <GeoPolygonTagItem key={`GeoPolygonTagItem-${tag.value}`} tag={tag} onDeleteClick={onDeleteClick} />;

    case 'geo-bbox':
      return <GeoBboxTagItem key="GeoBboxTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'geo-circle':
      return (
        <GeoCircleTagItem
          key={`GeoCircleTagItem-${tag.center}-${tag.radius}`}
          tag={tag}
          onDeleteClick={onDeleteClick}
        />
      );

    case 'IncludeNewMoscow':
      return <NewMoscowTagItem key="NewMoscowTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'is-in-hidden-base':
      return <HiddenBaseTagItem key="HiddenBaseTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'multiId':
      return <MultiIdTagItem key="MultiIdTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'kp-id':
      return <KpIdTagItem key="KpIdTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'domrf':
      return <DomrfTagItem key="DomrfTagItem" onDeleteClick={onDeleteClick} />;

    case 'identicalOffersId':
      return <IdenticalOffersIdTagItem key="IdenticalOffersIdTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'has-flat-tour-booking':
      return (
        <TagNew key="has-flat-tour-booking" onDeleteClick={onDeleteClick}>
          С записью на просмотр
        </TagNew>
      );

    case 'has-profitbase-booking':
      return (
        <TagNew key="has-profitbase-booking" onDeleteClick={onDeleteClick}>
          C онлайн-бронированием
        </TagNew>
      );

    case 'advanced-totalBedrooms':
      return <TotalBedroomsTagItem key="TotalBedroomsTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-possessor':
      return <PossessorTagItem key="PossessorTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'advanced-black-friday':
      return <BlackFridayTagItem key="BlackFridayTagItem" offerType={offerType} onDeleteClick={onDeleteClick} />;

    case 'advanced-electronicTradingType':
      return (
        <ElectronicTradingTypeTagItem key="ElectronicTradingTypeTagItem" tag={tag} onDeleteClick={onDeleteClick} />
      );

    case 'advanced-undergroundTime':
      return <UndergroundTimeTagItem key="UndergroundTimeTagItem" tag={tag} onDeleteClick={onDeleteClick} />;

    case 'builder':
      return (
        <TagNew key="builder" onDeleteClick={onDeleteClick}>
          {tag.name}
        </TagNew>
      );

    case 'promo_search_types':
      return (
        <TagNew key="promo_search_types" onDeleteClick={onDeleteClick}>
          {tag.label}
        </TagNew>
      );

    case 'decorations_list':
      return (
        <TagNew key="promo_search_types" onDeleteClick={onDeleteClick}>
          {tag.label}
        </TagNew>
      );

    default:
      return null;
  }
}
