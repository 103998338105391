import { map } from 'ramda';

import { isFromBank } from './agent/isFromBank';
import { isFromLeadFactory } from './agent/isFromLeadFactory';
import { isFromSuburbanBuilder } from './agent/isFromSuburbanBuilder';
import { agentImageLink } from './agentImageLink';
import { agentInfo } from './agentInfo';
import { agentName } from './agentName';
import { agentOffersLink } from './agentOffersLink';
import { agentPhoneWarningMessage } from './agentPhoneWarningMessage';
import { agentProfileLink } from './agentProfileLink';
import { agentType } from './agentType';
import { agentWorkTime } from './agentWorkTime';
import { discount } from './discount';
import { geoLabels } from './geoLabels';
import { hasTour } from './hasTour';
import { hasVideo } from './hasVideo';
import { highway } from './highway';
import { isAgent } from './isAgent';
import { isChatAvailable } from './isChatAvailable';
import { isMoscow } from './isMoscow';
import { isNewbuilding } from './isNewbuilding';
import { isNewbuildingFromBuilder } from './isNewbuildingFromBuilder';
import { isPremium } from './isPremium';
import { isProAgent } from './isProAgent';
import { isSpb } from './isSpb';
import { isStandarded } from './isStandarded';
import { isTop } from './isTop';
import { jk } from './jk';
import { kp } from './kp';
import { labels } from './labels';
import { media } from './media';
import { mediaLabels } from './mediaLabels';
import { comparisonStatus } from './offersComparison';
import { originalTitle } from './originalTitle';
import { price } from './price';
import { priceInfo } from './priceInfo';
import { railway } from './railway';
import { seoSuggestionDistance } from './seoSuggestionDistance';
import { seoSuggestionLabel } from './seoSuggestionLabel';
import { similarLink } from './similarLink';
import { subtitle } from './subtitle';
import { thumbnails } from './thumbnails';
import { title } from './title';
import { underground } from './underground';
import { vasList } from './vasList';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { IOfferHelperProps } from '../../types/offer-card';

export const combineComputedProps: TComputedPropMapper<IOfferHelperProps> = (...args) =>
  map(fn => fn(...args), {
    agentType,
    agentImageLink,
    agentInfo,
    agentName,
    agentOffersLink,
    agentPhoneWarningMessage,
    agentProfileLink,
    agentWorkTime,
    geoLabels,
    hasTour,
    hasVideo,
    highway,
    isAgent,
    isChatAvailable,
    isStandarded,
    isFromBank,
    isNewbuilding,
    isNewbuildingFromBuilder,
    isFromLeadFactory,
    isPremium,
    isProAgent,
    isTop,
    jk,
    kp,
    media,
    mediaLabels,
    labels,
    price,
    priceInfo,
    railway,
    seoSuggestionDistance,
    seoSuggestionLabel,
    similarLink,
    subtitle,
    thumbnails,
    title,
    originalTitle,
    underground,
    vasList,
    isMoscow,
    comparisonStatus,
    isSpb,
    isFromSuburbanBuilder,
    discount,
  });
