// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  ICianApiSiteV1GetNewobjectsBuilderRequest,
  TCianApiSiteV1GetNewobjectsBuilderModel,
  IMappers,
  TCianApiSiteV1GetNewobjectsBuilderResponse,
  TCianApiSiteV1GetNewobjectsBuilderResponse_1,
  TCianApiSiteV1GetNewobjectsBuilderResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TCianApiSiteV1GetNewobjectsBuilderModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/get-newobjects-builder/',
  interserviceAuthEnabled: true,
} as TCianApiSiteV1GetNewobjectsBuilderModel;

export function createCianApiSiteV1GetNewobjectsBuilderModel(
  parameters: ICianApiSiteV1GetNewobjectsBuilderRequest,
): TCianApiSiteV1GetNewobjectsBuilderModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICianApiSiteV1GetNewobjectsBuilderOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: ICianApiSiteV1GetNewobjectsBuilderRequest;
}

export async function fetchCianApiSiteV1GetNewobjectsBuilder<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICianApiSiteV1GetNewobjectsBuilderOptions<TResponse200, TResponse400>): Promise<
  TCianApiSiteV1GetNewobjectsBuilderResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createCianApiSiteV1GetNewobjectsBuilderModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TCianApiSiteV1GetNewobjectsBuilderResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TCianApiSiteV1GetNewobjectsBuilderResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCianApiSiteV1GetNewobjectsBuilderResponse;
}
