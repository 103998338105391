import { IConfig } from '@cian/config/shared';

import { isNewbuildingOnly } from './isNewbuildingOnly';
import { TOfferDataParam } from '../types';
import { NB_CONSULTANT_MESSAGE_BUTTON } from 'shared/constants/experiments';
import { TChatOnboardingByType } from 'shared/types/chatOnboarding';
import { IExperiment } from 'shared/types/experiments';
import { isAbUseExperimentGroup } from 'shared/utils/experiments/isAbUseExperimentGroup';

interface IParams {
  config: IConfig;
  data: TOfferDataParam;
  experiments: IExperiment[];
}

// TODO: Удалить в CD-226143/CD-225150. Онбординги на месяц.
export function getConsultantChatOnboarding({ config, data, experiments }: IParams): TChatOnboardingByType {
  const isOnboardingEnabled = config.get<boolean>('Newbuilding.WebimChatOnboarding.Enabled');
  const onboardingText =
    config.get<string>('Newbuilding.WebimChatOnboarding.Text') ??
    'Консультант бесплатно подберёт вам\u00a0квартиру в\u00a0новостройке и\u00a0ответит на\u00a0вопросы';
  const isConsultantMessageButtonEnabled = config.get<string>('Newbuilding.ConsultantMessageButton.Enabled');
  const accountName = config.get<string>('webim.accountName');

  if (
    !isOnboardingEnabled ||
    !isConsultantMessageButtonEnabled ||
    !accountName ||
    !isNewbuildingOnly(data) ||
    !isAbUseExperimentGroup(experiments, NB_CONSULTANT_MESSAGE_BUTTON, 'B')
  ) {
    return null;
  }

  const offer = data.offersSerialized.find(({ chat, newbuilding }) =>
    Boolean(newbuilding && (newbuilding.isFromDeveloper || newbuilding.isFromLeadFactory) && !chat?.canSendMessage),
  );

  if (offer) {
    return {
      onboardingOfferId: offer.id,
      wasChatOpened: false,
      onboardingText,
    };
  }

  return null;
}
