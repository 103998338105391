import { ICookieOptions, setCookie } from '@cian/utils/lib/browser/cookie';
import { parseCookies } from '@cian/utils/lib/shared/cookie';

export function getChatOnboardingCookie(cookieName: string): boolean {
  // istanbul ignore else
  if (typeof window !== 'undefined') {
    const cookie = parseCookies(window.document.cookie)[cookieName];

    return cookie === '1';
  }

  return false;
}

export function setChatOnboardingCookie(cookieName: string) {
  const cookieConfig: ICookieOptions = { expires: 365 * 86400, secure: false };

  setCookie(cookieName, '1', cookieConfig);
}
