import React from 'react';

import { Title } from '../../../components/Title';
import { useOfferContext } from '../../../context';
import { ENewbuildingSnippetType } from '../../../types/offer';
import { getOfferName } from '../../../utils/computedProps/title/getOfferName';
import { getOfferSubtitle } from '../../../utils/computedProps/title/getOfferSubtitle';
import { isCianPartner as isCianPartnerUtil } from 'shared/utils/dailyrent/isCianPartner';

export function TitleContainer() {
  const { title, originalTitle, subtitle: offerSubtitle, isTop, isNewbuilding, offer } = useOfferContext();
  const { fullUrl, externalOfferUrl, newbuilding, dailyrent } = offer;

  const isCianPartner = isCianPartnerUtil(offer);

  const rel = isCianPartner ? 'noreferrer nofollow noindex' : 'noreferrer';
  const href = isCianPartner ? externalOfferUrl : fullUrl;

  const subtitle = dailyrent?.hotelData?.isHotel
    ? null
    : getOfferSubtitle({
        isNewbuilding,
        originalSubtitle: offerSubtitle,
        house: newbuilding?.house,
        hideCompletionDate:
          Boolean(newbuilding?.snippetType === ENewbuildingSnippetType.Maximum) && Boolean(originalTitle),
        ...(newbuilding?.snippetType === ENewbuildingSnippetType.Maximum &&
          originalTitle && { additionalTextBegin: getOfferName(offer) }),
      });

  const starRating = dailyrent?.hotelData?.starRating;

  return <Title isTop={isTop} title={title} subtitle={subtitle} starRating={starRating} href={href} rel={rel} />;
}
