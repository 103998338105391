import { IAppState, Dispatch } from 'shared/common/state';
import {
  CHAT_FORCE_OPEN_KEY,
  CHAT_FORCE_OPEN_INTERVAL,
  MOTIVATION_POPUP_KEY,
  MOTIVATION_POPUP_OPEN_INTERVAL,
} from 'shared/constants/newbuildingConsultant';
import { chatOpenSelector, isForceNeedOpenSelector } from 'shared/selectors/newbuildingConsultant';
import { trackClickChatOpen } from 'shared/tracking/consultantChat';
import { ITypedReduxAction, TThunkAction } from 'shared/types/redux';
import { isNeedToShow, updateShownTimestamp } from 'shared/utils/newbuildingConsultant/periodicActions';

export enum ENewbuildingChatActionTypes {
  ToggleChat = 'newbuildingConsultant/TOGGLE_CHAT',
  OpenMotivationPopup = 'newbuildingConsultant/OPEN_MOTIVATION_POPUP',
  OpenConsultantPopup = 'newbuildingConsultant/OPEN_CONSULTANT_POPUP',
  ToggleJumpAnimation = 'newbuildingConsultant/TOGGLE_JUMP_ANIMATION',
  SetOfferId = 'newbuildingConsultant/SET_OFFER_ID',
}

// TODO: В CD-225150 в случае победы эксперимента переименовать экшны и редьюсер.
export function newbuildingChatToggle(isOpen: boolean) {
  return {
    type: ENewbuildingChatActionTypes.ToggleChat,
    payload: isOpen,
  };
}

export function newbuildingOpenMotivationPopup(isOpen: boolean) {
  return {
    type: ENewbuildingChatActionTypes.OpenMotivationPopup,
    payload: isOpen,
  };
}

export function newbuildingOpenConsultantPopup(isOpen: boolean) {
  return {
    type: ENewbuildingChatActionTypes.OpenConsultantPopup,
    payload: isOpen,
  };
}

export function toggleConsultantChatJumpAnimation(run: boolean) {
  return {
    type: ENewbuildingChatActionTypes.ToggleJumpAnimation,
    payload: run,
  };
}

export function setConsultantChatOfferId(offerId: number) {
  return {
    type: ENewbuildingChatActionTypes.SetOfferId,
    payload: offerId,
  };
}

export function newbuildingChatOpenToggle(isOpen: boolean): TThunkAction {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const needForceOpen = isForceNeedOpenSelector(state);

    if (isOpen && needForceOpen && isNeedToShow(MOTIVATION_POPUP_KEY)) {
      updateShownTimestamp(CHAT_FORCE_OPEN_KEY, CHAT_FORCE_OPEN_INTERVAL);
    }

    dispatch(newbuildingChatToggle(isOpen));
  };
}

export function newbuildingOpenMotivationToggle(isOpen: boolean) {
  return (dispatch: Dispatch) => {
    if (isOpen) {
      updateShownTimestamp(MOTIVATION_POPUP_KEY, MOTIVATION_POPUP_OPEN_INTERVAL);
    }

    dispatch(newbuildingOpenMotivationPopup(isOpen));
  };
}

export function openConsultantChat(offerId: number) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const isPreviousOpen = chatOpenSelector(getState());

    if (isPreviousOpen) {
      dispatch(toggleConsultantChatJumpAnimation(true));
    } else {
      dispatch(newbuildingChatToggle(true));
      dispatch(setConsultantChatOfferId(offerId));
      trackClickChatOpen(offerId);
    }
  };
}

type TToggleChat = ITypedReduxAction<ENewbuildingChatActionTypes.ToggleChat, boolean>;
type TOpenConsultantPopup = ITypedReduxAction<ENewbuildingChatActionTypes.OpenConsultantPopup, boolean>;
type TOpenMotivationPopup = ITypedReduxAction<ENewbuildingChatActionTypes.OpenMotivationPopup, boolean>;
type TToggleJumpAnimation = ITypedReduxAction<ENewbuildingChatActionTypes.ToggleJumpAnimation, boolean>;
type TSetNewbuildingId = ITypedReduxAction<ENewbuildingChatActionTypes.SetOfferId, number>;

export type INewbuildingChatActions =
  | TToggleChat
  | TOpenConsultantPopup
  | TOpenMotivationPopup
  | TToggleJumpAnimation
  | TSetNewbuildingId;
