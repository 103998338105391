import { ArticleHeading1, ArticleParagraph1, Button } from '@cian/ui-kit';
import { IconProductHighDemand16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import styles from './CianGptBanner.css';

interface ICianGptBannerProps {
  onClick: VoidFunction;
}

export const CianGtpBanner: React.FC<ICianGptBannerProps> = ({ onClick }) => {
  return (
    <div onClick={onClick} className={styles['gpt-banner']}>
      <div className={styles['text']}>
        <ArticleHeading1 color="white_100" whiteSpace="nowrap">
          Найти квартиру проще с Циан-помощником
        </ArticleHeading1>

        <ArticleParagraph1 color="white_100">
          Опишите в чате, какая квартира вам нужна. И выбирайте только из подходящих вариантов.
        </ArticleParagraph1>
      </div>

      <Button beforeIcon={<IconProductHighDemand16 color="icon-main-default" />} theme="fill_secondary">
        Попробовать
      </Button>
    </div>
  );
};
