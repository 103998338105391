import { isAvailableStrict, FDealType, FOfferType } from '../../../../../packages/JsonQuery';
import { SubsidisedMortgageContainer } from '../../containers/advancedFilters/SubsidisedMortgageContainer';
import { IFilter } from '../../types/advancedFilters';
import { SUBSIDIZED_MORTGAGE_ENABLED_KEY } from '../config';

export const subsidisedMortgage: IFilter = {
  component: SubsidisedMortgageContainer,
  availability: ({ config, jsonQuery }) => {
    return (
      Boolean(config.get<boolean>(SUBSIDIZED_MORTGAGE_ENABLED_KEY)) &&
      isAvailableStrict(FDealType.Sale, FOfferType.FlatOld)(jsonQuery)
    );
  },
};
