import * as React from 'react';

import { FOfferType } from '../../../../../JsonQuery';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { Filter } from '../common/Filter';

const OBJECT_TYPE_OPTIONS = [
  { label: 'Неважно', value: FOfferType.Flat },
  { label: 'Новостройки', value: FOfferType.FlatNew },
  { label: 'Вторичка', value: FOfferType.FlatOld },
];

interface IObjectTypeProps {
  value: FOfferType;
  onChange(value: FOfferType): void;
}

export const ObjectType: React.FC<IObjectTypeProps> = ({ value, onChange }) => {
  return (
    <Filter id="advancedFilter_objectType" label="Тип объекта">
      <RadioButtonGroup options={OBJECT_TYPE_OPTIONS} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
