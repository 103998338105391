import * as React from 'react';

import { FOfferType, getTermsValue, getTermValue, NonEmptyArray } from '../../../../../JsonQuery';
import { SaleType } from '../../../components/advancedFilters/SaleType';
import { ESaleType } from '../../../types/jsonQuery';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const SaleTypeContainer: React.FC = () => {
  const { jsonQuery, onChange, isMixedFiltersRedesign } = useContext();
  const offerType = useOfferType();
  const saleType = getTermsValue('sost_type')(jsonQuery);
  const mortgageAvailable = getTermValue('ipoteka')(jsonQuery);

  const handleSaleTypeChange = React.useCallback(
    (saleType: NonEmptyArray<ESaleType> | null) => {
      onChange({ action: 'setSaleTypes', arguments: [saleType] });
    },
    [onChange],
  );

  const handleMortgageAvailableChange = React.useCallback(
    (mortgageAvailable: boolean | null) => {
      onChange({ action: 'setMortgageAvailable', arguments: [mortgageAvailable] });
    },
    [onChange],
  );

  return (
    <SaleType
      offerType={offerType}
      saleType={saleType}
      mortgageAvailable={mortgageAvailable}
      showMortgageFilter={!isMixedFiltersRedesign || (isMixedFiltersRedesign && offerType !== FOfferType.Flat)}
      onSaleTypeChange={handleSaleTypeChange}
      onMortgageAvailableChange={handleMortgageAvailableChange}
    />
  );
};
