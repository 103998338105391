import { useMemo } from 'react';

import { IAutoZoomSettings } from 'shared/components/Carousel/types';
import { useApplicationContext } from 'shared/utils/applicationContext';

export const useAutoZoomSettings = () => {
  const { config } = useApplicationContext();

  return useMemo(() => config.get<IAutoZoomSettings>('audience.carouselSettings.autoZoom') ?? undefined, [config]);
};
