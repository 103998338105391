import * as React from 'react';

import { CardInViewContext } from './context/context';
import { useIntersectionOptions, useInViewMap } from './hooks';

export const CardInViewProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const intersectionOptions = useIntersectionOptions();

  const inViewMap = useInViewMap({ containerRef: ref, intersectionOptions });

  return (
    <CardInViewContext.Provider value={inViewMap}>
      <div ref={ref}>{children}</div>
    </CardInViewContext.Provider>
  );
};
