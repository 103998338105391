import { selectExperiments } from '../selectExperiments';
import { IAppState } from 'shared/common/state';
import { AUD_REDESIGN_MIXED_FILTERS_EXPERIMENT } from 'shared/constants/experiments';
import { isAbUseExperimentGroup } from 'shared/utils/experiments/isAbUseExperimentGroup';

export const selectIsRedesignMixedFiltersExperiment = (state: IAppState) => {
  const experiments = selectExperiments(state);

  return isAbUseExperimentGroup(experiments, AUD_REDESIGN_MIXED_FILTERS_EXPERIMENT, 'B');
};
