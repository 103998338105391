import { ResponseError } from '../../errors';
import { IJsonQuery } from '../../json_query';
import {
  fetchCianApiSiteV1ConvertJsonQueryToSeoUrl as fetchConvertJsonQueryToSeoUrl,
  TCianApiSiteV1ConvertJsonQueryToSeoUrlResponse,
} from '../../repositories/monolith-python/cian-api/site/v1/convert-json-query-to-seo-url';
import { IApplicationContext } from '../../types/applicationContext';

interface IFetchSeoUrlsFromJsonQueriesParameters {
  subdomain: string;
  jsonQueries: IJsonQuery[];
}

async function fetchSeoUrlsFromJsonQueries(
  { httpApi }: IApplicationContext,
  { subdomain, jsonQueries }: IFetchSeoUrlsFromJsonQueriesParameters,
): Promise<string[] | null> {
  const seoUrlsData: TCianApiSiteV1ConvertJsonQueryToSeoUrlResponse = await fetchConvertJsonQueryToSeoUrl({
    httpApi,
    parameters: { jsonQueries },
    config: {
      subdomain,
    },
  });

  if (seoUrlsData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchSeoUrlsFromJsonQueries',
      message: seoUrlsData.response.data?.message || '',
      details: {
        error: JSON.stringify(seoUrlsData.response.data?.errors),
      },
    });
  }

  return seoUrlsData.response.data.urls || null;
}

export async function fetchSeoUrlsFromJsonQueriesSafe(
  context: IApplicationContext,
  parameters: IFetchSeoUrlsFromJsonQueriesParameters,
): Promise<string[] | null> {
  try {
    return await fetchSeoUrlsFromJsonQueries(context, parameters);
  } catch (ex) {
    const { logger } = context;

    logger.warning(ex, { degradation: 'SEO ссылки' });

    return null;
  }
}
