import { IConfig } from '@cian/config/shared';

import { getConsultantChatOnboarding } from './internal/getConsultantChatOnboarding';
import { getOfferChatOnboarding } from './internal/getOfferChatOnboarding';
import { TOfferDataParam } from './types';
import { TChatOnboarding } from 'shared/types/chatOnboarding';
import { IExperiment } from 'shared/types/experiments';

interface IParams {
  config: IConfig;
  data: TOfferDataParam;
  experiments: IExperiment[];
}

export function getChatOnboarding({ config, data, experiments }: IParams): TChatOnboarding {
  const page = data.jsonQuery?.page?.value;

  if (page && page > 1) {
    return {
      consultantChatOnboarding: null,
      offerChatOnboarding: null,
    };
  }

  return {
    consultantChatOnboarding: getConsultantChatOnboarding({ config, data, experiments }),
    offerChatOnboarding: getOfferChatOnboarding({ config, data }),
  };
}
