// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  ICianApiSiteV1GetSimilarNewobjectsRequest,
  TCianApiSiteV1GetSimilarNewobjectsModel,
  IMappers,
  TCianApiSiteV1GetSimilarNewobjectsResponse,
  TCianApiSiteV1GetSimilarNewobjectsResponse_1,
  TCianApiSiteV1GetSimilarNewobjectsResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TCianApiSiteV1GetSimilarNewobjectsModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/get-similar-newobjects/',
  interserviceAuthEnabled: true,
} as TCianApiSiteV1GetSimilarNewobjectsModel;

export function createCianApiSiteV1GetSimilarNewobjectsModel(
  parameters: ICianApiSiteV1GetSimilarNewobjectsRequest,
): TCianApiSiteV1GetSimilarNewobjectsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICianApiSiteV1GetSimilarNewobjectsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: ICianApiSiteV1GetSimilarNewobjectsRequest;
}

export async function fetchCianApiSiteV1GetSimilarNewobjects<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICianApiSiteV1GetSimilarNewobjectsOptions<TResponse200, TResponse400>): Promise<
  TCianApiSiteV1GetSimilarNewobjectsResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createCianApiSiteV1GetSimilarNewobjectsModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TCianApiSiteV1GetSimilarNewobjectsResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TCianApiSiteV1GetSimilarNewobjectsResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCianApiSiteV1GetSimilarNewobjectsResponse;
}
