/* eslint-disable max-lines */
import { needClearGeo, setRange, setTerm, setTerms } from './helpers';
import { selectOfferType } from './selectOfferType';
import { setDealType } from './setDealType';
import {
  ECurrencyType,
  EGarageKind,
  EGarageMaterial,
  EGarageStatus,
  EGarageType,
  EHeatingType,
  ELandStatusType,
  EMetroTravelType,
  EPriceType,
  ERoomType,
  ESaleType,
  ESortValue,
  ESuburbanOfferFilter,
  ESuburbanUtility,
  ESuburbanWCType,
  IJsonQuery,
  IJsonQueryUnderground,
  TGeoValue,
} from '../../repositories/common/json_query';
import { TLocation } from '../../types/location';
import { IStationData } from '../../types/undergroundStationData';
import { getDefaultTravelTimeForType } from '../../utils/underground';
import { EFacility } from '../types';

const setDecorationType = setTerm('has_decoration');
const setDeveloperType = setTerm('from_developer');
const setIsFirstFloor = setTerm('is_first_floor');
const setKpId = setTerm('kp_id');
const setMultiId = setTerm('multi_id');
const setIdenticalId = setTerm('identical_id');
const setNotLastFloor = setTerm('not_last_floor');
const setPrice = setRange('price');
const setRoom = setTerms('room');
const setUserId = setTerm('id_user');

function setWithPhoto(jsonQuery: IJsonQuery): (value: boolean | undefined) => IJsonQuery {
  return value => setTerm('wp')(jsonQuery)(value);
}

function setTour3d(jsonQuery: IJsonQuery): (value: boolean | undefined) => IJsonQuery {
  return value => setTerm('tour_3d')(jsonQuery)(value);
}

function setWithNeighbors(jsonQuery: IJsonQuery): (value: boolean | undefined) => IJsonQuery {
  return value => setTerm('with_neighbors')(jsonQuery)(value);
}

function setWithLayout(jsonQuery: IJsonQuery): (value: boolean | undefined) => IJsonQuery {
  return value => setTerm('with_layout')(jsonQuery)(value);
}

function setFacility(jsonQuery: IJsonQuery): (facility: EFacility, value: boolean) => IJsonQuery {
  return (facility, value) => setTerm(facility)(jsonQuery)(value || undefined);
}

const setGarageType =
  (jsonQuery: IJsonQuery) =>
  (garage: EGarageType): IJsonQuery => {
    const types = (jsonQuery.garage_type && jsonQuery.garage_type.value.concat()) || [];

    if (types.indexOf(garage) === -1) {
      types.push(garage);
    }

    return setTerms('garage_type')(jsonQuery)(types);
  };

const unsetGarageType =
  (jsonQuery: IJsonQuery) =>
  (garage: EGarageType): IJsonQuery => {
    if (!jsonQuery.garage_type) {
      return jsonQuery;
    }

    const types = jsonQuery.garage_type.value.filter(e => e !== garage);

    return setTerms('garage_type')(jsonQuery)(types);
  };

const unsetGarageTypes =
  (jsonQuery: IJsonQuery): (() => IJsonQuery) =>
  () =>
    setTerm('garage_type')(jsonQuery)(undefined);

const setGarageKind =
  (jsonQuery: IJsonQuery) =>
  (garageKind: EGarageKind): IJsonQuery => {
    const types = (jsonQuery.garage_garage_type && jsonQuery.garage_garage_type.value.concat()) || [];

    if (types.indexOf(garageKind) === -1) {
      types.push(garageKind);
    }

    return setTerms('garage_garage_type')(jsonQuery)(types);
  };

const unsetGarageKind =
  (jsonQuery: IJsonQuery) =>
  (garageKind: EGarageKind): IJsonQuery => {
    if (!jsonQuery.garage_garage_type) {
      return jsonQuery;
    }

    const types = jsonQuery.garage_garage_type.value.filter(e => e !== garageKind);

    return setTerms('garage_garage_type')(jsonQuery)(types);
  };

const unsetGarageKinds =
  (jsonQuery: IJsonQuery): (() => IJsonQuery) =>
  () =>
    setTerm('garage_garage_type')(jsonQuery)(undefined);

const setGarageMaterial =
  (jsonQuery: IJsonQuery) =>
  (garageMaterial: EGarageMaterial): IJsonQuery => {
    const types = (jsonQuery.garage_material && jsonQuery.garage_material.value.concat()) || [];

    if (types.indexOf(garageMaterial) === -1) {
      types.push(garageMaterial);
    }

    return setTerms('garage_material')(jsonQuery)(types);
  };

const unsetGarageMaterial =
  (jsonQuery: IJsonQuery) =>
  (garageMaterial: EGarageMaterial): IJsonQuery => {
    if (!jsonQuery.garage_material) {
      return jsonQuery;
    }

    const types = jsonQuery.garage_material.value.filter(e => e !== garageMaterial);

    return setTerms('garage_material')(jsonQuery)(types);
  };

const unsetGarageMaterials =
  (jsonQuery: IJsonQuery): (() => IJsonQuery) =>
  () =>
    setTerm('garage_material')(jsonQuery)(undefined);

const setGarageStatus =
  (jsonQuery: IJsonQuery) =>
  (garageStatus: EGarageStatus): IJsonQuery => {
    const types = (jsonQuery.garage_status && jsonQuery.garage_status.value.concat()) || [];

    if (types.indexOf(garageStatus) === -1) {
      types.push(garageStatus);
    }

    return setTerms('garage_status')(jsonQuery)(types);
  };

const unsetGarageStatus =
  (jsonQuery: IJsonQuery) =>
  (garageStatus: EGarageStatus): IJsonQuery => {
    if (!jsonQuery.garage_status) {
      return jsonQuery;
    }

    const types = jsonQuery.garage_status.value.filter(e => e !== garageStatus);

    return setTerms('garage_status')(jsonQuery)(types);
  };

const unsetGarageStatuses =
  (jsonQuery: IJsonQuery): (() => IJsonQuery) =>
  () =>
    setTerm('garage_status')(jsonQuery)(undefined);

function setSuburbanUtility(jsonQuery: IJsonQuery): (facility: ESuburbanUtility, value: boolean) => IJsonQuery {
  return (facility, value) => setTerm(facility)(jsonQuery)(value || undefined);
}

function setMinPrice(jsonQuery: IJsonQuery): (minPrice: number | undefined) => IJsonQuery {
  return minPrice => setPrice(jsonQuery)(minPrice, jsonQuery.price && jsonQuery.price.value.lte);
}

function setMaxPrice(jsonQuery: IJsonQuery): (maxPrice: number | undefined) => IJsonQuery {
  return maxPrice => setPrice(jsonQuery)(jsonQuery.price && jsonQuery.price.value.gte, maxPrice);
}

function setPageNumber(jsonQuery: IJsonQuery): (pageNumber: number) => IJsonQuery {
  return pageNumber => setTerm('page')(jsonQuery)(pageNumber === 1 ? undefined : pageNumber);
}

function setPriceType(jsonQuery: IJsonQuery): (priceType: EPriceType) => IJsonQuery {
  return priceType => setTerm('price_sm')(jsonQuery)(priceType === EPriceType.SM ? true : undefined);
}

function setCurrencyType(jsonQuery: IJsonQuery): (currencyType: ECurrencyType) => IJsonQuery {
  return currencyType => setTerm('currency')(jsonQuery)(currencyType);
}

function setSuburbanOfferFilter(jsonQuery: IJsonQuery) {
  return (suburbanOfferFilter: ESuburbanOfferFilter): IJsonQuery =>
    setTerm('suburban_offer_filter')(jsonQuery)(
      suburbanOfferFilter === ESuburbanOfferFilter.Any ? undefined : suburbanOfferFilter,
    );
}

function unsetSuburbanOfferFilter(jsonQuery: IJsonQuery) {
  return (): IJsonQuery => setTerm('suburban_offer_filter')(jsonQuery)(undefined);
}

const setSuburbanWCType =
  (jsonQuery: IJsonQuery) =>
  (suburbanWCType: ESuburbanWCType): IJsonQuery =>
    setTerm('wc_site')(jsonQuery)(suburbanWCType === ESuburbanWCType.Any ? undefined : suburbanWCType);

const unsetSuburbanWCType = (jsonQuery: IJsonQuery) => (): IJsonQuery => setTerm('wc_site')(jsonQuery)(undefined);

const unsetIsDacha = (jsonQuery: IJsonQuery) => (): IJsonQuery => setTerm('is_dacha')(jsonQuery)(undefined);

function setHeatingType(jsonQuery: IJsonQuery) {
  return (heatingType: EHeatingType): IJsonQuery => {
    const types = (jsonQuery.heating_source_type && jsonQuery.heating_source_type.value.concat()) || [];

    if (types.indexOf(heatingType) === -1) {
      types.push(heatingType);
    }

    return setTerms('heating_source_type')(jsonQuery)(types);
  };
}

function unsetHeatingType(jsonQuery: IJsonQuery) {
  return (heatingType: EHeatingType): IJsonQuery => {
    if (!jsonQuery.heating_source_type) {
      return jsonQuery;
    }

    const types = jsonQuery.heating_source_type.value.filter(e => e !== heatingType);

    return setTerms('heating_source_type')(jsonQuery)(types);
  };
}

function unsetHeatingTypes(jsonQuery: IJsonQuery): () => IJsonQuery {
  return () => setTerm('heating_source_type')(jsonQuery)(undefined);
}

const unsetNewbuildingHouse =
  (jsonQuery: IJsonQuery) =>
  (key: string): IJsonQuery => {
    const value =
      jsonQuery.geo &&
      jsonQuery.geo.value.reduce((acc: TGeoValue[], geoValue) => {
        if (geoValue.type === 'nb_house_key' && geoValue.key === key) {
          return acc;
        }

        acc.push(geoValue);

        return acc;
      }, []);

    return {
      ...jsonQuery,
      geo: value ? { type: 'geo', value } : undefined,
    };
  };

const unsetNewbuildingHouses = (jsonQuery: IJsonQuery) => (): IJsonQuery => {
  const value =
    jsonQuery.geo &&
    jsonQuery.geo.value.reduce((acc: TGeoValue[], geoValue) => {
      if (geoValue.type === 'nb_house_key') {
        return acc;
      }

      acc.push(geoValue);

      return acc;
    }, []);

  return {
    ...jsonQuery,
    geo: value ? { type: 'geo', value } : undefined,
  };
};

const setNewbuildingHouses =
  (jsonQuery: IJsonQuery) =>
  (newbuildingId: number, keys: number[]): IJsonQuery => {
    const value =
      (jsonQuery.geo &&
        jsonQuery.geo.value.filter(geoValue => {
          return geoValue.type !== 'nb_house_key';
        })) ||
      [];

    const houseValues = keys.map(key => ({
      newbuilding_id: newbuildingId,
      type: 'nb_house_key' as const,
      id: key,
      key: `${newbuildingId}_${key}`,
    }));

    return {
      ...jsonQuery,
      geo: { type: 'geo', value: [...value, ...houseValues] },
    };
  };

function setLandStatusType(jsonQuery: IJsonQuery) {
  return (landStatusType: ELandStatusType): IJsonQuery => {
    const types = (jsonQuery.land_status && jsonQuery.land_status.value.concat()) || [];

    if (types.indexOf(landStatusType) === -1) {
      types.push(landStatusType);
    }

    return setTerms('land_status')(jsonQuery)(types);
  };
}

function unsetLandStatusType(jsonQuery: IJsonQuery) {
  return (landStatusType: ELandStatusType): IJsonQuery => {
    if (!jsonQuery.land_status) {
      return jsonQuery;
    }

    const types = jsonQuery.land_status.value.filter(e => e !== landStatusType);

    return setTerms('land_status')(jsonQuery)(types);
  };
}

function unsetLandStatusTypes(jsonQuery: IJsonQuery): () => IJsonQuery {
  return () => setTerm('land_status')(jsonQuery)(undefined);
}

function toggleRoom(jsonQuery: IJsonQuery): (room: ERoomType) => IJsonQuery {
  return room => {
    const currentRoom = jsonQuery.room && jsonQuery.room.value;
    let newRoom: ERoomType[] = [];
    if (!currentRoom) {
      newRoom = [room];
    } else if (currentRoom.indexOf(room) > -1) {
      newRoom = currentRoom.filter(value => value !== room);
    } else {
      newRoom.push(room);
    }

    return setTerms('room')(jsonQuery)(newRoom);
  };
}

function setLocation(jsonQuery: IJsonQuery): (nextLocation: TLocation, currentLocation: TLocation) => IJsonQuery {
  return (nextLocation, currentLocation) => {
    const operators = [
      (modifiedJsonQuery: IJsonQuery) => {
        let value: number[] = [];

        if (nextLocation === 'moscow_mo') {
          value = [1, 4593];
        } else if (nextLocation === 'spb_lo') {
          value = [2, 4588];
        } else if (nextLocation) {
          value = [nextLocation.id];
        }

        return setTerms('region')(modifiedJsonQuery)(value);
      },
    ];

    if (needClearGeo(nextLocation, currentLocation)) {
      operators.push(
        (modifiedJsonQuery: IJsonQuery) => jq(modifiedJsonQuery).clearGeoParams(),
        (modifiedJsonQuery: IJsonQuery) => jq(modifiedJsonQuery).unsetUndergroundTravel(),
      );
    }

    return jqs(jsonQuery, operators);
  };
}

function setMinFromMKAD(jsonQuery: IJsonQuery) {
  return (minFromMKAD: number | undefined): IJsonQuery => {
    return setRange('from_mcad_km')(jsonQuery)(minFromMKAD, jsonQuery.from_mcad_km && jsonQuery.from_mcad_km.value.lte);
  };
}

function setMaxFromMKAD(jsonQuery: IJsonQuery) {
  return (maxFromMKAD: number | undefined): IJsonQuery => {
    return setRange('from_mcad_km')(jsonQuery)(jsonQuery.from_mcad_km && jsonQuery.from_mcad_km.value.gte, maxFromMKAD);
  };
}

function setMinBedroomsCount(jsonQuery: IJsonQuery) {
  return (bedroomsCount: number | undefined): IJsonQuery => {
    return setRange('bedroom_total')(jsonQuery)(
      bedroomsCount,
      jsonQuery.bedroom_total && jsonQuery.bedroom_total.value.lte,
    );
  };
}

function setSort(jsonQuery: IJsonQuery): (sort: ESortValue) => IJsonQuery {
  return sort => setTerm('sort')(jsonQuery)(sort === ESortValue.Default ? undefined : sort);
}

function setBlackFriday(jsonQuery: IJsonQuery) {
  return (isBlackFriday: boolean): IJsonQuery =>
    setTerm('is_black_friday')(jsonQuery)(isBlackFriday ? true : undefined);
}

function setMortgage(jsonQuery: IJsonQuery) {
  return (morgage: boolean): IJsonQuery => setTerm('ipoteka')(jsonQuery)(morgage ? true : undefined);
}

function addSaleType(jsonQuery: IJsonQuery) {
  return (saleType: ESaleType): IJsonQuery => {
    const saleTypes = jsonQuery.sost_type ? jsonQuery.sost_type.value.concat() : [];

    if (!saleTypes.includes(saleType)) {
      saleTypes.push(saleType);
    }

    return setTerms('sost_type')(jsonQuery)(saleTypes);
  };
}

function removeSaleType(jsonQuery: IJsonQuery) {
  return (saleType: ESaleType): IJsonQuery => {
    if (!jsonQuery.sost_type) {
      return jsonQuery;
    }

    const saleTypes = jsonQuery.sost_type.value.filter(type => type !== saleType);

    return setTerms('sost_type')(jsonQuery)(saleTypes);
  };
}

function removeAllSaleTypes(jsonQuery: IJsonQuery) {
  return (): IJsonQuery => {
    return setTerms('sost_type')(jsonQuery)([]);
  };
}

function addMetroStations(jsonQuery: IJsonQuery) {
  return (stations: IStationData[]): IJsonQuery => {
    let nextJsonQuery = { ...jsonQuery };

    const undergrounds: IJsonQueryUnderground[] = stations.reduce((acc: IJsonQueryUnderground[], item) => {
      acc.push({
        type: 'underground',
        id: Number(item.id),
      });

      return acc;
    }, []);

    const wasNoUndergrounds = !nextJsonQuery.geo || !nextJsonQuery.geo.value.some(geo => geo.type === 'underground');
    const wasNoTravelType = !nextJsonQuery.only_foot || !nextJsonQuery.only_foot.value;
    const wasNoTravelTime = !nextJsonQuery.foot_min || !nextJsonQuery.foot_min.value;

    if (wasNoUndergrounds && wasNoTravelTime && wasNoTravelType) {
      nextJsonQuery = setUndergroundTravel(nextJsonQuery)(
        EMetroTravelType.MapFoot,
        getDefaultTravelTimeForType(EMetroTravelType.MapFoot),
      );
    }

    return {
      ...nextJsonQuery,
      geo: {
        type: 'geo',
        value: nextJsonQuery.geo ? nextJsonQuery.geo.value.concat(undergrounds) : undergrounds,
      },
    };
  };
}

function removeMetroStations(jsonQuery: IJsonQuery) {
  return (stations: IStationData[]): IJsonQuery => {
    const value =
      jsonQuery.geo &&
      jsonQuery.geo.value.reduce((acc: TGeoValue[], geoValue) => {
        if (geoValue.type === 'underground' && !stations.every(item => item.id !== geoValue.id)) {
          return acc;
        }

        acc.push(geoValue);

        return acc;
      }, []);

    return {
      ...jsonQuery,
      geo: value ? { type: 'geo', value } : undefined,
    };
  };
}

function setBuildingDone(jsonQuery: IJsonQuery) {
  return (value: boolean): IJsonQuery => {
    return setTerm('hand_over')(jsonQuery)(value ? true : undefined);
  };
}

function setBuildingDoneInYear(jsonQuery: IJsonQuery) {
  return (year: number): IJsonQuery => {
    const years = (jsonQuery.year && jsonQuery.year.value.concat()) || [];

    if (years.indexOf(year) === -1) {
      years.push(year);
    }

    return setTerms('year')(jsonQuery)(years);
  };
}

function unsetBuildingDoneInYear(jsonQuery: IJsonQuery) {
  return (year: number): IJsonQuery => {
    if (!jsonQuery.year) {
      return jsonQuery;
    }

    const years = jsonQuery.year.value.filter(e => e !== year);

    return setTerms('year')(jsonQuery)(years);
  };
}

function unsetBuildingDoneInYears(jsonQuery: IJsonQuery) {
  return (): IJsonQuery => {
    return setTerms('year')(jsonQuery)([]);
  };
}

function setBuildingDoneLater(jsonQuery: IJsonQuery) {
  return (year: number): IJsonQuery => {
    return setTerm('yeargte')(jsonQuery)(year);
  };
}

function unsetBuildingDoneLater(jsonQuery: IJsonQuery) {
  return (): IJsonQuery => {
    return setTerm('yeargte')(jsonQuery)(undefined);
  };
}

function setMinFloor(jsonQuery: IJsonQuery) {
  return (minFloor: number | undefined): IJsonQuery => {
    return setRange('floor')(jsonQuery)(minFloor, jsonQuery.floor && jsonQuery.floor.value.lte);
  };
}

function setMaxFloor(jsonQuery: IJsonQuery) {
  return (maxFloor: number | undefined): IJsonQuery => {
    return setRange('floor')(jsonQuery)(jsonQuery.floor && jsonQuery.floor.value.gte, maxFloor);
  };
}

function setHiddenBaseSearch(jsonQuery: IJsonQuery): (value: boolean) => IJsonQuery {
  return value => setTerm('is_in_hidden_base')(jsonQuery)(value ? true : undefined);
}

function unsetHiddenBaseSearch(jsonQuery: IJsonQuery): () => IJsonQuery {
  return () => setTerm('is_in_hidden_base')(jsonQuery)(undefined);
}

function setUndergroundTravel(jsonQuery: IJsonQuery): (travelType: EMetroTravelType, travelTime: number) => IJsonQuery {
  return (travelType, travelTime) => {
    const modifiedJsonQuery = setTerm('only_foot')(jsonQuery)(travelType);

    return setRange('foot_min')(modifiedJsonQuery)(undefined, travelTime);
  };
}

function unsetUndergroundTravel(jsonQuery: IJsonQuery): () => IJsonQuery {
  return () => {
    const modifiedJsonQuery = setTerm('only_foot')(jsonQuery)(undefined);

    return setRange('foot_min')(modifiedJsonQuery)(undefined, undefined);
  };
}

function clearGeoParams(jsonQuery: IJsonQuery): () => IJsonQuery {
  return () => setTerm('geo')(jsonQuery)(undefined);
}

const setHasFlatTourBooking =
  (jsonQuery: IJsonQuery): ((value: boolean | undefined) => IJsonQuery) =>
  value =>
    setTerm('has_flat_tour_booking')(jsonQuery)(value);

const setHasProfitbaseBooking =
  (jsonQuery: IJsonQuery): ((value: boolean | undefined) => IJsonQuery) =>
  value =>
    setTerm('has_profitbase_booking')(jsonQuery)(value);

const setVillageMortgageAllowed =
  (jsonQuery: IJsonQuery): ((value: boolean | undefined) => IJsonQuery) =>
  value =>
    setTerm('village_mortgage_allowed')(jsonQuery)(value);

const setBuilder =
  (jsonQuery: IJsonQuery): ((value: number | undefined) => IJsonQuery) =>
  value =>
    setTerm('builder')(jsonQuery)(value);

function unsetPromoSearchTypes(jsonQuery: IJsonQuery) {
  return (promoSearchType: string): IJsonQuery => {
    if (!jsonQuery.promo_search_types) {
      return jsonQuery;
    }

    const types = jsonQuery.promo_search_types.value.filter(e => e !== promoSearchType);

    return setTerms('promo_search_types')(jsonQuery)(types);
  };
}

function unsetDecorationsListItem(jsonQuery: IJsonQuery) {
  return (decoration: string): IJsonQuery => {
    if (!jsonQuery.decorations_list) {
      return jsonQuery;
    }

    const types = jsonQuery.decorations_list.value.filter(e => e !== decoration);

    return setTerms('decorations_list')(jsonQuery)(types);
  };
}

export function jq(jsonQuery: IJsonQuery) {
  return {
    addMetroStations: addMetroStations(jsonQuery),
    addSaleType: addSaleType(jsonQuery),
    clearGeoParams: clearGeoParams(jsonQuery),
    removeAllSaleTypes: removeAllSaleTypes(jsonQuery),
    removeMetroStations: removeMetroStations(jsonQuery),
    removeSaleType: removeSaleType(jsonQuery),
    selectOfferType: selectOfferType(jsonQuery),
    setBuildingDone: setBuildingDone(jsonQuery),
    setBuildingDoneInYear: setBuildingDoneInYear(jsonQuery),
    setBuildingDoneLater: setBuildingDoneLater(jsonQuery),
    setCurrencyType: setCurrencyType(jsonQuery),
    setDealType: setDealType(jsonQuery),
    setDecorationType: setDecorationType(jsonQuery),
    setDeveloperType: setDeveloperType(jsonQuery),
    setFacility: setFacility(jsonQuery),
    setGarageKind: setGarageKind(jsonQuery),
    setGarageMaterial: setGarageMaterial(jsonQuery),
    setGarageStatus: setGarageStatus(jsonQuery),
    setGarageType: setGarageType(jsonQuery),
    setHasFlatTourBooking: setHasFlatTourBooking(jsonQuery),
    setHasProfitbaseBooking: setHasProfitbaseBooking(jsonQuery),
    setHeatingType: setHeatingType(jsonQuery),
    setHiddenBaseSearch: setHiddenBaseSearch(jsonQuery),
    setIsFirstFloor: setIsFirstFloor(jsonQuery),
    setKpId: setKpId(jsonQuery),
    setLandStatusType: setLandStatusType(jsonQuery),
    setLocation: setLocation(jsonQuery),
    setMaxFloor: setMaxFloor(jsonQuery),
    setMaxFromMKAD: setMaxFromMKAD(jsonQuery),
    setMaxPrice: setMaxPrice(jsonQuery),
    setMinBedroomsCount: setMinBedroomsCount(jsonQuery),
    setMinFloor: setMinFloor(jsonQuery),
    setMinFromMKAD: setMinFromMKAD(jsonQuery),
    setMinPrice: setMinPrice(jsonQuery),
    setMortgage: setMortgage(jsonQuery),
    setMultiId: setMultiId(jsonQuery),
    setIdenticalId: setIdenticalId(jsonQuery),
    setNotLastFloor: setNotLastFloor(jsonQuery),
    setPageNumber: setPageNumber(jsonQuery),
    setPriceType: setPriceType(jsonQuery),
    setBlackFriday: setBlackFriday(jsonQuery),
    setRoom: setRoom(jsonQuery),
    setSort: setSort(jsonQuery),
    setSuburbanOfferFilter: setSuburbanOfferFilter(jsonQuery),
    setSuburbanUtility: setSuburbanUtility(jsonQuery),
    setSuburbanWCType: setSuburbanWCType(jsonQuery),
    setTour3d: setTour3d(jsonQuery),
    setUndergroundTravel: setUndergroundTravel(jsonQuery),
    setUserId: setUserId(jsonQuery),
    setWithPhoto: setWithPhoto(jsonQuery),
    setWithNeighbors: setWithNeighbors(jsonQuery),
    setWithLayout: setWithLayout(jsonQuery),
    setNewbuildingHouses: setNewbuildingHouses(jsonQuery),
    toggleRoom: toggleRoom(jsonQuery),
    unsetNewbuildingHouse: unsetNewbuildingHouse(jsonQuery),
    unsetNewbuildingHouses: unsetNewbuildingHouses(jsonQuery),
    unsetBuildingDoneInYear: unsetBuildingDoneInYear(jsonQuery),
    unsetBuildingDoneInYears: unsetBuildingDoneInYears(jsonQuery),
    unsetBuildingDoneLater: unsetBuildingDoneLater(jsonQuery),
    unsetGarageKind: unsetGarageKind(jsonQuery),
    unsetGarageKinds: unsetGarageKinds(jsonQuery),
    unsetGarageMaterial: unsetGarageMaterial(jsonQuery),
    unsetGarageMaterials: unsetGarageMaterials(jsonQuery),
    unsetGarageStatus: unsetGarageStatus(jsonQuery),
    unsetGarageStatuses: unsetGarageStatuses(jsonQuery),
    unsetGarageType: unsetGarageType(jsonQuery),
    unsetGarageTypes: unsetGarageTypes(jsonQuery),
    unsetHeatingType: unsetHeatingType(jsonQuery),
    unsetHeatingTypes: unsetHeatingTypes(jsonQuery),
    unsetHiddenBaseSearch: unsetHiddenBaseSearch(jsonQuery),
    unsetLandStatusType: unsetLandStatusType(jsonQuery),
    unsetLandStatusTypes: unsetLandStatusTypes(jsonQuery),
    unsetSuburbanOfferFilter: unsetSuburbanOfferFilter(jsonQuery),
    unsetSuburbanWCType: unsetSuburbanWCType(jsonQuery),
    unsetIsDacha: unsetIsDacha(jsonQuery),
    unsetUndergroundTravel: unsetUndergroundTravel(jsonQuery),
    setVillageMortgageAllowed: setVillageMortgageAllowed(jsonQuery),
    setBuilder: setBuilder(jsonQuery),
    unsetPromoSearchTypes: unsetPromoSearchTypes(jsonQuery),
    unsetDecorationsListItem: unsetDecorationsListItem(jsonQuery),
  };
}

export function jqs(jsonQuery: IJsonQuery, operations: ((jsonQuery: IJsonQuery) => IJsonQuery)[]): IJsonQuery {
  return operations.reduce((acc, operation) => operation(acc), jsonQuery);
}
