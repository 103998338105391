import { getGlobalEventsLogs } from '@cian/events-log';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { CianGtpBanner } from 'shared/components/CianGptBanner/CianGptBanner';
import { getOffers } from 'shared/selectors/offers';

export const CianGptBannerContainer: React.FC = () => {
  const offers = useSelector(getOffers);

  const products = React.useMemo(
    () =>
      offers.map((offer, index) => ({
        id: offer.id,
        offerType: 'offer',
        position: index + 1,
      })),
    [offers],
  );

  /* istanbul ignore next */
  const handleClick = React.useCallback(() => {
    const globalLog = getGlobalEventsLogs();

    const value = {
      source: 'Banner',
      gaProducts: JSON.stringify(products),
      pageType: 'Listing',
    };

    globalLog.produce('cianGptPopup', { type: 'open', value });
  }, [products]);

  return <CianGtpBanner onClick={handleClick} />;
};
