import * as React from 'react';

import * as styles from './RangeInput.css';
import { IRangeInputProps, RangeInput as RangeInputComponent } from '../../../RangeInput';

interface IRangeInputContainerProps extends IRangeInputProps {
  containerWidth?: number;
}

export const RangeInput: React.FC<IRangeInputContainerProps> = ({ containerWidth = 100, ...restProps }) => {
  return (
    <div className={styles['container']} style={{ width: containerWidth }}>
      <RangeInputComponent {...restProps} />
    </div>
  );
};
