import * as React from 'react';

/**
 * Вызывает callback когда страница полностью загрузится.
 * Если страница была загружена ранее, то callback будет вызван немедленно.
 *
 * @example
 * const someDependency = '';
 *
 * usePageLoaded(() => {
 *   console.log('Hey, page completely loaded', someDependency);
 * }, [someDependency]);
 *
 * @param {Function} callback - Обработчик, вызывается после загрузки страницы
 * @param {Array} deps - Список зависимостей, при их обновлении пересоздается обработчик
 * @returns
 */
export function usePageLoaded(callback: () => void, deps: React.DependencyList) {
  const handleLoad = React.useCallback(() => {
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  React.useEffect(() => {
    if (window.document.readyState === 'complete') {
      handleLoad();

      return;
    }

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, [handleLoad]);
}
