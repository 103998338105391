import { getTermValue } from '../../../packages/JsonQuery';
import { IAppState } from '../../common/state';
import { EDealType } from '../../types/dealType';
import { FOfferType } from '../../utils/category';
import { getDealType } from '../getDealType';
import { getJsonQuery } from '../getJsonQuery';
import { getOfferType } from '../getOfferType';

/**
 * Определяет, новостроечная или смешанная выдача.
 */
export function selectIsNewbuildingOrMixedListing(state: IAppState): boolean {
  const jsonQuery = getJsonQuery(state);
  const isFromDeveloper = Boolean(getTermValue('from_developer')(jsonQuery));

  const dealType = getDealType(state);
  const offerType = getOfferType(state);

  const isAvailableInNewbuildingList = state.isNewbuildingsOnly || isFromDeveloper;
  const isAvailableInMixedList = offerType === FOfferType.Flat && dealType === EDealType.Sale;

  return isAvailableInNewbuildingList || isAvailableInMixedList;
}
