import { EConsultantCianChatActionTypes, TConsultantCianChatActions } from 'shared/actions/consultantCianChat';
import { IAppState } from 'shared/common/state';

export function consultantCianChatReducer(state: IAppState, action: TConsultantCianChatActions): IAppState {
  switch (action.type) {
    case EConsultantCianChatActionTypes.OpenAutorisationMotivationPopup:
      return {
        ...state,
        consultantCianChat: {
          ...state.consultantCianChat,
          isOpened: false,
          isAutorisationPopupOpened: true,
        },
      };

    case EConsultantCianChatActionTypes.OpenChat:
      return {
        ...state,
        consultantCianChat: {
          ...state.consultantCianChat,
          chatId: action.payload,
          isOpened: true,
          isAutorisationPopupOpened: false,
        },
      };

    case EConsultantCianChatActionTypes.CloseChat:
      return {
        ...state,
        consultantCianChat: {
          ...state.consultantCianChat,
          isOpened: false,
          isAutorisationPopupOpened: false,
        },
      };

    default:
      return state;
  }
}
