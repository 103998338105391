import { RefObject, useEffect, useState } from 'react';

import { useOfferContext } from '../../../../context';
import { trackAutoScroll } from '../tracking';

interface IParams {
  cardRef: RefObject<HTMLDivElement>;
}

export const useAutoScroll = ({ cardRef }: IParams) => {
  const { jsonQuery, offer, position, isGalleryAutoScrollEnabled } = useOfferContext();

  const [isAutoScrolling, setIsAutoScrolling] = useState(false);

  useEffect(() => {
    if (isAutoScrolling) {
      trackAutoScroll({ jsonQuery, offerId: offer.id, position });
    }
  }, [isAutoScrolling, jsonQuery, offer.id, position]);

  useEffect(() => {
    const card = cardRef.current;
    const isActiveForEachFourthOffer = position % 4 === 0;

    if (!card || !isActiveForEachFourthOffer) {
      return () => {};
    }

    const handleMouseEnter = () => setIsAutoScrolling(!!isGalleryAutoScrollEnabled);
    const handleMouseLeave = () => setIsAutoScrolling(false);

    card.addEventListener('mouseenter', handleMouseEnter);
    card.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      card.removeEventListener('mouseenter', handleMouseEnter);
      card.removeEventListener('mouseleave', handleMouseLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRef]);

  return isAutoScrolling;
};
