// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type {
  TGetNewbuildingLayoutWithOffersDesktopRequest,
  TGetNewbuildingLayoutWithOffersDesktopModel,
  IMappers,
  TGetNewbuildingLayoutWithOffersDesktopResponse,
  TGetNewbuildingLayoutWithOffersDesktopResponse_1,
  TGetNewbuildingLayoutWithOffersDesktopResponseError,
} from './types';
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';

export const defaultConfig: TGetNewbuildingLayoutWithOffersDesktopModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-offers',
  pathApi: '/v1/get-newbuilding-layout-with-offers-desktop/',
  requestType: 'json',
  interserviceAuthEnabled: true,
} as TGetNewbuildingLayoutWithOffersDesktopModel;

export function createGetNewbuildingLayoutWithOffersDesktopModel(
  parameters: TGetNewbuildingLayoutWithOffersDesktopRequest,
): TGetNewbuildingLayoutWithOffersDesktopModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewbuildingLayoutWithOffersDesktopOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: TGetNewbuildingLayoutWithOffersDesktopRequest;
}

export async function fetchGetNewbuildingLayoutWithOffersDesktop<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetNewbuildingLayoutWithOffersDesktopOptions<TResponse200, TResponse400>): Promise<
  TGetNewbuildingLayoutWithOffersDesktopResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetNewbuildingLayoutWithOffersDesktopModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as TGetNewbuildingLayoutWithOffersDesktopResponse_1);
    }
    if (statusCode === 400) {
      return mappers[400](response as TGetNewbuildingLayoutWithOffersDesktopResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetNewbuildingLayoutWithOffersDesktopResponse;
}
