import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useOfferContext } from '../../../fragments/OfferCardWidget/shared/context';
import { useApplicationContext } from '../../utils/applicationContext';
import { selectIsHidePhoneNumberExperiment } from 'shared/selectors/experiments/selectIsHidePhoneNumberExperiment';
import { selectJsonQuery } from 'shared/selectors/results';

export function useGetIsAuthenticationPopupEnabled(): boolean {
  const { config } = useApplicationContext();
  const {
    offer: { category, offerType },
    agentType,
  } = useOfferContext();
  const { region } = useSelector(selectJsonQuery);
  const isExperimentEnabled = useSelector(selectIsHidePhoneNumberExperiment);
  const isHomeowner = agentType === 'homeowner';

  const isHidingPhoneNumberEnabled = config.get<boolean>('BX.HidingPhoneNumber.Enabled') ?? false;
  const hidingPhoneNumberRegions = config.get<number[]>('BX.HidingPhoneNumber.Regions') ?? [];

  const regionId = useMemo(() => {
    return hidingPhoneNumberRegions.some(id => id === region?.value[0]);
  }, [region]);

  return useMemo(
    () =>
      isHidingPhoneNumberEnabled &&
      isExperimentEnabled &&
      regionId &&
      isHomeowner &&
      offerType === 'flat' &&
      (category === 'flatSale' || category === 'flatRent' || category === 'dailyFlatRent'),
    [isHidingPhoneNumberEnabled, isExperimentEnabled, region, isHomeowner, offerType, category],
  );
}
