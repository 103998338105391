import { Button, UIHeading1, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NewbuildingBrokerConsultantBanner.css';
import { NBSP } from 'shared/constants/symbols';

interface INewbuildingBrokerConsultantBannerProps {
  url: string;
}

export const NewbuildingBrokerConsultantBanner: React.FC<INewbuildingBrokerConsultantBannerProps> = ({ url }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={styles['container']}
      data-testid="NewbuildingBrokerConsultantBanner"
    >
      <div className={styles['inner']}>
        <UIHeading1>
          Подберём и{NBSP}поможем купить квартиру в{NBSP}новостройке. Бесплатно!
        </UIHeading1>
        <div className={styles['description-wrapper']}>
          <UIText1>Найдём жильё под{NBSP}ваш запрос</UIText1>
        </div>
        <div>
          <Button>О сервисе</Button>
        </div>
      </div>
    </a>
  );
};
