import { useApplicationContext } from '../../../../../../shared/utils/applicationContext';
import { TViewKeys } from '../context/context';

export interface IIntersectionOption {
  id: TViewKeys;
  options: IntersectionObserverInit;
}

export const DEFAULT_INTERSECTION_OPTIONS: IIntersectionOption[] = [{ id: 'videoShorts', options: { threshold: 1 } }];

export const useIntersectionOptions = () => {
  const { config } = useApplicationContext();

  return config.get<IIntersectionOption[]>('audience.intersectionOptions') || DEFAULT_INTERSECTION_OPTIONS;
};
