import { ENewbuildingChatActionTypes } from '../actions';
import { IAppState, TActions } from 'shared/common/state';

export function newbuildingConsultant(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ENewbuildingChatActionTypes.ToggleChat:
      return {
        ...state,
        newbuildingConsultant: {
          ...state.newbuildingConsultant,
          isOpen: action.payload,
          showMotivationPopup: action.payload ? false : state.newbuildingConsultant.showMotivationPopup,
        },
      };
    case ENewbuildingChatActionTypes.OpenMotivationPopup:
      return {
        ...state,
        newbuildingConsultant: {
          ...state.newbuildingConsultant,
          showMotivationPopup: action.payload,
        },
      };
    case ENewbuildingChatActionTypes.OpenConsultantPopup:
      return {
        ...state,
        newbuildingConsultant: {
          ...state.newbuildingConsultant,
          consultantPopupOpen: action.payload,
        },
      };
    case ENewbuildingChatActionTypes.ToggleJumpAnimation:
      return {
        ...state,
        newbuildingConsultant: {
          ...state.newbuildingConsultant,
          needRunJumpAnimation: action.payload,
        },
      };
    case ENewbuildingChatActionTypes.SetOfferId:
      return {
        ...state,
        newbuildingConsultant: {
          ...state.newbuildingConsultant,
          offerId: action.payload,
        },
      };
    default:
      return state;
  }
}
