import { onAddOfferToComparisonSuccessAuth } from './addOfferToComparisonSuccessAuth';
import { requestAuthentication } from '../../../../actions/authentication';
import { Dispatch } from '../../../../common/state';
import { TOfferCategory } from '../../../../offer/types';
import { ECategory } from 'shared/repositories/offers-comparison/entities/offer/OfferDesktopSchema';

interface IParameters {
  offerId: number;
  category: ECategory | TOfferCategory | string | null;
}

export function addOfferToComparisonAfterAuth(parameters: IParameters) {
  return async (dispatch: Dispatch) => {
    const { offerId, category } = parameters;

    dispatch(
      requestAuthentication({
        source: 'сompareOffers',
        viewData: { headingType: 'compareOffers' },
        onSuccess: onAddOfferToComparisonSuccessAuth({ offerId, category }),
      }),
    );
  };
}
