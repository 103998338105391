import { selectExperiments } from '../selectExperiments';
import { IAppState } from 'shared/common/state';
import { AUD_GALLERY_AUTOSCROLL_AUTOZOOM_EXPERIMENT } from 'shared/constants/experiments';
import { isAbUseExperimentGroup } from 'shared/utils/experiments/isAbUseExperimentGroup';

export const selectGalleryAutoZoomExperimentEnabled = (state: IAppState) => {
  const experiments = selectExperiments(state);

  return isAbUseExperimentGroup(experiments, AUD_GALLERY_AUTOSCROLL_AUTOZOOM_EXPERIMENT, 'B');
};
